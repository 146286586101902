import { Button, message, Space, Table, Tag, Typography, Tooltip } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import axios from "../../../Utils/axios";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan } from "@fortawesome/free-solid-svg-icons";
const { Paragraph } = Typography;

const Others = ({ filteredData, size }) => {
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      const { data } = await axios.get("/api/request/self?status=MODIFICATION");

      setRequests(data.requests);
      setLoading(false);
      return data;
    }

    fetchData();
  }, []);

  /* eslint-disable-next-line */

  const handleCancel = async (record) => {
    try {
      const { data } = await axios.patch("/api/request/cancel/" + record._id, {
        status: "CANCELED",
      });
      if (data.status === "success") {
        message.success(data.message);
        const new_requests = requests.map((r) => {
          if (r._id === record._id) {
            r.status = "CANCELLED";
            return r;
          } else {
            return r;
          }
        });
        setRequests(new_requests);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  const columns = [
    {
      title: "Soumis le",
      dataIndex: "send_time_date",
      render: (_, leave) => (
        <Typography.Text strong>
          {leave?.send_time_date
            ? dayjs(leave.send_time_date).format("DD/MM/YYYY HH:mm")
            : "N/A"}
        </Typography.Text>
      ),
      sorter: (a, b) => a.send_time_date - b.send_time_date,
    },

    {
      title: "Demande",
      dataIndex: "request",
      width: 300,
      render: (_, requestedLeaves) => (
        <>
          <Paragraph
            style={{ margin: 0 }}
            ellipsis={{
              rows: 2,
              expandable: true,
              symbol: "Plus",
            }}
          >
            {requestedLeaves.request}
          </Paragraph>
        </>
      ),
    },

    {
      title: "Statut",
      dataIndex: "status",
      render: (_, record) => (
        <>
          {record.status === "PENDING" && <Tag color="orange">En attente</Tag>}
          {record.status === "APPROVED" && <Tag color="green">Acceptèe</Tag>}

          {record.status === "REJECTED" && <Tag color="red">Rejetèe</Tag>}
          {record.status === "CANCELLED" && <Tag>Annulèe</Tag>}
        </>
      ),
      filters: [
        {
          text: <Tag color="orange">En attente</Tag>,
          value: "PENDING",
        },
        {
          text: <Tag color="green">Acceptèe</Tag>,
          value: "APPROVED",
        },
        {
          text: <Tag color="red">Rejetèe</Tag>,
          value: "REJECTED",
        },
        {
          text: <Tag>Annulèe</Tag>,
          value: "CANCELLED",
        },
      ],
      defaultFilteredValue: filteredData || [],
      onFilter: (value, record) => record.status === value,
    },
    {
      title: "Actions",
      render: (_, record) => (
        <>
          {record.status === "PENDING" && (
            <Space>
              <Tooltip title="Annuler vote demande.">
                <Button
                  shape="circle"
                  size="small"
                  type="primary"
                  onClick={() => {
                    handleCancel(record);
                  }}
                >
                  <FontAwesomeIcon icon={faBan} />
                </Button>
              </Tooltip>
            </Space>
          )}
          {record.status === "APPROVED" && (
            <Tooltip title="Annuler vote demande.">
              <Button shape="circle" disabled size="small" type="primary">
                <FontAwesomeIcon icon={faBan} />
              </Button>
            </Tooltip>
          )}
          {record.status === "REJECTED" && (
            <Tooltip title="Annuler vote demande.">
              <Button shape="circle" disabled size="small" type="primary">
                <FontAwesomeIcon icon={faBan} />
              </Button>
            </Tooltip>
          )}
          {record.status === "CANCELLED" && (
            <Tooltip title="Annuler vote demande.">
              <Button shape="circle" disabled size="small" type="primary">
                <FontAwesomeIcon icon={faBan} />
              </Button>
            </Tooltip>
          )}

        </>
      ),
    },
  ];

  return (
    <div>
      <Table
        columns={columns}
        loading={loading}
        dataSource={requests}
        scroll={{ x: "max-content" }}
      />
    </div>
  );
};

export default Others;
