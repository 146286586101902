import { DatePicker, Spin, Button, Input, Space, Table, Typography } from "antd";
import axios from "../../../Utils/axios";
import React, { useEffect, useRef, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;

const Logs = () => {
  const [loading, setLoading] = useState(true);
  const [archives, setArchives] = useState([]);
  const [dateRange, setRange] = useState("");
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex, label) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Rechercher ${label}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
          >
            Rechercher
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
          >
            Reset
          </Button>

          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Fermer
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const onChange = (date) => {
    if (date) {
      const startDate = dayjs(date[0]).format("DD-MM-YYYY");
      const endDate = dayjs(date[1]).format("DD-MM-YYYY");
      setRange({ startDate: startDate, endDate: endDate });
    } else {
      setRange("");
    }
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "at",
      width: 160,
      render: (_, record) => (
        <span>{dayjs(Number(record.at)).format("DD/MM/YYYY HH:mm")}</span>
      ),
    },
    {
      title: "Utilisateur",
      dataIndex: "By",
      sorter: (a, b) => a.By.localeCompare(b.By), // Modify the sorter function to compare string values correctly
      width: 250,
      ...getColumnSearchProps("By", "utilisateur"),
    },
    {
      title: "Action",
      dataIndex: "Action",
      ...getColumnSearchProps("Action", "action"),
    },
  ];

  useEffect(() => {
    async function fetchLogs() {
      setLoading(true);
      const { data } = await axios.get(
        `/api/archive/${dateRange
          ? "?startDate=" +
          dateRange.startDate +
          "&endDate=" +
          dateRange.endDate
          : ""
        }`
      );
      if (data.status === "success") {
        setArchives(data.Archives);
        setLoading(false);
      }
      return data;
    }

    fetchLogs();
  }, [dateRange]);
  return (
    <div className="p-4">
      <div className="pb-2 mb-3 d-flex justify-content-between align-items-center flex-column flex-lg-row flex-md-row">
        <Typography.Title level={4}>⏰ Historiques</Typography.Title>
        <div className="col-lg-6 col-md-7 col-sm-12 ">
          <RangePicker
            onChange={onChange}
            className="w-100"
            placement="bottomLeft"
          />
        </div>
      </div>
      <div>
        {loading ? (
          <div className="d-flex mt-5 justify-content-center">
            <Spin size="large" />
          </div>
        ) : (
          <Table columns={columns}  dataSource={archives} scroll={{
            x: "max-content",
          }} />
        )}
      </div>
    </div>
  );
};

export default Logs;
