import { Button, Form, message, Typography } from "antd";
import React, { useEffect } from "react";
import ListCategories from "./ListCategories";
import axiosInstance from "../../../Utils/axios";
import { faCircleChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

const Categories = () => {
  const [form] = Form.useForm();
  const Navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await axiosInstance.get(
          "/api/factory/skills/categories"
        );
        form.setFieldValue("categories", data?.categories || []);
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    }

    fetchData();
  }, []);

  const handleSave = async (formValues) => {
    try {
      const { data } = await axiosInstance.post(
        "/api/factory/skills/categories",
        formValues
      );
      if (data?.status === "success") message.success("Enregistré avec succès");
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };
  return (
    <div className="pt-2">
      <div className="mb-3">
        <Typography.Title level={4}>Catégories</Typography.Title>
      </div>
      <div className="  p-4 ">
        <div className="d-flex">
          <Button
            onClick={() => Navigate("/administration/manage/skills")}
            shape="round"
            icon={<FontAwesomeIcon icon={faCircleChevronLeft} />}
          >
            Compétances
          </Button>
        </div>
        <Form className="mt-3" form={form} onFinish={handleSave}>
          <ListCategories />
        </Form>
      </div>
    </div>
  );
};

export default Categories;
