import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  notification,
  message,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import axiosInstance from "../../Utils/axios";
import { useNavigate } from "react-router-dom";

const AccessNewRequest = () => {
  const [services, setServices] = useState([]);
  const [form] = Form.useForm();
  const Navigate = useNavigate();

  const handleRequest = async () => {
    form
      .validateFields()
      .then((res) => {
        const service = services.find((elem) => elem?.value === res?.type);
        const payload = {
          typeId: service?.value,
          type: service?.label,
          motif: res?.motif,
          _description: res?._description,
        };


        axiosInstance
          .post("/api/request/access", payload)
          .then((response) => {
            if (response?.data?.status === "success") {
              notification.success({
                message: response?.data?.message,
                duration: 4,
              });
              Navigate("/self/access");
            }
          })
          .catch((error) => {
            notification.error({
              message: error?.response?.data?.message,
              duration: 4,
            });
          });
      })
      .catch((error) => {
        notification.error({
          message: error?.errorFields[0]?.errors[0],
          duration: 4,
        });
      });
  };

  useEffect(() => {
    async function fetchServices() {
      try {
        const { data } = await axiosInstance.get(
          "/api/administration/services/mini"
        );

        const temp = data?.data?.services?.map((elem) => {
          return {
            value: elem?._id,
            label: elem?.name,
          };
        });

        setServices(temp);
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    }
    fetchServices();
  }, []);

  return (
    <div className="p-4mt-4">
      <div className="mb-3 d-flex justify-content-between align-items-center flex-lg-row flex-md-row flex-column pb-2">
        <Typography.Title level={4}>🆕 Nouvelle demande d'accés</Typography.Title>
      </div>
      <div className="row d-flex flex-column-reverse flex-lg-row flex-md-row ">
        <Form layout="vertical" size="large" form={form}>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="type"
                label="Type d'accès"
                rules={[
                  {
                    required: true,
                    message: "Veuillez sélectionner le type d'accès",
                  },
                ]}
              >
                <Select>
                  {services.map((elem, index) => {
                    return (
                      <Select.Option key={index} value={elem?.value}>
                        {elem?.label}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name={"_description"}
                label={"Description"}
                rules={[
                  {
                    required: true,
                    message: "Merci de remplir le champ",
                  },
                ]}
              >
                <Input.TextArea
                  rows={4}
                  placeholder="N'hésitez pas à détailler votre demande pour une meilleure compréhension et pour une gestion plus efficace."
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name={"motif"}
                label={"Motif"}
                rules={[
                  {
                    whitespace: true,
                    required: true,
                    message: "Merci de remplir le champ",
                  },
                ]}
              >
                <Input.TextArea
                  rows={4}
                  placeholder="N'hésitez pas à détailler la cause de votre demande pour une meilleure compréhension et pour une gestion plus efficace."
                />
              </Form.Item>
            </Col>
          </Row>
          <Button
            style={{ marginRight: "1rem" }}
            onClick={() => Navigate("/self/access")}
          >
            Annuler
          </Button>
          <Button onClick={handleRequest} type="primary">
            Soumettre
          </Button>
        </Form>{" "}
      </div>
    </div>
  );
};

export default AccessNewRequest;
