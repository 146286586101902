import { Timeline, Typography } from "antd";
import React from "react";
import Leaves from "./Tables/Leaves";
import Telework from "./Tables/Telework";
import Equipments from "./Tables/Equipments";
import Acces from "./Tables/Acces";
import Others from "./Tables/Others";

const PendingRequests = () => {
  return (
    <div className="mt-4">
      <div className="px-4">
        <Typography.Title level={4}>⌛ Demandes en attente :</Typography.Title>
      </div>
      <Timeline className="px-4 py-3">
        <Timeline.Item>
          <h6 style={{ fontWeight: "400" }}>✈️ Congés/Autorisations</h6>
          <div className="mb-3 ">
            <Leaves />
          </div>
        </Timeline.Item>
        <Timeline.Item>
          <h6 style={{ fontWeight: "400" }}>🏡 Télétravail</h6>
          <div className="mb-3">
            <Telework />
          </div>
        </Timeline.Item>
        <Timeline.Item>
          <h6 style={{ fontWeight: "400" }}>💻 Equipements</h6>
          <div className="mb-3">
            <Equipments />
          </div>
        </Timeline.Item>
        <Timeline.Item>
          <h6 style={{ fontWeight: "400" }}>🔑 Accès</h6>
          <div className="mb-3">
            <Acces />
          </div>
        </Timeline.Item>
        <Timeline.Item>
          <h6 style={{ fontWeight: "400" }}>📝 Autre(s)</h6>
          <div className="mb-3">
            <Others />
          </div>
        </Timeline.Item>
      </Timeline>
    </div>
  );
};

export default PendingRequests;
