import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Empty, theme as antdTheme } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";

const CalendarDay = ({ data, singleDate }) => {
  const [sortedData, setSortedData] = useState();

  const capitalizeFirstLetter = (str) => {
    const capitalized = str[0].toUpperCase() + str.slice(1).toLowerCase();
    return capitalized;
  };

  useEffect(() => {
    function splitInterval(leaveRecord) {
      const startDate = dayjs(leaveRecord.BeginingDate);
      const endDate = dayjs(leaveRecord.EndingDate);

      const intervalDays = endDate.diff(startDate, "day") + 1;

      const splitRecords = [];

      for (let i = 0; i < intervalDays; i++) {
        const currentDate = startDate.add(i, "day").format("YYYY-MM-DD");

        const newRecord = {
          ...leaveRecord,
          BeginingDate: currentDate,
          EndingDate: currentDate,
        };
        splitRecords.push(newRecord);
      }

      return splitRecords;
    }

    function sortLeaveRecords(records) {
      return records.sort((recordA, recordB) => {
        const dateA = dayjs(recordA.BeginingDate);
        const dateB = dayjs(recordB.BeginingDate);
        return dateA - dateB;
      });
    }

    let temp = [];

    data?.forEach((element) => {
      const splitRecords = splitInterval(element);
      splitRecords.forEach((elem) => temp.push(elem));
    });

    let sortedTemp = sortLeaveRecords(temp);

    let displayedData = [];

    sortedTemp?.forEach((elem) => {
      if (
        dayjs(elem?.BeginingDate).isAfter(
          dayjs().startOf("month").subtract(1, "day")
        ) &&
        dayjs(elem?.BeginingDate).isBefore(dayjs().endOf("month"))
      ) {
        displayedData.push(elem);
      }
    });

    setSortedData(displayedData);
  }, [data]);

  const {
    token: { colorBgContainer },
  } = antdTheme.useToken();

  return (
    <div>
      <div
        className="d-flex flex-column  p-3"
        style={{ backgroundColor: colorBgContainer }}
      >
        {sortedData?.map((elem, idx) => {
          if (singleDate) {
            return sortedData?.filter(
              (obj) =>
                dayjs(obj?.BeginingDate).format("DD/MM/YYYY") ===
                dayjs(elem?.BeginingDate).format("DD/MM/YYYY")
            )?.length !== 0 &&
              dayjs(elem?.BeginingDate).format("DD/MM/YYYY") ===
                dayjs(singleDate).format("DD/MM/YYYY") &&
              dayjs(elem?.BeginingDate).format("dddd") !== "samedi" &&
              dayjs(elem?.BeginingDate).format("dddd") !== "dimanche" ? (
              <div>
                {idx === 0 && (
                  <div
                    className={
                      dayjs().format("DD/MM/YYYY") !==
                        dayjs(elem?.BeginingDate).format("DD/MM/YYYY") &&
                      "text-muted"
                    }
                    style={{
                      fontSize: "18px",
                      fontWeight: "500",
                      padding: "10px 0",
                    }}
                  >
                    <span>
                      <FontAwesomeIcon
                        icon={faCalendar}
                        style={{ marginRight: "5px" }}
                      />
                      {dayjs(elem?.BeginingDate).format("DD MMMM YYYY")}
                    </span>
                    <br />
                    <span
                      style={{
                        fontSize: "14px",
                        fontWeight: "400",
                      }}
                    >
                      {
                        sortedData?.filter(
                          (obj) =>
                            dayjs(obj?.BeginingDate).format("DD/MM/YYYY") ===
                            dayjs(elem?.BeginingDate).format("DD/MM/YYYY")
                        )?.length
                      }{" "}
                      Collaborateur(s) en congé(s)
                    </span>
                  </div>
                )}
                {idx !== 0 &&
                  dayjs(elem?.BeginingDate).format("DD/MM/YYYY") !==
                    dayjs(sortedData[idx - 1]?.BeginingDate).format(
                      "DD/MM/YYYY"
                    ) && (
                    <div
                      className={
                        dayjs().format("DD/MM/YYYY") !==
                          dayjs(elem?.BeginingDate).format("DD/MM/YYYY") &&
                        "text-muted"
                      }
                    >
                      <div
                        style={{
                          fontSize: "20px",
                          fontWeight: "500",
                          padding: "10px 0",
                        }}
                        className="py-3"
                      >
                        <span>
                          <FontAwesomeIcon
                            icon={faCalendar}
                            style={{ marginRight: "5px" }}
                          />
                          {dayjs(elem?.BeginingDate).format("DD MMMM YYYY")}
                        </span>
                        <br />
                        <span
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                          }}
                        >
                          {
                            sortedData?.filter(
                              (obj) =>
                                dayjs(obj?.BeginingDate).format(
                                  "DD/MM/YYYY"
                                ) ===
                                dayjs(elem?.BeginingDate).format("DD/MM/YYYY")
                            )?.length
                          }{" "}
                          Collaborateur(s) en congé(s)
                        </span>
                      </div>
                    </div>
                  )}
                <div
                  className="d-flex justify-content-between my-1 hoverable-effect"
                  style={{
                    borderLeft: "3px solid grey",
                    borderColor: elem?._is_half_day
                      ? "#FFBE0B"
                      : elem?._typeLeave === "Congé annuel"
                      ? "#1ECB44"
                      : elem?._typeLeave === "Autorisation"
                      ? "#8338EC"
                      : elem?._typeLeave === "Congé sans solde"
                      ? "#FB0707"
                      : elem?._typeLeave === "Congé Special"
                      ? "#FB33FF"
                      : "#3A86FF",
                  }}
                >
                  <span style={{ fontWeight: "500" }}>
                    {capitalizeFirstLetter(elem?.fullName)}
                  </span>
                  <span
                    style={{
                      backgroundColor: elem?._is_half_day
                        ? "#FFBE0B"
                        : elem?._typeLeave === "Congé annuel"
                        ? "#1ECB44"
                        : elem?._typeLeave === "Autorisation"
                        ? "#8338EC"
                        : elem?._typeLeave === "Congé sans solde"
                        ? "#FB0707"
                        : elem?._typeLeave === "Congé Special"
                        ? "#FB33FF"
                        : "#3A86FF",
                      borderRadius: "20px",
                      color: "#FFFFFF",
                      fontSize: "12px",
                      fontWeight: "500",
                      padding: "4px 7px",
                      textAlign: "center",
                    }}
                  >
                    {elem?._is_half_day
                      ? "Congé 1/2"
                      : elem?._typeLeave === "Congé annuel"
                      ? "Congé"
                      : elem?._typeLeave === "Autorisation"
                      ? "Autorisation"
                      : elem?._typeLeave === "Congé sans solde"
                      ? "Congé"
                      : elem?._typeLeave === "Congé Special"
                      ? "Congé"
                      : "Congé"}
                  </span>
                </div>
              </div>
            ) : (
              <div>
                {sortedData?.filter(
              (obj) =>
                dayjs(obj?.BeginingDate).format("DD/MM/YYYY") ===
                dayjs(elem?.BeginingDate).format("DD/MM/YYYY")
            )?.length !== 0 &&
              dayjs(elem?.BeginingDate).format("DD/MM/YYYY") ===
                dayjs(singleDate).format("DD/MM/YYYY") &&
              dayjs(elem?.BeginingDate).format("dddd") === "samedi" &&
              dayjs(elem?.BeginingDate).format("dddd") === "dimanche" && <Empty />}
              </div>
            );
          } else {
            return (
              dayjs(elem?.BeginingDate).format("dddd") !== "samedi" &&
              dayjs(elem?.BeginingDate).format("dddd") !== "dimanche" && (
                <div>
                  {idx === 0 && (
                    <div
                      className={
                        dayjs().format("DD/MM/YYYY") !==
                          dayjs(elem?.BeginingDate).format("DD/MM/YYYY") &&
                        "text-muted"
                      }
                      style={{
                        fontSize: "18px",
                        fontWeight: "500",
                        padding: "10px 0",
                      }}
                    >
                      <span>
                        <FontAwesomeIcon
                          icon={faCalendar}
                          style={{ marginRight: "5px" }}
                        />
                        {dayjs(elem?.BeginingDate).format("DD MMMM YYYY")}
                      </span>
                      <br />
                      <span
                        style={{
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                      >
                        {
                          sortedData?.filter(
                            (obj) =>
                              dayjs(obj?.BeginingDate).format("DD/MM/YYYY") ===
                              dayjs(elem?.BeginingDate).format("DD/MM/YYYY")
                          )?.length
                        }{" "}
                        Collaborateur(s) en congé(s)
                      </span>
                    </div>
                  )}
                  {idx !== 0 &&
                    dayjs(elem?.BeginingDate).format("DD/MM/YYYY") !==
                      dayjs(sortedData[idx - 1]?.BeginingDate).format(
                        "DD/MM/YYYY"
                      ) && (
                      <div
                        className={
                          dayjs().format("DD/MM/YYYY") !==
                            dayjs(elem?.BeginingDate).format("DD/MM/YYYY") &&
                          "text-muted"
                        }
                      >
                        <div
                          style={{
                            fontSize: "20px",
                            fontWeight: "500",
                            padding: "10px 0",
                          }}
                          className="py-3"
                        >
                          <span>
                            <FontAwesomeIcon
                              icon={faCalendar}
                              style={{ marginRight: "5px" }}
                            />
                            {dayjs(elem?.BeginingDate).format("DD MMMM YYYY")}
                          </span>
                          <br />
                          <span
                            style={{
                              fontSize: "14px",
                              fontWeight: "400",
                            }}
                          >
                            {
                              sortedData?.filter(
                                (obj) =>
                                  dayjs(obj?.BeginingDate).format(
                                    "DD/MM/YYYY"
                                  ) ===
                                  dayjs(elem?.BeginingDate).format("DD/MM/YYYY")
                              )?.length
                            }{" "}
                            Collaborateur(s) en congé(s)
                          </span>
                        </div>
                      </div>
                    )}
                  <div
                    className="d-flex justify-content-between my-1 hoverable-effect"
                    style={{
                      borderLeft: "3px solid grey",
                      borderColor: elem?._is_half_day
                        ? "#FFBE0B"
                        : elem?._typeLeave === "Congé annuel"
                        ? "#1ECB44"
                        : elem?._typeLeave === "Autorisation"
                        ? "#8338EC"
                        : elem?._typeLeave === "Congé sans solde"
                        ? "#FB0707"
                        : elem?._typeLeave === "Congé Special"
                        ? "#FB33FF"
                        : "#3A86FF",
                    }}
                  >
                    <span style={{ fontWeight: "500" }}>
                      {capitalizeFirstLetter(elem?.fullName)}
                    </span>
                    <span
                      style={{
                        backgroundColor: elem?._is_half_day
                          ? "#FFBE0B"
                          : elem?._typeLeave === "Congé annuel"
                          ? "#1ECB44"
                          : elem?._typeLeave === "Autorisation"
                          ? "#8338EC"
                          : elem?._typeLeave === "Congé sans solde"
                          ? "#FB0707"
                          : elem?._typeLeave === "Congé Special"
                          ? "#FB33FF"
                          : "#3A86FF",
                        borderRadius: "20px",
                        color: "#FFFFFF",
                        fontSize: "12px",
                        fontWeight: "500",
                        padding: "4px 7px",
                        textAlign: "center",
                      }}
                    >
                      {elem?._is_half_day
                        ? "Congé 1/2"
                        : elem?._typeLeave === "Congé annuel"
                        ? "Congé"
                        : elem?._typeLeave === "Autorisation"
                        ? "Autorisation"
                        : elem?._typeLeave === "Congé sans solde"
                        ? "Congé"
                        : elem?._typeLeave === "Congé Special"
                        ? "Congé"
                        : "Congé"}
                    </span>
                  </div>
                </div>
              )
            );
          }
        })}
      </div>
    </div>
  );
};

export default CalendarDay;
