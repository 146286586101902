import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Empty,
  Timeline,
  Typography,
  message,
} from "antd";

import { CaretDownOutlined } from "@ant-design/icons";

import axiosInstance from "../../Utils/axios";
import GlobalContext from "../../Context/GlobalContext";
import SondageCard from "../../Components/SondageCard/SondageCard";
import PostCard from "../../Components/PostCard";
import DocumentCard from "../../Components/DocumentCard";

import SelfKPI from "./SelfKPI";
import SelfInformation from "./SelfInformation";
import TeleworkCollab from "./TeleworkCollab";
import UnavailableCollab from "./UnavailableCollab";
import SubInformation from "./SubInformation";

const Default = () => {
  const Navigate = useNavigate();
  const { profile, stats } = useContext(GlobalContext);
  const [page, setPage] = useState(1);
  const [pageNumbers, setPageNumbers] = useState(1);
  const [actualities, setActualities] = useState([]);
  const [todayLeaves, setTodayLeaves] = useState([]);
  const [todayRemote, setTodayRemote] = useState([]);
  const [kpis, setKpis] = useState({});

  const handlePlusLeaves = () => {
    Navigate("/pending/requests");
  };

  const handlePlusSubs = () => {
    Navigate("/manage/subordinates");
  };

  useEffect(() => {
    async function getTodayLeaves() {
      const { data } = await axiosInstance.get("/api/leaves/get/today/leaves");
      setTodayLeaves(data.todayLeave);
      setTodayRemote(data.todayRemote);
      return data;
    }

    getTodayLeaves();
    // openNotification();
  }, []);

  useEffect(() => {
    async function getActualities() {
      const { data } = await axiosInstance.get(
        `/api/factory/actualities?page=${page}&pageSize=3`
      );
      setActualities([...actualities, ...data.actualities]);
      setPageNumbers(data.totalPages);
    }
    getActualities();
    /* eslint-disable-next-line */
  }, [page]);

  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await axiosInstance.get("api/platform/kpis/me");
        setKpis(data);
      } catch (error) {
        message.error(error.response.data.message);
      }
    }
    fetchData();
  }, []);

  return (
    <div>
      <div className="pb-4">
        <div className="container w-100 px-4">
          <div
            className="my-4 d-flex flex-column justify-content-center"
            style={{ height: 100 }}
          >
            <Typography.Title style={{ fontWeight: "400", fontSize: "20px" }}>
              Salut{" "}
              <span style={{ fontWeight: "500" }}>{profile?.fullName}</span> !{" "}
              {kpis?.currentLeave?.length === 0 && (
                <>
                  Prêt(e) à briller aujourd'hui ? 🚀💪
                  <br />
                </>
              )}{" "}
              {/*  */}
              {kpis?.currentLeave?.find(
                (elem) => elem?._typeLeave === "Télétravail"
              ) &&
                !kpis?.currentLeave?.find(
                  (elem) =>
                    elem?._typeLeave !== "Télétravail" &&
                    elem?._typeLeave !== "Autorisation"
                ) && (
                  <>
                    Vous êtes actuellement en mode télétravail. 🏡 <br />
                  </>
                )}{" "}
              {/*  */}
              {kpis?.currentLeave?.find(
                (elem) => elem?._typeLeave === "Autorisation"
              ) && (
                <>
                  Vous avez obtenu une autorisation de sortie de{" "}
                  {
                    kpis?.currentLeave?.find(
                      (elem) => elem?._typeLeave === "Autorisation"
                    )._duration
                  }{" "}
                  ⏰
                </>
              )}{" "}
              {/*  */}
              {kpis?.currentLeave?.find(
                (elem) =>
                  elem?._typeLeave !== "Télétravail" &&
                  elem?._typeLeave !== "Autorisation"
              ) && (
                <>
                  Vous profitez actuellement de votre congé. 🏖️ <br />
                </>
              )}{" "}
              {/*  <br /> */}
            </Typography.Title>
          </div>
          <Timeline>
            <Timeline.Item>
              <SelfKPI kpis={kpis} />
            </Timeline.Item>
            <Timeline.Item>
              <SelfInformation
                stats={stats}
                handlePlusLeaves={handlePlusLeaves}
              />
            </Timeline.Item>
            {stats.isTeamLead && (
              <Timeline.Item>
                <SubInformation stats={stats} handlePlusSubs={handlePlusSubs} />
              </Timeline.Item>
            )}
            <Timeline.Item>
              <h1 style={{ fontWeight: "500", fontSize: "20px" }} className="">
                👥 Mes collaborateurs
              </h1>
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <UnavailableCollab todayLeaves={todayLeaves} />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <TeleworkCollab todayRemote={todayRemote} />
                </div>
              </div>
            </Timeline.Item>

            <Timeline.Item>
              <div>
                <h4 style={{ fontWeight: "500", fontSize: "20px" }}>
                  📢 Actualités
                </h4>
                <div className="  p-3  mt-2">
                   {/* eslint-disable-next-line */}
                  {actualities.map((elem) => {
                    if (elem.type === "document") {
                      return (
                        <DocumentCard
                          id={elem._id}
                          access={false}
                          isHomePage={true}
                        />
                      );
                    } else if (elem.type === "suggestion") {
                      return <PostCard id={elem._id} access={false} />;
                    } else if (elem.type === "survey") {
                      return <SondageCard id={elem._id} access={false} />;
                    }
                  })}
                  {actualities.length > 0 ? (
                    <div className="d-flex justify-content-center mt-2">
                      <Button
                        shape="round"
                        disabled={pageNumbers === page}
                        onClick={() => {
                          const temp = page + 1;
                          setPage(temp);
                        }}
                        className="d-flex justify-content-center align-items-center mt-2"
                      >
                        <CaretDownOutlined />
                        Afficher plus
                      </Button>
                    </div>
                  ) : (
                    <Empty description={"Aucune donnée"} />
                  )}
                </div>
              </div>
            </Timeline.Item>
          </Timeline>
        </div>
      </div>
    </div>
  );
};

export default Default;
