import React, { useContext, useEffect } from "react";
import { Button, Typography } from "antd";

import axios from "../../Utils/axios";
import GlobalContext from "../../Context/GlobalContext";
import EquipmentsRequests from "../../Components/EquipmentsRequests";
import { useNavigate } from "react-router-dom";

const MyEquipments = () => {
  const { equipments, setEquipments } = useContext(GlobalContext);

  useEffect(() => {
    async function fetchData() {
      const { data } = await axios.get(`/api/equipments/me`);

      setEquipments(data.equipments);
    }

    fetchData();
    // eslint-disable-next-line
  }, []);

  const Navigate = useNavigate();
  return (
    <div className=" p-4">
      <div className="mb-3 d-flex justify-content-between">
        <Typography.Title level={4}>📋 Historique de mes demandes d'équipements :</Typography.Title>
        <Button
          type="primary"
          shape="round"
          onClick={() => Navigate("/request/equipments", {
            state: {
              equipments,

            },
          })}
        >
          Nouvelle demande
        </Button>
      </div>
      <EquipmentsRequests />
    </div>
  );
};

export default MyEquipments;
