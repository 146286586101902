import {
  Button,
  Popconfirm,
  Space,
  Steps,
  Table,
  Tooltip,
  ConfigProvider,
  Tag,
  Typography,
  message,
} from "antd";
import React, { useContext, useEffect, useState } from "react";
import axiosInstance from "../../../../Utils/axios";
import {
  LoadingOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  MinusCircleTwoTone,
  CheckCircleOutlined,
  CloseCircleOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import RejectModal from "./RejectModal";
import GlobalContext from "../../../../Context/GlobalContext";
import EditAccessDrawer from "./EditAccessDrawer";
import AccessRequestDrawer from "../../../../Components/LayoutClient/AccessRequestDrawer/AccessRequestDrawer";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AccessRequests = ({ size, getColumnSearchProps }) => {
  const { profile } = useContext(GlobalContext);
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const access = [];
  const accessTypes = [];

  const handleApprove = async (id) => {
    try {
      const { data } = await axiosInstance.patch(
        `/api/request/access/${
          profile?.sub_role === "SUPER_ADMIN" ? "hr" : "sysadmin"
        }/${id}`,
        { status: true }
      );
      if (data?.status === "success") {
        setRefresh(!refresh);
        message.success(data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    async function fetchRecords() {
      try {
        const { data } = await axiosInstance?.get("/api/request/access");

        if (data?.status === "success") {
          const temp = data?.requests?.filter(
            (elem) => elem?.status === "PENDING"
          );
          setRecords(temp);
          setLoading(false);
        }
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    }

    fetchRecords();
  }, [refresh]);

  const columns = [
    {
      title: "Demandeur",
      dataIndex: "sender",
      ...getColumnSearchProps("sender", "nom et prénom"),
    },
    {
      title: "Accès demandé",
      dataIndex: "request",
      ...getColumnSearchProps("request", "accès demandé"),
      render: (text) => (
        <Typography.Text className="text-uppercase">{text}</Typography.Text>
      ),
    },
    {
      title: "Statut",
      filters: [
        {
          text: (
            <Tag color="red">
              Rejetée <CloseCircleOutlined style={{ marginLeft: "5px" }} />
            </Tag>
          ),
          value: "REJECTED",
        },
        {
          text: (
            <Tag color="green">
              Approuvée <CheckCircleOutlined style={{ marginLeft: "5px" }} />
            </Tag>
          ),
          value: "APPROVED",
        },
        {
          text: (
            <Tag color="orange">
              En attente <LoadingOutlined style={{ marginLeft: "5px" }} />
            </Tag>
          ),
          value: "PENDING",
        },
        {
          text: (
            <Tag color="cyan">
              Annulée <MinusCircleOutlined style={{ marginLeft: "5px" }} />
            </Tag>
          ),
          value: "CANCELLED",
        },
      ],
      onFilter: (value, record) => record.status.includes(value),
      render: (_, record) => (
        <Steps
          items={[
            {
              title: "Responsable",
              status:
                record?.status !== "PENDING"
                  ? "finish"
                  : record?.status === !record?.answered_by[0]?.by
                  ? "process"
                  : "finish",
              description:
                record?.status === "CANCELLED"
                  ? "Annulé"
                  : record?.status === "REJECTED"
                  ? "Rejeté"
                  : record?.answered_by[0]?.by || "En attente",
              icon:
                record?.status !== "PENDING" && !record?.answered_by[0]?.by ? (
                  <MinusCircleTwoTone twoToneColor={"#b1b1b1"} />
                ) : !record?.answered_by[0]?.by ? (
                  <LoadingOutlined />
                ) : record?.answered_by[0].status ? (
                  <CheckCircleTwoTone twoToneColor={"#87d068"} />
                ) : (
                  <CloseCircleTwoTone twoToneColor={"#f3531e"} />
                ),
            },
            {
              title: "RH",
              status:
                record?.status !== "PENDING"
                  ? "finish"
                  : !record?.answered_by[0]?.by ||
                    !record?.answered_by[0]?.status
                  ? "wait"
                  : record?.answered_by[0]?.by && !record?.answered_by[1]?.by
                  ? "process"
                  : "finish",
              description:
                record?.status === "CANCELLED"
                  ? "Annulé"
                  : record?.status === "REJECTED"
                  ? "Rejeté"
                  : record?.answered_by[1]?.by || "En attente",
              icon:
                record?.status !== "PENDING" && !record?.answered_by[1]?.by ? (
                  <MinusCircleTwoTone twoToneColor={"#b1b1b1"} />
                ) : !record?.answered_by[0]?.by ||
                  !record?.answered_by[0]?.status ? (
                  <MinusCircleTwoTone twoToneColor={"#b1b1b1"} />
                ) : record?.answered_by[0]?.by &&
                  !record?.answered_by[1]?.by ? (
                  <LoadingOutlined />
                ) : record?.answered_by[1].status ? (
                  <CheckCircleTwoTone twoToneColor={"#87d068"} />
                ) : (
                  <CloseCircleTwoTone twoToneColor={"#f3531e"} />
                ),
            },
            {
              title: "Sys-Admin",
              status:
                record?.status !== "PENDING"
                  ? "finish"
                  : !record?.answered_by[1]?.by ||
                    !record?.answered_by[1]?.status
                  ? "wait"
                  : record?.answered_by[1]?.by && !record?.answered_by[2]?.by
                  ? "process"
                  : "finish",
              description:
                record?.status === "CANCELLED"
                  ? "Annulé"
                  : record?.status === "REJECTED"
                  ? "Rejeté"
                  : record?.answered_by[2]?.by || "En attente",
              icon:
                record?.status !== "PENDING" && !record?.answered_by[2]?.by ? (
                  <MinusCircleTwoTone twoToneColor={"#b1b1b1"} />
                ) : !record?.answered_by[1]?.by ||
                  !record?.answered_by[1]?.status ? (
                  <MinusCircleTwoTone twoToneColor={"#b1b1b1"} />
                ) : record?.answered_by[1]?.by &&
                  !record?.answered_by[2]?.by ? (
                  <LoadingOutlined />
                ) : record?.answered_by[2].status ? (
                  <CheckCircleTwoTone twoToneColor={"#87d068"} />
                ) : (
                  <CloseCircleTwoTone twoToneColor={"#f3531e"} />
                ),
            },
          ]}
          size="small"
        />
      ),
    },
    {
      title: "Action",
      render: (_, record) => (
        <Space>
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: "#52C41A",
              },
            }}
          >
            <Popconfirm
              placement="topLeft"
              title="Etes-vous sûr d'approuver la demande ?"
              okText="Oui"
              cancelText="Non"
              onConfirm={() => handleApprove(record?._id)}
              disabled={
                record?.answered_by[
                  profile?.sub_role === "SUPER_ADMIN" ? 1 : 2
                ] || record?.status !== "PENDING"
              }
            >
              {profile?.sub_role === "SUPER_ADMIN" ? (
                <Tooltip title="Approuver la demande">
                  <Button
                    size="small"
                    type="primary"
                    shape="circle"
                    disabled={
                      record?.answered_by.length !== 1 ||
                      record?.status !== "PENDING"
                    }
                  >
                    <FontAwesomeIcon icon={faCheck} />
                  </Button>
                </Tooltip>
              ) : (
                <EditAccessDrawer
                  data={access?.find(
                    (elem) => elem?.matricule === record?.sender_id
                  )}
                  accessTypes={accessTypes}
                  refresh={refresh}
                  setRefresh={setRefresh}
                  record={record}
                  index={profile?.sub_role === "SUPER_ADMIN" ? 1 : 2}
                />
              )}
            </Popconfirm>
          </ConfigProvider>
          <RejectModal
            refresh={refresh}
            setRefresh={setRefresh}
            record={record}
            index={profile?.sub_role === "SUPER_ADMIN" ? 1 : 2}
          />
          <AccessRequestDrawer record={record} />
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Table
        columns={columns}
        loading={loading}
        dataSource={records}
        scroll={{ x: "max-content" }}
      />
    </div>
  );
};

export default AccessRequests;
