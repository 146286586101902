import React, { useEffect, useState } from "react";
import { Spin, Typography, message } from "antd";
import axiosInstance from "../../Utils/axios";
import GeneralProfile from "./Components/GeneralProfile";

const Profile = () => {
  const [profile, setProfile] = useState({});
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(true);

  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await axiosInstance.get("/api/employees/profile");

        setProfile(data?.employee);
        setLoading(false);
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    }
    fetchData();
  }, []);

  return (
    <div className="p-4">
      <div className="container pb-3">
        <Typography.Title level={4}>🪪 Mes Informations :</Typography.Title>
        {loading ? (
          <div className="d-flex justify-content-center align-items-center ">
            <Spin size="large" />
          </div>
        ) : (
          <GeneralProfile
            data={profile}
            refresh={refresh}
            setRefresh={setRefresh}
          />
        )}
      </div>
    </div>
  );
};

export default Profile;
