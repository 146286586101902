import React, { useState } from 'react';
import { Button, Modal, Form, Input, Space, message } from 'antd';
import axiosInstance from '../../../Utils/axios';
const FormServer = ({ servers, setServers }) => {
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();

  const handleSubmit = async (values) => {
    try {
      const { data } = await axiosInstance.post('/api/administration/server/create', values);

      const temp = [...servers, data?.data?.server];
      setServers(temp);
      form.resetFields();
      setOpen(false);
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  }

  return (
    <>
      <Button type="primary" shape='rounded' onClick={() => setOpen(true)}>
        Nouveau serveur
      </Button>
      <Modal
        title="✅ Nouveau serveur"
        centered
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        width={800}
        footer={[
          <Space>
            <Button key="back" onClick={() => setOpen(false)}>
              Annuler
            </Button>
            <Button key="submit" type="primary" onClick={() => form.submit()}>
              Enregistrer
            </Button>
          </Space>
        ]}
      >
        <Form onFinish={handleSubmit} form={form} layout='vertical' className='row rounded-3 p-3'>
          <Form.Item className='col-lg-6' name={'hostName'} label='HostName' rules={[{ whitespace: true, required: true, message: 'Ce champ est obligatoire' }]}>
            <Input placeholder='HostName' />
          </Form.Item>
          <Form.Item className='col-lg-6' name={'ipAddress'} label='IP Address' rules={[{ whitespace: true, required: true, message: 'Ce champ est obligatoire' }]}>
            <Input placeholder='IP Address' />
          </Form.Item>
          <Form.Item className='col-lg-4' name={'region'} label='Region' rules={[{ whitespace: true, required: true, message: 'Ce champ est obligatoire' }]}>
            <Input placeholder='Region' />
          </Form.Item>
          <Form.Item className='col-lg-4' name={'vendor'} label='Vendeur' rules={[{ whitespace: true, required: true, message: 'Ce champ est obligatoire' }]}>
            <Input placeholder='Vendeur' />
          </Form.Item>
          <Form.Item className='col-lg-4' name={'pseudo'} label='Désignation'>
            <Input placeholder='Désignation exp: M15' />
          </Form.Item>
          <Form.Item name={'urlDashboardVendor'} label='URL dashboard du vendeur' rules={[{ whitespace: true, required: true, message: 'Ce champ est obligatoire' }]}>
            <Input placeholder='URL dashboard du vendeur' />
          </Form.Item>
          <Form.Item name={'urlManagement'} label='URL de gestion'>
            <Input placeholder='URL de gestion' />
          </Form.Item>

        </Form>
      </Modal>
    </>
  );
};
export default FormServer;