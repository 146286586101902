import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  Form,
  DatePicker,
  Input,
  Spin,
  Typography,
  Collapse,
  Modal,
  Button,
  message,
  Checkbox,
  Space,
  theme as antdTheme
} from "antd";

import { LoadingOutlined, ExclamationCircleFilled } from "@ant-design/icons";

import axios from "../../Utils/axios";
import dayjs from "dayjs";
import GlobalContext from "../../Context/GlobalContext";

const { TextArea } = Input;
const { confirm } = Modal;
const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
    spin
  />
);

const TeleworkForm = () => {
  const { profile } = useContext(GlobalContext);
  const [form] = Form.useForm();
  const [Loading, setLoading] = useState(true);
  const [disabled, setDisabled] = useState(false);

  const yearlyLeaveType = "Standare";
  const Xs = "";

  const Navigate = useNavigate();

  useEffect(() => {
    async function fetchReasons() {
      const { data } = await axios.get("/api/types");
      setLoading(false);
      return data.types;
    }

    fetchReasons();

    form.setFieldValue("_typeLeave", "Télétravail");
    // eslint-disable-next-line
  }, []);

  const [beginingDate, setBeginingDate] = useState();

  const disabledDateAuth = (current) => {
    return current && current < dayjs().add(-1, "days");
  };

  const disabledEndDate = (current) => {
    return current && current < dayjs(beginingDate).endOf("day");
  };

  const handlePostLeave = () => {
    form
      .validateFields()
      .then(async (res) => {
        let formValues = form.getFieldsValue();
        setDisabled(true);
        formValues._typeLeave = "Télétravail";
        if (formValues._typeLeave === "Autorisation") {
          formValues.EndingDate = formValues.BeginingDate;
        }
        if (formValues._typeLeave === "Télétravail") {
          formValues._except = SaufDays;
        }
        if (
          formValues._typeLeave === "Congé annuel" &&
          yearlyLeaveType === "Half"
        ) {
          if (!Xs) {
            return message.error("Veuillez choisir la seance");
          }
          formValues.EndingDate = formValues.BeginingDate;
          formValues.typeAnnuel = yearlyLeaveType;
          formValues.typeHalf = Xs;
        } else if (
          formValues._typeLeave === "Congé annuel" &&
          yearlyLeaveType === "Standare"
        ) {
          formValues.typeAnnuel = yearlyLeaveType;
        }

        if (
          formValues._typeLeave === "Congé sans solde" &&
          yearlyLeaveType === "Half"
        ) {
          if (!Xs) {
            return message.error("Veuillez choisir la seance");
          }
          formValues.EndingDate = formValues.BeginingDate;
          formValues.typeAnnuel = yearlyLeaveType;
          formValues.typeHalf = Xs;
        } else if (
          formValues._typeLeave === "Congé sans solde" &&
          yearlyLeaveType === "Standare"
        ) {
          formValues.typeAnnuel = yearlyLeaveType;
        }

        try {
          const { data } = await axios.post("/api/leaves/save/leave/telework", formValues);
          if (data) {
            Modal.success({
              title: "Votre demande a été soumise avec succès !",
              content: (
                <div style={{ whiteSpace: "pre-wrap" }}>{data.message}</div>
              ),
            });
            let temp = profile;

            if (data?.newLeave?._typeLeave === "Congé annuel") {
              temp.sold -= data?.newLeave?.DaysNumber;
            }

            setDisabled(false);
            Navigate("/manage/self/telework");
          }
        } catch (error) {
          Modal.error({
            title: "Impossible de soumettre la demande !",
            content: (
              <div style={{ whiteSpace: "pre-wrap" }}>
                {error.response.data.message}
              </div>
            ),
          });
          setDisabled(false);
        }
      })
      .catch((error) => {
        setDisabled(false);
        return error;
      });
  };

  const showConfirm = () => {
    confirm({
      title: "Confirmation",
      icon: <ExclamationCircleFilled />,
      content: (
        <Typography.Paragraph>
          En soumettant cette demande, je confirme avoir pris connaissance et accepté les conditions du télétravail.
        </Typography.Paragraph>
      ),
      onOk() {
        handlePostLeave();
      },
      okText: "J'accepte",
    });
  };

  const maxChecked = 4; // nombre maximum de cases à cocher
  const [SaufDays, setSaufDays] = useState([]);
  const [checkedCount, setCheckedCount] = useState(0);

  const slectDay = (e) => {
    if (SaufDays.includes(e.target.name)) {
      let tab = [...SaufDays];
      tab.splice(tab.indexOf(e.target.name), 1);
      setSaufDays(tab);
    } else {
      if (SaufDays.length < maxChecked) {
        let tab = [...SaufDays];
        tab.push(e.target.name);
        setSaufDays(tab);
      } else {
        return message.error("Veuillez choisir maximum 4 choix");
      }
    }
  };

  const handleCheckboxChange = (e) => {
    const checked = e.target.checked;
    const newCount = checked ? checkedCount + 1 : checkedCount - 1;
    setCheckedCount(newCount);
  };

  const {
    token: { colorBgContainer },
  } = antdTheme.useToken();

  return (
    <div className="py-4">
      {Loading ? (
        <div className="d-flex justify-content-center ">
          <Spin size="large" />
        </div>
      ) : (
        <div className=" p-4   ">
          <div>
            <div className="mb-2 d-flex justify-content-between">
              <Typography.Title level={4}>🆕 Nouvelle demande de télétravail</Typography.Title>
              <Button
                type="primary"
                shape="round"
                onClick={() => Navigate("/manage/self")}
              >
                Mes demandes
              </Button>
            </div>
            <div className="row border-top mt-2 ">
              <div className="col-lg-6 col-md-12 col-sm-12 rounded-3 mt-3 p-3" style={{ backgroundColor: colorBgContainer }} >
                <Form
                  form={form}
                  layout="vertical"
                  size="large"
                  disabled={disabled}
                >


                  <Form.Item
                    name="BeginingDate"
                    onChange={(dateString) => {
                      setBeginingDate(dateString);
                    }}
                    rules={[
                      {
                        required: true,
                        message: "Veuillez remplir le champ",
                      },
                    ]}
                    label="Date de début"
                  >
                    <DatePicker
                      disabledDate={disabledDateAuth}
                      format={"DD/MM/YYYY"}
                      style={{ width: "100%" }}
                      placeholder="Date de début"
                      showToday={false}
                    />
                  </Form.Item>
                  <Form.Item
                    name="EndingDate"
                    rules={[
                      {
                        required: true,
                        message: "Veuillez remplir le champ",
                      },
                    ]}
                    label="Date de fin:"
                  >
                    <DatePicker
                      disabledDate={disabledEndDate}
                      format={"DD/MM/YYYY"}
                      style={{ width: "100%" }}
                      placeholder="Date de fin"
                      showToday={false}
                    />
                  </Form.Item>

                  <Form.Item label="Sauf">
                    <Space >
                      <div >
                        <Checkbox
                          name="lundi"
                          onChange={(e) => {
                            slectDay(e);
                            handleCheckboxChange(e);
                          }}
                        >
                          lundi
                        </Checkbox>
                      </div>

                      <div >
                        <Checkbox
                          name="mardi"
                          onChange={(e) => {
                            slectDay(e);
                            handleCheckboxChange(e);
                          }}
                        >
                          Mardi
                        </Checkbox>
                      </div>
                      <div >
                        <Checkbox
                          name="mercredi"
                          onChange={(e) => {
                            slectDay(e);
                            handleCheckboxChange(e);
                          }}
                        >
                          Mercredi
                        </Checkbox>
                      </div>
                      <div >
                        <Checkbox
                          name="jeudi"
                          onChange={(e) => {
                            slectDay(e);
                            handleCheckboxChange(e);
                          }}
                        >
                          Jeudi
                        </Checkbox>
                      </div>
                      <div >
                        <Checkbox
                          name="vendredi"
                          onChange={(e) => {
                            slectDay(e);
                            handleCheckboxChange(e);
                          }}
                        >
                          Vendredi
                        </Checkbox>
                      </div>
                    </Space>
                  </Form.Item>

                  <Form.Item
                    name="_motif"
                    rules={[
                      {
                        required: true,
                        message: "Veuillez remplir le champ",
                        whitespace: true,
                      },
                    ]}
                    label="Motif"
                  >
                    <TextArea
                      placeholder="N'hésitez pas à détailler la cause de votre demande pour une meilleure compréhension et pour une gestion plus efficace."
                      autoSize={{
                        minRows: 2,
                        maxRows: 3,
                      }}
                    />
                  </Form.Item>

                  <div>
                    <Button
                      onClick={showConfirm}
                      disabled={disabled || checkedCount >= 5}
                      type="primary"
                    >
                      Soumettre !
                    </Button>

                    {disabled && (
                      <Spin
                        style={{ marginLeft: "10px" }}
                        size="large"
                        indicator={antIcon}
                      />
                    )}
                  </div>
                </Form>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 ">
                <h6
                  className="fw-bold py-2"
                  style={{ color: "#2E79A8" }}
                >
                  Avant de soumettre votre demande de télétravail, veuillez noter les points suivants :
                </h6>
                <Collapse
                  className="shadow-md rounded p-3"
                  defaultActiveKey={["1"]}
                >
                  <p style={{ fontSize: "14px" }}>
                    🚀 <span className="fw-bold">Approbation Préalable</span> : La
                    demande de télétravail doit être approuvée en amont. En
                    l'absence d'approbation à temps, la demande sera considérée
                    comme rejetée, et vous ne pourrez pas bénéficier du
                    télétravail.
                    <br />
                    <br />
                    📋 <span className="fw-bold">Conditions requises</span> :
                    <br />
                    <ul>
                      <li>
                        Fournir une raison valable et acceptable dans la demande.
                      </li>
                      <li>
                        S'assurer que les tâches à réaliser ne nécessitent pas une
                        présence physique sur site.
                      </li>
                      <li>
                        Disposer d'une connexion internet stable, d'accès distant
                        aux fichiers et applications, et du matériel nécessaire.
                      </li>
                      <li>Prioriser la sécurité des données et des appareils.</li>
                      <li style={{ color: "red", fontWeight: 500 }} className="fade-text ">
                        Être disponible sur Skype pendant les plages horaires de
                        travail convenues.
                      </li>
                    </ul>
                    📅 <span className="fw-bold text-primary">Durée Maximale</span> : La durée maximale d'une demande de
                    télétravail est fixée à 3 mois. Vous recevrez une notification
                    par email avant la fin de la période de télétravail.
                    <br /> <br />
                    ⚠️ <span className="fw-bold text-warning">Note Importante</span> : Tout manquement aux conditions de la
                    politique de télétravail peut entraîner l'annulation du
                    télétravail, le refus des prochaines demandes, ou des mesures
                    disciplinaires.
                  </p>

                </Collapse>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TeleworkForm;
