import React from "react";
import TodoTable from "../../Components/TodoList/TodoTable";

const Home = () => {
  return (
    <div className="container">
      <div className="row mb-4">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="  p-3 h-100">
            <TodoTable />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
