import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../../../Utils/axios";
import { CheckOutlined } from "@ant-design/icons";
import {
  Button,
  Descriptions,
  Popconfirm,
  Skeleton,
  Typography,
  message,
} from "antd";
import dayjs from "dayjs";
import axiosInstance from "../../../Utils/axios";

const { Text, Link } = Typography;
const EditRequest = () => {
  const [currentProfile, setCurrentProfile] = useState({});
  const [incomingProfile, setIncomingProfile] = useState({});
  const [finalProfile, setFinalProfile] = useState({});
  const [loading, setLoading] = useState(true);
  const { matricule, _id } = useParams();

  const Navigate = useNavigate();
  useEffect(() => {
    async function fetchProfile() {
      const { data } = await axios.get(
        "/api/employees/" + matricule + "?edit=1"
      );
      setCurrentProfile(data.employee[0]);
      setIncomingProfile(JSON.parse(data.employee[0]._edit));
      let temp = data.employee[0];
      delete temp._id;
      temp._edit = " ";
      setFinalProfile(temp);
      setLoading(false);
    }
    fetchProfile();
  }, []);

  const handleChange = (key, value) => {
    setFinalProfile({ ...finalProfile, [key]: value });
  };

  const handleSave = async () => {
    axiosInstance
      .patch("/api/employees/" + finalProfile.matricule, finalProfile)
      .then((res) => {
        message.success("Profil modifié avec succès");
        if (res.data.status === "success")
          axiosInstance
            .patch("/api/request/" + _id, {
              status: "APPROVED",
            })
            .then((res) => {
              message.success(
                "La demande de modification a été modifiée avec succès"
              );
              Navigate("/administration/employees/" + finalProfile.matricule);
            })
            .catch((err) => message.error(err.response.data.message));
      })
      .catch((err) => message.error(err.response.data.message));
  };

  return (
    <div className="  p-4 ">
      <div className="row">
        {/* CURRENT BLOCK */}
        <div className="col-lg-6 col-md-6 col-sm-12 mt-2 p-2 ">
          <Descriptions
            column={{
              xxl: 2,
              xl: 2,
              lg: 2,
              md: 1,
              sm: 1,
              xs: 1,
            }}
            title={<h6>Profil sans modification</h6>}
          >
            <Descriptions.Item label="Nom et prénom">
              {!loading ? (
                <>
                  <Text
                    mark={currentProfile.fullName !== incomingProfile.fullName}
                  >
                    {currentProfile.fullName}{" "}
                  </Text>{" "}
                  {currentProfile.fullName !== incomingProfile.fullName && (
                    <Popconfirm
                      placement="topLeft"
                      title="Accepter courant"
                      okText={"Oui"}
                      onConfirm={() =>
                        handleChange("fullName", currentProfile.fullName)
                      }
                    >
                      <Link>
                        <CheckOutlined className="mx-1" />
                      </Link>
                    </Popconfirm>
                  )}
                </>
              ) : (
                <Skeleton.Input active size={"small"} />
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Téléphone">
              {!loading ? (
                <>
                  <Text
                    mark={currentProfile.phoneNum !== incomingProfile.phoneNum}
                  >
                    {currentProfile.phoneNum}
                  </Text>
                  {currentProfile.phoneNum !== incomingProfile.phoneNum && (
                    <Popconfirm
                      placement="topLeft"
                      title="Accepter courant"
                      okText={"Oui"}
                      onConfirm={() =>
                        handleChange("phoneNum", currentProfile.phoneNum)
                      }
                    >
                      <Link>
                        <CheckOutlined className="mx-1" />
                      </Link>
                    </Popconfirm>
                  )}
                </>
              ) : (
                <Skeleton.Input active size="small" />
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Address">
              {!loading ? (
                <>
                  <Text
                    mark={currentProfile.address !== incomingProfile.address}
                  >
                    {currentProfile.address}
                  </Text>
                  {currentProfile.address !== incomingProfile.address && (
                    <Popconfirm
                      placement="topLeft"
                      title="Accepter courant"
                      okText={"Oui"}
                      onConfirm={() =>
                        handleChange("address", currentProfile.address)
                      }
                    >
                      <Link>
                        <CheckOutlined className="mx-1" />
                      </Link>
                    </Popconfirm>
                  )}
                </>
              ) : (
                <Skeleton.Input active size="small" />
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Date de naissance">
              {!loading ? (
                <>
                  {" "}
                  <Text
                    mark={
                      currentProfile.birthDate !== incomingProfile.birthDate
                    }
                  >
                    {dayjs(currentProfile.birthDate).format("DD/MM/YYYY")}
                  </Text>
                  {currentProfile.birthDate !== incomingProfile.birthDate && (
                    <Popconfirm
                      placement="topLeft"
                      title="Accepter courant"
                      okText={"Oui"}
                      onConfirm={() =>
                        handleChange("birthDate", currentProfile.birthDate)
                      }
                    >
                      <Link>
                        <CheckOutlined className="mx-1" />
                      </Link>
                    </Popconfirm>
                  )}
                </>
              ) : (
                <Skeleton.Input active size="small" />
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Address de naissance">
              {!loading ? (
                <>
                  <Text
                    mark={
                      currentProfile.birthPlace !== incomingProfile.birthPlace
                    }
                  >
                    {currentProfile.birthPlace}
                  </Text>
                  {currentProfile.birthPlace !== incomingProfile.birthPlace && (
                    <Popconfirm
                      placement="topLeft"
                      title="Accepter courant"
                      okText={"Oui"}
                      onConfirm={() =>
                        handleChange("birthPlace", currentProfile.birthPlace)
                      }
                    >
                      <Link>
                        <CheckOutlined className="mx-1" />
                      </Link>
                    </Popconfirm>
                  )}
                </>
              ) : (
                <Skeleton.Input active size="small" />
              )}
            </Descriptions.Item>
          </Descriptions>
          <Descriptions
            column={{
              xxl: 2,
              xl: 2,
              lg: 2,
              md: 1,
              sm: 1,
              xs: 1,
            }}
          >
            <Descriptions.Item label="CIN">
              {!loading ? (
                <>
                  <Text mark={currentProfile.CIN !== incomingProfile.CIN}>
                    {currentProfile.CIN}
                  </Text>
                  {currentProfile.CIN !== incomingProfile.CIN && (
                    <Popconfirm
                      placement="topLeft"
                      title="Accepter courant"
                      okText={"Oui"}
                      onConfirm={() => handleChange("CIN", currentProfile.CIN)}
                    >
                      <Link>
                        <CheckOutlined className="mx-1" />
                      </Link>
                    </Popconfirm>
                  )}
                </>
              ) : (
                <Skeleton.Input active size="small" />
              )}
            </Descriptions.Item>
            <Descriptions.Item label="RIB">
              {!loading ? (
                <>
                  <Text mark={currentProfile.Rib !== incomingProfile.Rib}>
                    {currentProfile.Rib}
                  </Text>
                  {currentProfile.Rib !== incomingProfile.Rib && (
                    <Popconfirm
                      placement="topLeft"
                      title="Accepter courant"
                      okText={"Oui"}
                      onConfirm={() => handleChange("Rib", currentProfile.Rib)}
                    >
                      <Link>
                        <CheckOutlined className="mx-1" />
                      </Link>
                    </Popconfirm>
                  )}
                </>
              ) : (
                <Skeleton.Input active size="small" />
              )}
            </Descriptions.Item>
            <Descriptions.Item label="CV">
              {!loading ? (
                <>
                  <Text mark={currentProfile.CV !== incomingProfile.CV}>
                    <a href={currentProfile.CV} download={"CURRENT_CV"}>
                      Piéce jointe
                    </a>
                  </Text>
                  {currentProfile.CV !== incomingProfile.CV && (
                    <Popconfirm
                      placement="topLeft"
                      title="Accepter courant"
                      okText={"Oui"}
                      onConfirm={() => handleChange("CV", currentProfile.CV)}
                    >
                      <Link>
                        <CheckOutlined className="mx-1" />
                      </Link>
                    </Popconfirm>
                  )}
                </>
              ) : (
                <Skeleton.Input active size="small" />
              )}
            </Descriptions.Item>
            <Descriptions.Item label="CIN">
              {!loading ? (
                <>
                  <Text
                    mark={
                      currentProfile._cin_File !== incomingProfile._cin_File
                    }
                  >
                    <a href={currentProfile._cin_File} download={"CURRENT_CIN"}>
                      Piéce jointe
                    </a>
                  </Text>
                  {currentProfile._cin_File !== incomingProfile._cin_File && (
                    <Popconfirm
                      placement="topLeft"
                      title="Accepter courant"
                      okText={"Oui"}
                      onConfirm={() =>
                        handleChange("_cin_File", currentProfile._cin_File)
                      }
                    >
                      <Link>
                        <CheckOutlined className="mx-1" />
                      </Link>
                    </Popconfirm>
                  )}
                </>
              ) : (
                <Skeleton.Input active size="small" />
              )}
            </Descriptions.Item>
            <Descriptions.Item label="RIB">
              {!loading ? (
                <>
                  <Text
                    mark={
                      currentProfile._rib_File !== incomingProfile._rib_File
                    }
                  >
                    <a href={currentProfile._rib_File} download={"CURRENT_RIB"}>
                      Piéce jointe
                    </a>
                  </Text>
                  {currentProfile._rib_File !== incomingProfile._rib_File && (
                    <Popconfirm
                      placement="topLeft"
                      title="Accepter courant"
                      okText={"Oui"}
                      onConfirm={() =>
                        handleChange("_rib_File", currentProfile._rib_File)
                      }
                    >
                      <Link>
                        <CheckOutlined className="mx-1" />
                      </Link>
                    </Popconfirm>
                  )}
                </>
              ) : (
                <Skeleton.Input active size="small" />
              )}
            </Descriptions.Item>
          </Descriptions>
          <Descriptions
            title="Personne à prévenir en cas d'urgence"
            column={{
              xxl: 2,
              xl: 2,
              lg: 2,
              md: 1,
              sm: 1,
              xs: 1,
            }}
          >
            <Descriptions.Item label="Nom et prénom">
              {!loading ? (
                <>
                  <Text
                    mark={
                      currentProfile._urgence_name !==
                      incomingProfile._urgence_name
                    }
                  >
                    {currentProfile._urgence_name}
                  </Text>
                  {currentProfile._urgence_name !==
                    incomingProfile._urgence_name && (
                      <Popconfirm
                        placement="topLeft"
                        title="Accepter courant"
                        okText={"Oui"}
                        onConfirm={() =>
                          handleChange(
                            "_urgence_name",
                            currentProfile._urgence_name
                          )
                        }
                      >
                        <Link>
                          <CheckOutlined className="mx-1" />
                        </Link>
                      </Popconfirm>
                    )}
                </>
              ) : (
                <Skeleton.Input active size="small" />
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Téléphone">
              {!loading ? (
                <>
                  <Text
                    mark={
                      currentProfile._urgence_phone !==
                      incomingProfile._urgence_phone
                    }
                  >
                    {currentProfile._urgence_phone}
                  </Text>
                  {currentProfile._urgence_phone !==
                    incomingProfile._urgence_phone && (
                      <Popconfirm
                        placement="topLeft"
                        title="Accepter courant"
                        okText={"Oui"}
                        onConfirm={() =>
                          handleChange(
                            "_urgence_phone",
                            currentProfile._urgence_phone
                          )
                        }
                      >
                        <Link>
                          <CheckOutlined className="mx-1" />
                        </Link>
                      </Popconfirm>
                    )}
                </>
              ) : (
                <Skeleton.Input active size="small" />
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Relation">
              {!loading ? (
                <>
                  <Text
                    mark={
                      currentProfile._urgence_relation !==
                      incomingProfile._urgence_relation
                    }
                  >
                    {currentProfile._urgence_relation}
                  </Text>
                  {currentProfile._urgence_relation !==
                    incomingProfile._urgence_relation && (
                      <Popconfirm
                        placement="topLeft"
                        title="Accepter courant"
                        okText={"Oui"}
                        onConfirm={() =>
                          handleChange(
                            "_urgence_relation",
                            currentProfile._urgence_relation
                          )
                        }
                      >
                        <Link>
                          <CheckOutlined className="mx-1" />
                        </Link>
                      </Popconfirm>
                    )}
                </>
              ) : (
                <Skeleton.Input active size="small" />
              )}
            </Descriptions.Item>
          </Descriptions>
        </div>
        {/* INCOMING BLOCK */}
        <div className="col-lg-6 col-md-6 col-sm-12 mt-2 bg-light p-2 ">
          <Descriptions
            column={{
              xxl: 2,
              xl: 2,
              lg: 2,
              md: 1,
              sm: 1,
              xs: 1,
            }}
            title={<h6>Profil avec modification</h6>}
          >
            <Descriptions.Item label="Nom et prénom">
              {!loading ? (
                <>
                  <Text
                    mark={currentProfile.fullName !== incomingProfile.fullName}
                  >
                    {incomingProfile.fullName}
                  </Text>
                  {currentProfile.fullName !== incomingProfile.fullName && (
                    <Popconfirm
                      placement="topLeft"
                      title="Accepter entrant"
                      okText={"Oui"}
                      onConfirm={() =>
                        handleChange("fullName", incomingProfile.fullName)
                      }
                    >
                      <Link>
                        <CheckOutlined className="mx-1" />
                      </Link>
                    </Popconfirm>
                  )}
                </>
              ) : (
                <Skeleton.Input active size="small" />
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Téléphone">
              {!loading ? (
                <>
                  <Text
                    mark={currentProfile.phoneNum !== incomingProfile.phoneNum}
                  >
                    {incomingProfile.phoneNum}
                  </Text>
                  {currentProfile.phoneNum !== incomingProfile.phoneNum && (
                    <Popconfirm
                      placement="topLeft"
                      title="Accepter entrant"
                      okText={"Oui"}
                      onConfirm={() =>
                        handleChange("phoneNum", incomingProfile.phoneNum)
                      }
                    >
                      <Link>
                        <CheckOutlined className="mx-1" />
                      </Link>
                    </Popconfirm>
                  )}
                </>
              ) : (
                <Skeleton.Input active size="small" />
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Address">
              {!loading ? (
                <>
                  <Text
                    mark={currentProfile.address !== incomingProfile.address}
                  >
                    {incomingProfile.address}
                  </Text>
                  {currentProfile.address !== incomingProfile.address && (
                    <Popconfirm
                      placement="topLeft"
                      title="Accepter entrant"
                      okText={"Oui"}
                      onConfirm={() =>
                        handleChange("address", incomingProfile.address)
                      }
                    >
                      <Link>
                        <CheckOutlined className="mx-1" />
                      </Link>
                    </Popconfirm>
                  )}
                </>
              ) : (
                <Skeleton.Input active size="small" />
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Date de naissance">
              {!loading ? (
                <>
                  <Text
                    mark={
                      currentProfile.birthDate !== incomingProfile.birthDate
                    }
                  >
                    {dayjs(incomingProfile.birthDate).format("DD/MM/YYYY")}
                  </Text>
                  {currentProfile.birthDate !== incomingProfile.birthDate && (
                    <Popconfirm
                      placement="topLeft"
                      title="Accepter entrant"
                      okText={"Oui"}
                      onConfirm={() =>
                        handleChange("birthDate", incomingProfile.birthDate)
                      }
                    >
                      <Link>
                        <CheckOutlined className="mx-1" />
                      </Link>
                    </Popconfirm>
                  )}
                </>
              ) : (
                <Skeleton.Input active size="small" />
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Address de naissance">
              {!loading ? (
                <>
                  <Text
                    mark={
                      currentProfile.birthPlace !== incomingProfile.birthPlace
                    }
                  >
                    {incomingProfile.birthPlace}
                  </Text>
                  {currentProfile.birthPlace !== incomingProfile.birthPlace && (
                    <Popconfirm
                      placement="topLeft"
                      title="Accepter entrant"
                      okText={"Oui"}
                      onConfirm={() =>
                        handleChange("birthPlace", incomingProfile.birthPlace)
                      }
                    >
                      <Link>
                        <CheckOutlined className="mx-1" />
                      </Link>
                    </Popconfirm>
                  )}
                </>
              ) : (
                <Skeleton.Input active size="small" />
              )}
            </Descriptions.Item>
          </Descriptions>
          <Descriptions
            column={{
              xxl: 2,
              xl: 2,
              lg: 2,
              md: 1,
              sm: 1,
              xs: 1,
            }}
          >
            <Descriptions.Item label="CIN">
              {!loading ? (
                <>
                  <Text mark={currentProfile.CIN !== incomingProfile.CIN}>
                    {incomingProfile.CIN}
                  </Text>
                  {currentProfile.CIN !== incomingProfile.CIN && (
                    <Popconfirm
                      placement="topLeft"
                      title="Accepter entrant"
                      okText={"Oui"}
                      onConfirm={() => handleChange("CIN", incomingProfile.CIN)}
                    >
                      <Link>
                        <CheckOutlined className="mx-1" />
                      </Link>
                    </Popconfirm>
                  )}
                </>
              ) : (
                <Skeleton.Input active size="small" />
              )}
            </Descriptions.Item>
            <Descriptions.Item label="RIB">
              {!loading ? (
                <>
                  <Text mark={currentProfile.Rib !== incomingProfile.Rib}>
                    {incomingProfile.Rib}
                  </Text>
                  {currentProfile.Rib !== incomingProfile.Rib && (
                    <Popconfirm
                      placement="topLeft"
                      title="Accepter entrant"
                      okText={"Oui"}
                      onConfirm={() => handleChange("Rib", incomingProfile.Rib)}
                    >
                      <Link>
                        <CheckOutlined className="mx-1" />
                      </Link>
                    </Popconfirm>
                  )}
                </>
              ) : (
                <Skeleton.Input active size="small" />
              )}
            </Descriptions.Item>
            <Descriptions.Item label="CV">
              {!loading ? (
                <>
                  <Text mark={currentProfile.CV !== incomingProfile.CV}>
                    <a href={incomingProfile.CV} download={"INCOMING_CV"}>
                      Piéce jointe
                    </a>
                  </Text>

                  {currentProfile.CV !== incomingProfile.CV && (
                    <Popconfirm
                      placement="topLeft"
                      title="Accepter entrant"
                      okText={"Oui"}
                      onConfirm={() => handleChange("CV", incomingProfile.CV)}
                    >
                      <Link>
                        <CheckOutlined className="mx-1" />
                      </Link>
                    </Popconfirm>
                  )}
                </>
              ) : (
                <Skeleton.Input active size="small" />
              )}
            </Descriptions.Item>
            <Descriptions.Item label="CIN">
              {!loading ? (
                <>
                  <Text
                    mark={
                      currentProfile._cin_File !== incomingProfile._cin_File
                    }
                  >
                    <a
                      href={incomingProfile._cin_File}
                      download={"INCOMING_CIN"}
                    >
                      Piéce jointe
                    </a>
                  </Text>
                  {currentProfile._cin_File !== incomingProfile._cin_File && (
                    <Popconfirm
                      placement="topLeft"
                      title="Accepter entrant"
                      okText={"Oui"}
                      onConfirm={() =>
                        handleChange("_cin_File", incomingProfile._cin_File)
                      }
                    >
                      <Link>
                        <CheckOutlined className="mx-1" />
                      </Link>
                    </Popconfirm>
                  )}{" "}
                </>
              ) : (
                <Skeleton.Input active size="small" />
              )}
            </Descriptions.Item>
            <Descriptions.Item label="RIB">
              {!loading ? (
                <>
                  <Text
                    mark={
                      currentProfile._rib_File !== incomingProfile._rib_File
                    }
                  >
                    <a
                      href={incomingProfile._rib_File}
                      download={"INCOMING_RIB"}
                    >
                      Piéce jointe
                    </a>
                  </Text>
                  {currentProfile._rib_File !== incomingProfile._rib_File && (
                    <Popconfirm
                      placement="topLeft"
                      title="Accepter entrant"
                      okText={"Oui"}
                      onConfirm={() =>
                        handleChange("_rib_File", incomingProfile._rib_File)
                      }
                    >
                      <Link>
                        <CheckOutlined className="mx-1" />
                      </Link>
                    </Popconfirm>
                  )}
                </>
              ) : (
                <Skeleton.Input active size="small" />
              )}
            </Descriptions.Item>
          </Descriptions>
          <Descriptions
            title="Personne à prévenir en cas d'urgence"
            column={{
              xxl: 2,
              xl: 2,
              lg: 2,
              md: 1,
              sm: 1,
              xs: 1,
            }}
          >
            <Descriptions.Item label="Nom et prénom">
              {!loading ? (
                <>
                  <Text
                    mark={
                      currentProfile._urgence_name !==
                      incomingProfile._urgence_name
                    }
                  >
                    {incomingProfile._urgence_name}
                  </Text>
                  {currentProfile._urgence_name !==
                    incomingProfile._urgence_name && (
                      <Popconfirm
                        placement="topLeft"
                        title="Accepter entrant"
                        okText={"Oui"}
                        onConfirm={() =>
                          handleChange(
                            "_urgence_name",
                            incomingProfile._urgence_name
                          )
                        }
                      >
                        <Link>
                          <CheckOutlined className="mx-1" />
                        </Link>
                      </Popconfirm>
                    )}{" "}
                </>
              ) : (
                <Skeleton.Input active size="small" />
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Téléphone">
              {!loading ? (
                <>
                  <Text
                    mark={
                      currentProfile._urgence_phone !==
                      incomingProfile._urgence_phone
                    }
                  >
                    {incomingProfile._urgence_phone}
                  </Text>
                  {currentProfile._urgence_phone !==
                    incomingProfile._urgence_phone && (
                      <Popconfirm
                        placement="topLeft"
                        title="Accepter entrant"
                        okText={"Oui"}
                        onConfirm={() =>
                          handleChange(
                            "_urgence_phone",
                            incomingProfile._urgence_phone
                          )
                        }
                      >
                        <Link>
                          <CheckOutlined className="mx-1" />
                        </Link>
                      </Popconfirm>
                    )}
                </>
              ) : (
                <Skeleton.Input active size="small" />
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Relation">
              {!loading ? (
                <>
                  <Text
                    mark={
                      currentProfile._urgence_relation !==
                      incomingProfile._urgence_relation
                    }
                  >
                    {incomingProfile._urgence_relation}
                  </Text>
                  {currentProfile._urgence_relation !==
                    incomingProfile._urgence_relation && (
                      <Popconfirm
                        placement="topLeft"
                        title="Accepter entrant"
                        okText={"Oui"}
                        onConfirm={() =>
                          handleChange(
                            "_urgence_relation",
                            incomingProfile._urgence_relation
                          )
                        }
                      >
                        <Link>
                          <CheckOutlined className="mx-1" />
                        </Link>
                      </Popconfirm>
                    )}
                </>
              ) : (
                <Skeleton.Input active size="small" />
              )}
            </Descriptions.Item>
          </Descriptions>
        </div>
        {/* FINAL BLOCK */}
        <div
          className="col-lg-12 col-md-12 col-sm-12 mt-2 p-4 rounded-2"
          style={{ backgroundColor: "#f8f2fc" }}
        >
          <Descriptions
            column={{
              xxl: 3,
              xl: 3,
              lg: 2,
              md: 1,
              sm: 1,
              xs: 1,
            }}
            title={<h6>Résultat final</h6>}
          >
            <Descriptions.Item label="Nom et prénom">
              {!loading ? (
                <>
                  <Text
                    mark={
                      finalProfile.fullName === incomingProfile.fullName &&
                      finalProfile.fullName !== currentProfile.fullName
                    }
                  >
                    {finalProfile.fullName}
                  </Text>
                </>
              ) : (
                <Skeleton.Input active size="small" />
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Téléphone">
              {!loading ? (
                <>
                  <Text
                    mark={
                      finalProfile.phoneNum === incomingProfile.phoneNum &&
                      finalProfile.phoneNum !== currentProfile.phoneNum
                    }
                  >
                    {finalProfile.phoneNum}
                  </Text>
                </>
              ) : (
                <Skeleton.Input active size="small" />
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Address">
              {!loading ? (
                <>
                  <Text
                    mark={
                      finalProfile.address === incomingProfile.address &&
                      finalProfile.address !== currentProfile.address
                    }
                  >
                    {finalProfile.address}
                  </Text>
                </>
              ) : (
                <Skeleton.Input active size="small" />
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Date de naissance">
              {!loading ? (
                <>
                  <Text
                    mark={
                      finalProfile.birthDate === incomingProfile.birthDate &&
                      finalProfile.birthDate !== currentProfile.birthDate
                    }
                  >
                    {dayjs(finalProfile.birthDate).format("DD/MM/YYYY")}
                  </Text>{" "}
                </>
              ) : (
                <Skeleton.Input active size="small" />
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Address de naissance">
              {!loading ? (
                <>
                  <Text
                    mark={
                      finalProfile.birthPlace === incomingProfile.birthPlace &&
                      finalProfile.birthPlace !== currentProfile.birthPlace
                    }
                  >
                    {finalProfile.birthPlace}
                  </Text>{" "}
                </>
              ) : (
                <Skeleton.Input active size="small" />
              )}
            </Descriptions.Item>
          </Descriptions>
          <Descriptions
            column={{
              xxl: 3,
              xl: 3,
              lg: 2,
              md: 1,
              sm: 1,
              xs: 1,
            }}
          >
            <Descriptions.Item label="CIN">
              {!loading ? (
                <>
                  <Text
                    mark={
                      finalProfile.CIN === incomingProfile.CIN &&
                      finalProfile.CIN !== currentProfile.CIN
                    }
                  >
                    {finalProfile.CIN}
                  </Text>
                </>
              ) : (
                <Skeleton.Input active size="small" />
              )}
            </Descriptions.Item>
            <Descriptions.Item label="RIB">
              {!loading ? (
                <>
                  <Text
                    mark={
                      finalProfile.Rib === incomingProfile.Rib &&
                      finalProfile.Rib !== currentProfile.Rib
                    }
                  >
                    {finalProfile.Rib}
                  </Text>{" "}
                </>
              ) : (
                <Skeleton.Input active size="small" />
              )}
            </Descriptions.Item>
            <Descriptions.Item label="CV">
              {!loading ? (
                <>
                  <Text
                    mark={
                      finalProfile.CV === incomingProfile.CV &&
                      finalProfile.CV !== currentProfile.CV
                    }
                  >
                    <a href={finalProfile.CV} download={"CURRENT_CV"}>
                      Piéce jointe
                    </a>
                  </Text>
                </>
              ) : (
                <Skeleton.Input active size="small" />
              )}
            </Descriptions.Item>
            <Descriptions.Item label="CIN">
              {!loading ? (
                <>
                  <Text
                    mark={
                      finalProfile._cin_File === incomingProfile._cin_File &&
                      finalProfile._cin_File !== currentProfile._cin_File
                    }
                  >
                    <a href={finalProfile._cin_File} download={"CURRENT_CIN"}>
                      Piéce jointe
                    </a>
                  </Text>
                </>
              ) : (
                <Skeleton.Input active size="small" />
              )}
            </Descriptions.Item>
            <Descriptions.Item label="RIB">
              {!loading ? (
                <>
                  <Text
                    mark={
                      finalProfile._rib_File === incomingProfile._rib_File &&
                      finalProfile._rib_File !== currentProfile._rib_File
                    }
                  >
                    <a href={finalProfile._rib_File} download={"CURRENT_RIB"}>
                      Piéce jointe
                    </a>
                  </Text>
                </>
              ) : (
                <Skeleton.Input active size="small" />
              )}
            </Descriptions.Item>
          </Descriptions>
          <Descriptions
            title="Personne à prévenir en cas d'urgence"
            column={{
              xxl: 2,
              xl: 2,
              lg: 2,
              md: 1,
              sm: 1,
              xs: 1,
            }}
          >
            <Descriptions.Item label="Nom et prénom">
              {!loading ? (
                <>
                  <Text
                    mark={
                      finalProfile._urgence_name ===
                      incomingProfile._urgence_name &&
                      finalProfile._urgence_name !==
                      currentProfile._urgence_name
                    }
                  >
                    {finalProfile._urgence_name}
                  </Text>
                </>
              ) : (
                <Skeleton.Input active size="small" />
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Téléphone">
              {!loading ? (
                <>
                  <Text
                    mark={
                      finalProfile._urgence_phone ===
                      incomingProfile._urgence_phone &&
                      finalProfile._urgence_phone !==
                      currentProfile._urgence_phone
                    }
                  >
                    {finalProfile._urgence_phone}
                  </Text>
                </>
              ) : (
                <Skeleton.Input active size="small" />
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Relation">
              {!loading ? (
                <>
                  <Text
                    mark={
                      finalProfile._urgence_relation ===
                      incomingProfile._urgence_relation &&
                      finalProfile._urgence_relation !==
                      currentProfile._urgence_relation
                    }
                  >
                    {finalProfile._urgence_relation}
                  </Text>
                </>
              ) : (
                <Skeleton.Input active size="small" />
              )}
            </Descriptions.Item>
          </Descriptions>
          <div>
            <Button type="primary" onClick={handleSave}>
              Sauvgarder
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditRequest;
