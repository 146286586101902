import React, { useEffect, useState } from "react";
import { Table, message, Tag, Button, Space, Typography, Collapse } from "antd";
import axiosInstance from "../../../Utils/axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import * as XLSX from "xlsx";
import dayjs from "dayjs";
import AccessDrawer from "./AccessDrawer";
import { render } from "@testing-library/react";
import DeleteAccessModal from "./DeleteAccessModal";

const UsersAccessList = () => {
  const [refrech, setRefresh] = useState(false);
  const [access, setAccess] = useState([]);
  const [deletedAccess, setDeletedAccess] = useState([]);

  const handleRefresh = () => {
    setRefresh(!refrech);
  }

  const columns = [
    {
      title: "Utilisateur",
      dataIndex: "userFullName",
    },
    {
      title: "Service",
      render: (_, record) => record?.service?.name,
    },
    {
      title: "Login",
      dataIndex: "userName",
    },
    {
      title: "Serveur(s) assosié(s)",
      render: (_, record) => (
        <div className="row px-3">
          <Tag color="blue" className="col-auto" key={record.server._id}>
            {record.server.hostName}
          </Tag>
        </div>
      ),
    },
    {
      title: "Actions",
      render: (_, record) => (
        <Space>
          <Button size="small" type="primary" shape="circle">
            <FontAwesomeIcon icon={faEdit} />
          </Button>
          <DeleteAccessModal record={record} handleRefresh={handleRefresh} />
          <AccessDrawer record={record} />
        </Space>
      ),
    },
  ];


  const deletedColumns = [
    {
      title: "Supprimé le ",
      dataIndex: "updatedAt",
      render: (text) => dayjs(text).format("DD/MM/YYYY HH:mm"),
    },
    {
      title: "Username",
      dataIndex: "userName",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Motif de supprission",
      dataIndex: "deleteTrace",
    }
  ]

  const handleExport = () => {
    const workbook = XLSX.utils.book_new();
    const renamedAccess = access.map((item) => {
      return {
        Matricule: item.userMatricule,
        Utilisateur: item.userFullName,
        Service: item?.service?.name,
        Login: item?.userName,
        Serveur: item?.server?.hostName ?? "N/A",
      };
    });
    const worksheet = XLSX.utils.json_to_sheet(renamedAccess);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Access Data");
    XLSX.writeFile(
      workbook,
      `access_data_${dayjs().format("DD-MM-YYYY-HH-mm")}.xlsx`
    );
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await axiosInstance.get("/api/administration/access/");

        setAccess(data?.data);
        return data;
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    }

    async function fetchDeleteData() {
      try {
        const {data} = await axiosInstance.get("/api/administration/deleted/access");

        setDeletedAccess(data?.data);
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    }
    fetchDeleteData()
    fetchData();
  }, [refrech]);

  

  return (
    <div
      className="  p-4 "
      style={{ minHeight: "80vh" }}
    >
      <div className="mb-3 d-flex justify-content-between">
        <Typography.Title level={4}>🔑 Accès utilisateurs</Typography.Title>
        <Space>
          <Button type="primary" shape="round" onClick={handleExport}>
            Exporter
          </Button>
          <Button shape="round">Ajouter</Button>
        </Space>
      </div>
      <Table columns={columns} dataSource={access} />
      <Collapse
      className="mt-4"
      items={[
        {
          key: '1',
          label: <Typography.Title level={4}>Corbeille</Typography.Title>,
          children:  <Table columns={deletedColumns} dataSource={deletedAccess} />,
        },
      ]} bordered={false}  />
     
    </div>
  );
};

export default UsersAccessList;
