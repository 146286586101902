import React, { useEffect, useState, useRef } from "react";
import { SearchOutlined } from "@ant-design/icons";

import axiosInstance from "../../Utils/axios";
import {
  Table,
  Tag,
  Typography,
  message,
  Button,
  Input,
  Space,
  DatePicker,
  Tooltip,
  Descriptions,
} from "antd";
import dayjs from "dayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowTrendDown,
  faArrowTrendUp,
} from "@fortawesome/free-solid-svg-icons";
import Highlighter from "react-highlight-words";

const monthFormat = "YYYY-MM";

const BalanceArchive = () => {
  const [archive, setArchive] = useState([]);
  const [monthlyBalance, srtMonthlyBalance] = useState({});
  const [selectedMonth, setSelectedMonth] = useState(
    dayjs().format(monthFormat)
  );

  const onChange = (date, dateString) => {
    setSelectedMonth(dayjs(date).format(monthFormat));
  };

  const disabledDate = (current) => {
    // Can not select days after today and today
    return current && current > dayjs().endOf("day");
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await axiosInstance.get(
          "/api/employees/balance/archive/me/" + selectedMonth
        );

        setArchive(data?.archive);
        srtMonthlyBalance({
          startOfMonthBalance: data?.startOfMonthBalance,
          endOfMonthBalance: data?.endOfMonthBalance,
        });
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    }
    fetchData();
  }, [selectedMonth]);

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex, title) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Rechercher ${title}...`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
          >
            Rechercher
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
          >
            Réinitialiser
          </Button>

          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            Fermer
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Date",
      dataIndex: "at",
      sorter: (a, b) => dayjs(a.at) - dayjs(b.at),
      render: (_, record) => (
        <Typography.Text>
          {dayjs(record?.at).format("DD/MM/YYYY HH:mm")}
        </Typography.Text>
      ),
    },
    {
      title: "Evènement",
      dataIndex: "action",
      ...getColumnSearchProps("action", "evènement"),
    },
    {
      title: "Modification",
      dataIndex: "calcValue",
      sorter: (a, b) => a.calcValue - b.calcValue,
      render: (_, record) => (
        <Tag
          color={record?.calcValue >= 0 ? "#2a96ff" : "red"}
          style={{ fontWeight: 600 }}
        >
          {record?.calcValue === 0
            ? "N/A"
            : record?.calcValue > 0
              ? "+" + record?.calcValue
              : record?.calcValue}
        </Tag>
      ),
    },
    {
      title: (
        <Tooltip title="Solde avant" placement="bottom" color="blue">
          <div>S.Avant</div>
        </Tooltip>
      ),
      dataIndex: "currentValue",
      sorter: (a, b) => a.currentValue - b.currentValue,
      render: (_, record) => (
        <Tag style={{ fontWeight: 600 }}>
          {record?.currentValue === 0 ? "N/A" : record?.currentValue}
        </Tag>
      ),
    },
    {
      title: (
        <Tooltip title="Solde aprés" placement="bottom" color="blue">
          <div>S.Aprés</div>
        </Tooltip>
      ),
      sorter: (a, b) =>
        a?.currentValue + a?.calcValue - (b?.currentValue + b?.calcValue),
      render: (_, record) => (
        <Tag
          color={record?.calcValue >= 0 ? "#2a96ff" : "red"}
          style={{ fontWeight: 600 }}
        >
          <FontAwesomeIcon
            icon={record?.calcValue >= 0 ? faArrowTrendUp : faArrowTrendDown}
            style={{
              marginRight: "5px",
            }}
          />
          {record?.currentValue === 0 && record?.calcValue === 0
            ? "N/A"
            : record?.currentValue + record?.calcValue}
        </Tag>
      ),
    },
  ];

  return (
    <div
      className="p-4"
      style={{ minHeight: "70vh" }}
    >
      <div className="d-flex justify-content-between flex-lg-row flex-md-row flex-column pb-2 align-items-center">
        <Typography.Title level={4}>📋 Historique du solde des congés</Typography.Title>
      </div>
      <div>
        <Table
          title={() => (
            <div className="col-lg-6 col-md-6 col-sm-12">
              <Descriptions title="" bordered size="small">
                <Descriptions.Item label="Choisir un mois :" span={3}>
                  <DatePicker
                    defaultValue={dayjs(selectedMonth, monthFormat)}
                    onChange={onChange}
                    style={{ width: "100%" }}
                    size="small"
                    picker="month"
                    disabledDate={disabledDate}
                    format={monthFormat}
                  />
                </Descriptions.Item>
                <Descriptions.Item label="Solde de début du mois :" span={1}>
                  {monthlyBalance?.startOfMonthBalance
                    ? monthlyBalance?.startOfMonthBalance
                    : "N/A"}
                </Descriptions.Item>
                <Descriptions.Item label="Solde de fin du mois :" span={1}>
                  {monthlyBalance?.endOfMonthBalance
                    ? monthlyBalance?.endOfMonthBalance
                    : "N/A"}
                </Descriptions.Item>
              </Descriptions>
            </div>
          )}
          columns={columns}
          dataSource={archive}
          scroll={{ x: "max-content" }}
        />
      </div>
    </div>
  );
};

export default BalanceArchive;
