import React from 'react'
import TodoList from '../../Components/TodoList'
import { Button, Typography } from 'antd'
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

const Todos = () => {
    const Navigate = useNavigate();
    return (
        <div className="pt-2">
            <div className="mb-3">
                <Typography.Title level={4}>Tâche(s) à faire</Typography.Title>
                <Button shape='round' icon={<FontAwesomeIcon icon={faArrowLeft} />} onClick={() => Navigate("/administration")}>Retour</Button>
            </div>
            <div className=" p-4">
                <TodoList />
            </div>
        </div>
    )
}

export default Todos
