import React from "react";
import { Form, Input, Button, Typography,theme as antdTheme, notification, message, } from "antd";
import NeoLogoDark from "../../Assets/brand_large_dark.svg";
import axios from "../../Utils/axios";
import Logo from "../../Assets/logo-neop-dev-bleu.png";
import { useNavigate } from "react-router-dom";
import data from '../../../package.json'

const { Text, Link } = Typography;

const ForgotPassword = ({theme}) => {
  const {
    token: { colorBgContainer, colorBorder, colorBgLayout },
  } = antdTheme.useToken();
  const Navigate = useNavigate();

 

  const handleReset = async (formValues) => {
    // Navigate("/");
    try {
      const { data } = await axios.post("api/auth/forgot-password", formValues);
      if (data.status === "success") 
        notification.success({
      description: data?.message || "Un courriel contenant les instructions pour réinitialiser votre mot de passe a été envoyé.",});
    } catch (e) { 
      message.error(e?.response?.data?.message || "Quelque chose s'est mal passé, veuillez réessayer plus tard.");
    }
  };
  return (
    <div
    className="vh-100 vw-100"
    style={{ overflow: "hidden", backgroundColor: colorBgLayout }}
  >
    <div className="d-flex vw-100 justify-content-center align-items-center vh-100 px-3 m-0 ">
      <div
        className="col-lg-4 col-md-6 col-12 p-4 shadow-lg rounded-3"
        style={{
          border: `1px solid ${colorBorder}`,
          backgroundColor: colorBgContainer,
        }}
      >
          <div className="d-flex flex-column justify-content-between h-100">

            <div>
              <Typography.Title level={2} className="mb-4">
                Mot de passe oublié ?
              </Typography.Title>
              <Typography.Paragraph >
                Entrez l'email de votre compte et nous vous enverrons un lien
                pour réinitialiser votre mot de passe. </Typography.Paragraph>
              <Form
                labelCol={{
                  xs: { span: 24 },
                  sm: { span: 24 },
                  md: { span: 24 },
                }}
                wrapperCol={{
                  xs: { span: 24 },
                  sm: { span: 24 },
                  md: { span: 24 },
                  lg: { span: 24 },
                }}
                layout="vertical"
                size="large"
                onFinish={handleReset}
              >
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "S’il vous plaît entrer votre email!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item>
                  <div className="d-flex justify-content-between align-items-center flex-column flex-xl-row">
                    <Button className="my-1" type="primary" htmlType="submit" shape="round">
                      Réinitialiser !
                    </Button>
                    <Link
                      className="my-1 fw-bold"
                      onClick={() => {
                        Navigate("/login");
                      }}
                    >
                      Se connecter ?
                    </Link>
                  </div>
                </Form.Item>
              </Form>
            </div>
            <div>
              <div className="d-flex justify-content-between">
                <Text type="primary" className="mt-2" strong>
                &copy; {new Date().getFullYear()} NeoLink V{data.version}
                </Text>
                <img src={theme ==="dark" ? NeoLogoDark : Logo} style={{ height: "35px" }} alt="Logo" />
              </div>
            </div>
          </div>
        </div>
       
      </div>
    </div>

  );
};

export default ForgotPassword;
