import { useState, useEffect, useRef } from "react";

import { SearchOutlined } from "@ant-design/icons";

import {
  Button,
  Space,
  Table,
  Tag,
  Typography,
  message,
  Modal,
  Input,
  Form,
  Tooltip,
  Popconfirm,
  Timeline,
} from "antd";

import Highlighter from "react-highlight-words";

import axios from "../../Utils/axios";
import dayjs from "dayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import AccessTable from "./AccessTable";
import EquipmentsTable from "./EquipmentsTable";
import TeleworkTable from "./TeleworkTable";
import LeaveRequestsDrawer from "../../Drawers/LeaveRequestsDrawer";

const { Text, Paragraph } = Typography;
const { TextArea } = Input;
const LeavesListEmployees = () => {
  const [form] = Form.useForm();
  const options = [
    "fullName",
    "request",
    "status",
    "motif",
    "startDate",
    "endDate",
    "duration",
    "answered_by",
  ];
  const size = "default";

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [loading, setLoading] = useState(true);
  const [requestedLeaves, setRequestedLeaves] = useState([]);
  const searchInput = useRef(null);


  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        justify-content-center
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Soumis le",
      dataIndex: "_posted_date",
      sorter: (a, b) => dayjs(a._posted_date) - dayjs(b._posted_date),
      render: (_, leave) => (
        <Text strong>
          {leave?._posted_date
            ? dayjs(leave._posted_date).format("DD/MM/YYYY HH:mm")
            : "N/A"}
        </Text>
      ),
    },
    options.find((elem) => elem === "fullName")
      ? {
        title: "Demandeur",
        dataIndex: "fullName",
        key: "1",
        ...getColumnSearchProps("fullName"),
        render: (_, requestedLeaves) => <>{requestedLeaves.fullName}</>,
      }
      : { width: 0, className: "d-none" },
    options.find((elem) => elem === "request")
      ? {
        title: "Demande",
        dataIndex: "_typeLeave",
        width: 200,
        render: (_, requestedLeaves) => (
          <>
            {requestedLeaves._special_leave ? (
              <Paragraph
                style={{ margin: 0 }}
                ellipsis={{
                  rows: 2,
                  expandable: true,
                  symbol: "Plus",
                }}
              >
                {requestedLeaves._typeLeave +
                  `${requestedLeaves._special_leave &&
                  " - " + requestedLeaves._special_leave
                  }`}
              </Paragraph>
            ) : (
              <Text>{requestedLeaves._typeLeave}</Text>
            )}
          </>
        ),
      }
      : { width: 0, className: "d-none" },
    options.find((elem) => elem === "status")
      ? {
        title: "Statut",
        dataIndex: "status",
        key: "7",
        render: (_, record) => (
          <>
            {record.status === "Pending" && (
              <Tag color={"orange"}>En attente</Tag>
            )}
            {record.status === "Accepted" && (
              <Tag color={"green"}>Approuvée</Tag>
            )}
            {record.status === "Validating" && (
              <Tag color={"cyan"}>En attente d'approbation</Tag>
            )}
            {record.status === "Rejected" && <Tag color={"red"}>Rejeté</Tag>}
            {record.status === "Canceled" && <Tag color="red">Annulée</Tag>}
            {record.status === "Unjustified" && (
              <Tag color={"gold"}>Non encore justifié</Tag>
            )}
            {record.status === "Failed" && (
              <Tooltip title="Considérée comme absence non justifiée">
                <Tag>Échue</Tag>
              </Tooltip>
            )}
          </>
        ),
      }
      : { width: 0, className: "d-none" },

    options.find((elem) => elem === "startDate")
      ? {
        title: (
          <Tooltip title="Les filtres et la recherche sont en cours de développement">
            Date de début
          </Tooltip>
        ),
        dataIndex: "BeginingDate",

        sorter: (a, b) => dayjs(a.BeginingDate) - dayjs(b.BeginingDate),
        render: (text) => <Text>{dayjs(text).format("DD/MM/YYYY")}</Text>,
      }
      : { width: 0, className: "d-none" },
    options.find((elem) => elem === "endDate")
      ? {
        title: (
          <Tooltip title="Les filtres et la recherche sont en cours de développement">
            Date de fin
          </Tooltip>
        ),
        dataIndex: "EndingDate",
        key: "6",
        sorter: (a, b) => dayjs(a.EndingDate) - dayjs(b.EndingDate),
        render: (text) => <Text>{dayjs(text).format("DD/MM/YYYY")}</Text>,
      }
      : { width: 0, className: "d-none" },
    options.find((elem) => elem === "duration")
      ? {
        title: "Durée",
        render: (_, requestedLeaves) => (
          <Tooltip
            color="cyan"
            title={
              requestedLeaves._typeLeave === "Télétravail" ? (
                <div>
                  Sauf:
                  <br />
                  {requestedLeaves._except.map((elem) => {
                    return <Tag color="blue">{elem}</Tag>;
                  })}
                </div>
              ) : requestedLeaves._typeLeave === "Autorisation" ? (
                requestedLeaves._duration
              ) : !requestedLeaves._is_half_day ? (
                requestedLeaves.DaysNumber + " Jour(s)"
              ) : (
                requestedLeaves._is_half_day
              )
            }
          >
            <Text>
              {requestedLeaves._typeLeave === "Autorisation"
                ? requestedLeaves._duration
                : !requestedLeaves._is_half_day
                  ? requestedLeaves.DaysNumber + " Jour(s)"
                  : requestedLeaves._is_half_day}
            </Text>
          </Tooltip>
        ),
      }
      : { width: 0, className: "d-none" },

    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space>
          {(record.status === "Pending") && (
            <Space>
              <Popconfirm
                placement="topLeft"
                okText="Oui"
                title="Vous êtes sûr d'approuver la demande?"
                onConfirm={() => {
                  handleActions({
                    _id: record._id,
                    value: "Accepted",
                  });

                  const newRecord = requestedLeaves.map((RL) => {
                    if (RL._id === record._id) {
                      RL.status = "Accepted";
                    }
                    return RL;
                  });

                  setRequestedLeaves(newRecord);
                }}
              >
                <Tooltip title="Approuver la demande">
                  <Button type="primary" shape="circle" size="small">
                    <FontAwesomeIcon icon={faCheck} />
                  </Button>
                </Tooltip>
              </Popconfirm>

              <Popconfirm
                placement="topLeft"
                okText="Oui"
                title="Vous êtes sûr de rejeter la demande?"
                onConfirm={() => {
                  Modal.info({
                    title: "Rejet de la demande",
                    okCancel: true,
                    cancelText: "Annuler",
                    content: (
                      <>
                        <Form form={form} layout="vertical">
                          <Form.Item
                            name="message"
                            rules={{
                              whiteSpace: true,
                              required: true,
                              message: "Merci de donner une réponse appropriée",
                            }}
                            label="Motif de rejet"
                          >
                            <TextArea />
                          </Form.Item>
                        </Form>
                      </>
                    ),
                    onOk: async () => {
                      const formValues = form.getFieldsValue();
                      await axios.patch("/api/leaves/options/" + record._id, {
                        message: formValues.message,
                        status: "Rejected",
                      });
                      const newRecord = requestedLeaves.map((RL) => {
                        if (RL._id === record._id) {
                          RL.status = "Rejected";
                        }
                        return RL;
                      });

                      setRequestedLeaves(newRecord);
                    },
                  });
                }}
              >
                <Tooltip title="Rejeter la demande">
                  <Button type="primary" shape="circle" danger size="small">
                    <FontAwesomeIcon icon={faXmark} />
                  </Button>
                </Tooltip>
              </Popconfirm>
            </Space>
          )}

          <LeaveRequestsDrawer record={record} />
        </Space>
      ),
    },
  ];

  const handleActions = async (options) => {
    try {
      const { data } = await axios.patch("/api/leaves/options/" + options._id, {
        status: options.value,
        self: false,
      });
      if (data.status === "success") {
        message.success(data.message);
        const newRequestedLeaves = requestedLeaves.map((RL) => {
          if (RL._id === options._id) {
            RL.status = options.value;
          }
          return RL;
        });
        setRequestedLeaves(newRequestedLeaves);
      }
    } catch (error) { }
  };

  useEffect(() => {
    async function fetchRequestedLeaves() {
      const { data } = await axios.get("/api/leaves/requested/leaves");
      const tempData = data?.filter((elem) => {
        return (
          (elem?.status === "Pending" ||
            elem?.status === "Validating" ||
            elem?.status === "Unjustified") &&
          elem?._typeLeave !== "Télétravail"
        );
      });
      setRequestedLeaves(tempData);
      setLoading(false);
      return data;
    }

    fetchRequestedLeaves();
  }, []);


  return (
    <div className="mt-4">
      <div className="px-4">
      <Typography.Title level={4}>⌛ Demandes en attente :</Typography.Title>
      </div>
      <Timeline className="px-4 py-3">
        <Timeline.Item>
          <h5 style={{ fontWeight: "400" }}>✈️ Congés/Autorisations</h5>
          <Table
            loading={loading}
            columns={columns}
            scroll={{ x: "max-content" }}
            dataSource={requestedLeaves}
          />
        </Timeline.Item>
        <Timeline.Item>
          <h5 style={{ fontWeight: "400" }}>🏠 Télétravail</h5>
          <TeleworkTable />
        </Timeline.Item>
        <Timeline.Item>
          <h5 style={{ fontWeight: "400" }}>🔑 Accès</h5>
          <AccessTable
            size={size}
            getColumnSearchProps={getColumnSearchProps}
          />
        </Timeline.Item>
        <Timeline.Item>
          <h5 style={{ fontWeight: "400" }}>💻 Equipements</h5>
          <EquipmentsTable
            size={size}
            getColumnSearchProps={getColumnSearchProps}
          />
        </Timeline.Item>
      </Timeline>
    </div>
  );
};

export default LeavesListEmployees;
