import React from "react";
import { Calendar } from "antd";

const MiniCalendar = ({ headerRender, onSelect }) => {
  return (
    <div>
      <Calendar
        fullscreen={false}
        onSelect={onSelect}
        // headerRender={headerRender}
      />
    </div>
  );
};
export default MiniCalendar;
