import React, { useState } from "react";

import { Button, Form, Input, message, Radio, Spin, Typography } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import axios from "../../Utils/axios";
import { useLocation, useNavigate } from "react-router-dom";
import UploadArea from "./UploadArea";

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
    spin
  />
);

const typeOptions = [
  {
    label: "Nouveau Matériel",
    value: "Nouveau Matériel",
  },
  {
    label: "Réparation",
    value: "Réparation",
  },
];
function RequestInfoComponents() {
  const [typeValue, setTypeValue] = useState("Nouveau Matériel");
  const [category, setCategory] = useState("");
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);

  const [form] = Form.useForm();

  const Navigate = useNavigate();
  const params = useLocation();
  const onChangeTypeValue = ({ target: { value } }) => {
    setTypeValue(value);
  };

  const handleSubmit = async (formValues) => {
    setLoading(true);
    formValues._docs = files;
    try {
      const { data } = await axios.post("/api/request/equipment", formValues);
      message.success(data.message);
      form.resetFields();
      setFiles([]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error(error.response.data.message);
    }
  };

  return (
    <div className="p-4mt-4">
      <div className="mb-3 d-flex justify-content-between align-items-center flex-lg-row flex-md-row flex-column pb-2">
        <Typography.Title level={4}>🆕 Nouvelle demande d'équipement</Typography.Title>

        <Button type="primary" onClick={() => Navigate("/self/equipments")}>
          Mes demandes
        </Button>
      </div>
      <div className="row d-flex flex-column-reverse flex-lg-row flex-md-row ">
        <Form
          form={form}
          disabled={loading}
          labelCol={{
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 },
          }}
          wrapperCol={{
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 },
            lg: { span: 24 },
          }}
          className="col-lg-12 p-4 "
          layout="vertical"
          size="default"
          onFinish={handleSubmit}
        >
          <Form.Item name={"type"} label="Type de la demande">
            <Radio.Group
              defaultValue={"Nouveau Matériel"}
              options={typeOptions}
              onChange={onChangeTypeValue}
              value={typeValue}
            />
          </Form.Item>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <Form.Item
                className="w-100"
                label={
                  <Typography.Text>
                    {typeValue !== "Réparation"
                      ? "Type de matériel demandé"
                      : "Mes matériels"}
                  </Typography.Text>
                }
                name="_categorie"
                rules={[
                  {
                    required: true,
                    message: "Veuillez sélectionner le type de matériel",
                  },
                ]}
              >
                {typeValue !== "Réparation" ? (
                  <Radio.Group
                    onChange={(e) => {
                      setCategory(e.target.value);
                    }}
                    value={category}
                    className="row"
                  >
                    <Radio className="col-lg-3" value="PC">
                      PC
                    </Radio>
                    <Radio className="col-lg-3" value="Souris">
                      Souris
                    </Radio>
                    <Radio className="col-lg-3" value="Écran">
                      Écran
                    </Radio>
                    <Radio className="col-lg-3" value="Clavier">
                      Clavier
                    </Radio>
                    <Radio className="col-lg-3" value="Chargeur pc">
                      Chargeur pc
                    </Radio>
                    <Radio className="col-lg-3" value="Cable">
                      Cable
                    </Radio>
                    <Radio className="col-lg-3" value="Un téléphone portable">
                      Téléphone portable
                    </Radio>
                    <Radio className="col-lg-3" value="Ligne téléphonique">
                      Ligne téléphonique
                    </Radio>
                    <Radio className="col-lg-3" value="Un casque">
                      Casque
                    </Radio>
                    <Radio className="col-lg-3" value="Autres (à Préciser)">
                      Autres (à Préciser)
                    </Radio>
                  </Radio.Group>
                ) : (
                  <Radio.Group
                    className="row"
                    onChange={(e) => {
                      setCategory(e.target.value);
                    }}
                    value={category}
                  >
                    {params?.state?.equipments?.map((e, index) => (
                      <Radio
                        className="col-lg-3"
                        value={e._categorie}
                        key={index}
                      >
                        {e._categorie + " " + e?._brand + " (" + e._ref + ")"}
                      </Radio>
                    ))}
                  </Radio.Group>
                )}
              </Form.Item>
            </div>

            <div className="col-lg-12 col-md-12 col-sm-12">
              <Form.Item
                label={<Typography.Text>Plus de précision</Typography.Text>}
                name="_description"
                rules={[
                  {
                    required: category === "Autres (à Préciser)",
                    message: "Veuilez Ajouter plus de précision.",
                  },
                ]}
              >
                <Input.TextArea placeholder="Plus de précision." rows={5} />
              </Form.Item>
              <Form.Item
                label={<Typography.Text>Motif</Typography.Text>}
                name="_motif"
                rules={[
                  { required: true, message: "Veuillez entrer un motif" },
                ]}
              >
                <Input.TextArea
                  rows={4}
                  placeholder="N'hésitez pas à détailler la cause de votre demande pour une meilleure compréhension et pour une gestion plus efficace."
                />
              </Form.Item>
              <Form.Item
                label={
                  <Typography.Text>Documents Supplémentaires </Typography.Text>
                }
              >
                <UploadArea setFiles={setFiles} />
              </Form.Item>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12"></div>
          </div>

          <Form.Item>
            <div className="d-flex">
              <Button
                shape="round"
                disabled={loading}
                htmlType="submit"
                type="primary"
              >
                Soumettre !
              </Button>
              {loading && (
                <Spin
                  style={{ marginLeft: "10px" }}
                  size="large"
                  indicator={antIcon}
                />
              )}
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default RequestInfoComponents;
