import {
  Form,
  Input,
  Button,
  Typography,
  message,
  theme as antdTheme,
} from "antd";
import axios from "../../Utils/axios";
import Logo from "../../Assets/logo-neop-dev-bleu.png";
import NeoLogoDark from "../../Assets/brand_large_dark.svg";
import { useNavigate, useParams } from "react-router-dom";

import data from '../../../package.json'
const { Text } = Typography;

const PasswordReset = ({ theme }) => {
  const {
    token: { colorBgContainer, colorBorder, colorBgLayout },
  } = antdTheme.useToken();

  const Navigate = useNavigate();

  const { token } = useParams();

  const handleReset = async (formValues) => {
    try {
      const { data } = await axios.patch(
        "/api/auth/reset-password/" + token,
        formValues
      );
      if (data) {
        message.success("Le mot de passe a été réinitialisé", 3);
        setTimeout(() => {
          Navigate("/login");
        }, 3000);
      }
    } catch (err) {
      message.error(err?.response?.data?.message || "Erreur lors de la réinitialisation du mot de passe", 3);
    }
  };
  return (
    <div
      className="vh-100 vw-100"
      style={{ overflow: "hidden", backgroundColor: colorBgLayout }}
    >
      <div className="d-flex vw-100 justify-content-center align-items-center vh-100 px-3 m-0 ">
        <div
          className="col-lg-4 col-md-6 col-12 p-4 shadow-lg rounded-3"
          style={{
            border: `1px solid ${colorBorder}`,
            backgroundColor: colorBgContainer,
          }}
        >
          <div className="d-flex flex-column justify-content-between h-100">
            <div>
              <Typography.Title level={2} className="pb-3 mb-4">
                Réinitialisez votre mot de passe
              </Typography.Title>
              <Form
                labelCol={{
                  xs: { span: 24 },
                  sm: { span: 24 },
                  md: { span: 24 },
                }}
                wrapperCol={{
                  xs: { span: 24 },
                  sm: { span: 24 },
                  md: { span: 24 },
                  lg: { span: 24 },
                }}
                layout="vertical"
                size="large"
                onFinish={handleReset}
              >
                <Form.Item
                  label="Nouvelle mot de passe"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "S’il vous plaît entrer votre mot de passe!",
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item
                  label="Confirmer mot de passe"
                  name="passwordConfirm"
                  rules={[
                    {
                      required: true,
                      message: "S’il vous plaît entrer votre mot de passe!",
                    },
                  ]}
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item>
                  <div className="d-flex justify-content-between">
                    <Button type="primary" htmlType="submit" shape="round">
                      Réinitialiser !
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </div>
            <div>
              <div className="d-flex justify-content-between">
                <Text type="primary" className="mt-2" strong>
                &copy; {new Date().getFullYear()} NeoLink V{data.version}
                </Text>
                <img src={theme==="dark" ? NeoLogoDark : Logo} style={{ height: "35px" }} alt="Logo" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordReset;
