import React from "react";
import { Modal, Form, Input, Button, message } from "antd";
import { useNavigate } from "react-router-dom";
import axios from "../../Utils/axios";

const ChangePasswordModal = ({ TriggerModal, Trigger }) => {
  const Navigate = useNavigate();


  const handleChangePassword = async (formValues) => {
    if (formValues.password !== formValues.passwordConfirm) {
      return message.error("Confirmer votre mot de passe !");
    }
    try {
      const { data } = await axios.patch(
        "/api/auth/change-password",
        formValues
      );
      if (data.status === "success") {
        message.success("Le mot de passe a été changé avec succès", 10);
        localStorage.removeItem("access_token");
        Navigate("/login");
      }
    } catch (error) {
      message.error(error.response.data.message, 10);
    }
  };

  return (
    <>
      <Modal
        visible={Trigger}
        title="Changer mon mot de passe"
        onCancel={() => TriggerModal(false)}
        footer={null}
      >
        <Form
          labelCol={{
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 },
          }}
          wrapperCol={{
            xs: { span: 24 },
            sm: { span: 24 },
            md: { span: 24 },
            lg: { span: 24 },
          }}
          layout="vertical"
          size="middle"
          onFinish={handleChangePassword}
        >
          <Form.Item
            label="Nouveau mot de passe"
            name="password"
            rules={[
              {
                required: true,
                message: "S’il vous plaît entrer votre mot de passe!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="Confirmer nouveau mot de passe"
            name="passwordConfirm"
            rules={[
              {
                required: true,
                message: "S’il vous plaît entrer votre mot de passe!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item>
            <div className="d-flex justify-content-between">
              <Button htmlType="submit" type="primary" shape="round">
                Réinitialiser
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ChangePasswordModal;
