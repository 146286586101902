import {
  Button,
  Card,
  Col,
  Collapse,
  DatePicker,
  Form,
  Input,
  Row,
  Space,
  Table,
} from "antd";
import React, { useState } from "react";
import equipeObje from "../../../Assets/objectif-de-lequipe.png";
import indivobj from "../../../Assets/objectif.png";
import classes from "./EmployeesObjectives.module.css";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import EditorText from "../../Components/EditorText/EditorText";

const EmployeesObjectives = () => {
  const [typeObject, setTypeObjectif] = useState("");
  const [form] = Form.useForm();
  const listEquipes = ["ASSolution", "Géoprod", "Trinita"];
  const listIndiv = [
    "Mabrouk Arij",
    "Amine Ouni",
    "Nadia Ben Mansour",
    "Aziz Souabni",
    "Rahma Boussafa",
  ];
  const [personChoosen, setPersonChoosen] = useState("");
  const changeTypeObjectif = (type) => {
    setTypeObjectif(type);
  };
  const { Panel } = Collapse;
  const columns = [
    {
      title: "Matricule",
      dataIndex: "matricule",
      // ...getColumnSearchProps("matricule", "Matricule"),
    },
    {
      title: "Nom et prénom",
      dataIndex: "fullName",
      // ...getColumnSearchProps("fullName", "Nom et prénom"),
    },
    {
      title: "Date d'embauche",
      dataIndex: "embaucheDate",
    },
    {
      title: "Solde",
      dataIndex: "sold",
      sorter: (a, b) => a.sold - b.sold,
    },

  ];
  return (
    <Form form={form} initialValues={{ users: [] }}>
      <Row>
        <h3>Liste des objectifs</h3>
      </Row>

      {personChoosen === "" && (
        <Row className={classes.objectifsRow}>
          <Card
            hoverable
            className={classes.objectifsCard}
            lg={12}
            md={24}
            onClick={() => changeTypeObjectif("equipe")}
          >
            <img src={equipeObje} className={classes.objectifsImages} />
            <h5 style={{ marginTop: "1rem" }}>Équipes</h5>
          </Card>
          <Card
            hoverable
            className={classes.objectifsCard}
            lg={12}
            md={24}
            onClick={() => changeTypeObjectif("indiv")}
          >
            <img src={indivobj} className={classes.objectifsImages} />
            <h5 style={{ marginTop: "1rem" }}>Individuels</h5>
          </Card>
        </Row>
      )}
      {typeObject === "equipe" && personChoosen === "" && (
        <Row gutter={[16, 16]}>
          {listEquipes.map((e, index) => (
            <Col key={index} lg={12}>
              <Card
                hoverable
                style={{ width: "100%" }}
                onClick={() => setPersonChoosen(e)}
              >
                <h5>{e}</h5>
              </Card>
            </Col>
          ))}
        </Row>
      )}
      {typeObject === "indiv" && personChoosen === "" && (
        <Row gutter={[16, 16]}>
          {listIndiv.map((e, index) => (
            <Col key={index} lg={12}>
              <Card
                hoverable
                style={{ width: "100%" }}
                onClick={() => setPersonChoosen(e)}
              >
                <h5>{e}</h5>
              </Card>
            </Col>
          ))}
        </Row>
      )}

      {personChoosen !== "" && (
        <>


          <Form.List name="users">
            {(fields, { add, remove }) => (
              <>
                <Row
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "2rem",
                  }}
                >
                  <Button
                    style={{ width: "15rem", marginInline: "1rem" }}
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                  >
                    Ajouter un objectif
                  </Button>
                  <Button
                    style={{ width: "15rem" }}
                    icon={<PlusOutlined />}
                  >
                    Confirmer
                  </Button>
                </Row>
                <Table
                  columns={columns}
                  scroll={{ x: "max-content" }}
                />
                {fields.map(({ key, name, ...restField }) => (
                  <Col lg={12} md={24} key={key}>
                    <Collapse
                      bordered={false}
                      type="ghost"
                      expandIconPosition="end"
                      className={classes.container}
                    >
                      <Panel
                        key={name}
                        className={classes.panelStyle}
                        header={
                          <div className={classes.headerStyle}>
                            <span>
                              Équipe
                              <span style={{ marginLeft: "0.5rem" }}>
                                {name}
                              </span>
                            </span>

                            <div
                              onClick={() => {
                                remove(name);
                              }}
                            >
                              {" "}
                              <FontAwesomeIcon
                                icon={faTrash}
                                style={{ color: "#FF3636" }}
                              />
                            </div>
                          </div>
                        }
                      >
                        <Form.Item
                          {...restField}
                          name={[name, "title"]}
                          label="Titre"
                          rules={[
                            { required: true, message: "Title is required" },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, "dateecheance"]}
                          label="Date d'échéance"
                          rules={[
                            {
                              required: true,
                              message: "Echeance Date is required",
                            },
                          ]}
                        >
                          <DatePicker style={{ width: "100%" }} />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, "descirption"]}
                          label="Description"
                          rules={[
                            {
                              required: true,
                              message: "Description is required",
                            },
                          ]}
                        >
                          <EditorText />
                        </Form.Item>
                      </Panel>
                    </Collapse>
                  </Col>
                ))}
              </>
            )}
          </Form.List>
        </>
      )}
    </Form>
  );
};

export default EmployeesObjectives;
