import React, { useState, useEffect } from "react";
import { Collapse, Skeleton, Typography, } from "antd";

import axios from "../../Utils/axios";
import dayjs from "dayjs";
import FaqCard from "../../Admin/Components/FaqCard";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const { Text } = Typography;

const { Panel } = Collapse;

const FAQ = () => {
  // eslint-disable-next-line
  const [holidays, setHolidays] = useState([]);
  const [faqs, setFAQS] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const { data } = await axios.get("/api/faq/");
      setFAQS(data.faqs);
      return data.faqs;
    }

    const fetchHolidays = async () => {
      const { data } = await axios.get("/api/holidays/");

      setHolidays(data.holidays);

      return data;
    };

    fetchHolidays();
    fetchData();
  }, []);
  return (
    <div>
      <div className="p-4">
        <div className="mb-4">
          <Typography.Title level={4}>❓ Foire Aux Questions </Typography.Title>
        </div>

        <div className="d-flex justify-content-center flex-column align-items-center gap-3">
          {faqs.map((faq, idx) => {
            return <FaqCard faq={faq} idx={idx} access={false} />;
          })}

          {holidays.length ? (
            <Collapse
              expandIconPosition={"end"}
              style={{ width: "100%", }}
              expandIcon={({ isActive }) => (
                <FontAwesomeIcon
                  icon={faCirclePlus}
                  style={{ fontSize: "24px" }}
                  rotate={isActive ? 90 : 0}
                />
              )}
            >
              <Panel
                header={
                  <span style={{ fontWeight: "500", fontSize: "14px" }}>
                    Les jours fériés ?
                  </span>
                }
                key={"1"}
              >
                {holidays.map((holiday, index) => {
                  return (
                    <div key={index} className="py-1 px-2">
                      {" "}
                      <Text>{holiday.label}</Text>
                      {" : "}
                      <span style={{ fontWeight: "500" }}>
                        {dayjs(holiday.Date).format("DD MMMM YYYY")}
                      </span>{" "}
                      - {holiday.Days}
                      {" "}Jour(s)
                    </div>
                  );
                })}
              </Panel>
            </Collapse>
          ) : (
            <Skeleton.Input active={true} size={"default"} block="true" />
          )}
        </div>
      </div>
    </div>
  );
};

export default FAQ;
