import { Card, Skeleton, Tooltip } from "antd";

const SubInformation = ({ stats, handlePlusSubs }) => {
  return (
    <div className="w-100">
      <h1 style={{ fontWeight: "500", fontSize: "20px" }} className="pb-2">
        🤝 Mes subordonnées
      </h1>
      <div className="row">
        <div className="col-lg-4 col-md-4 col-sm-12 my-2">
          <Tooltip title="Les absences de mes subordonnées qui n'ont pas encore été justifiées ou expliquées, telles que les absences pour raison de maladie ou celles déclarées par RH.">
            <Card
              hoverable
              style={{ padding: "0 !important" }}
              onClick={handlePlusSubs}
            >
              <h1 style={{ fontWeight: "500", fontSize: "14px" }}>
                Leur(s) absence(s) non encore justifiée(s)
              </h1>
              <div
                className="my-3 mx-2 px-3 d-flex flex-column"
                style={{ borderLeft: "2px solid #EE7985" }}
              >
                <span style={{ fontWeight: "600", fontSize: "28px" }}>
                  {stats?.requestedUnjustifiedLeaves ||
                    stats?.requestedUnjustifiedLeaves === 0 ? (
                    stats?.requestedUnjustifiedLeaves
                  ) : (
                    <Skeleton.Button active={true} size={"small"} />
                  )}
                </span>
              </div>
            </Card>
          </Tooltip>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 my-2">
          <Tooltip title="Les demandes de mes subordonnées en attente de traitement, que ce soient des demandes de congés, des demandes de matériel ou des demandes d'accès...">
            <Card
              hoverable
              style={{ padding: "0 !important" }}
              onClick={handlePlusSubs}
            >
              <h1 style={{ fontWeight: "500", fontSize: "14px" }}>
                Leur(s) demande(s) non encore traitée(s)
              </h1>
              <div
                className="my-3 mx-2 px-3 d-flex flex-column"
                style={{ borderLeft: "2px solid #2A96FF" }}
              >
                <span style={{ fontWeight: "600", fontSize: "28px" }}>
                  {stats?.requestedLeaves || stats?.requestedLeaves === 0 ? (
                    stats?.requestedLeaves
                  ) : (
                    <Skeleton.Button active={true} size={"small"} />
                  )}
                </span>
              </div>
            </Card>
          </Tooltip>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 my-2">
          <Tooltip title="Cliquez ici pour afficher le détail des subordonnées.">
            <Card
              hoverable
              style={{ padding: "0 !important" }}
              onClick={handlePlusSubs}
            >
              <h1 style={{ fontWeight: "500", fontSize: "14px" }}>
                Nombre de mes subordonnées
              </h1>
              <div
                className="my-3 mx-2 px-3 d-flex flex-column"
                style={{ borderLeft: "2px solid #FFA318" }}
              >
                <span style={{ fontWeight: "600", fontSize: "28px" }}>
                  {stats?.requestedLeaves || stats?.requestedLeaves === 0 ? (
                    stats?.requestedLeaves
                  ) : (
                    <Skeleton.Button active={true} size={"small"} />
                  )}
                </span>
              </div>
            </Card>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default SubInformation;
