import { Button, Form, message, TimePicker, Typography } from "antd";
import React from "react";
import axiosInstance from "../../Utils/axios";
import dayjs from "dayjs";

const GeneralSettings = () => {
  const handleFinish = async (values) => {
    try {
        // const {data} = await axiosInstance.post('/settings/hours', values);
        const temp = {
            "work-hours": [dayjs(values["work-hours"][0]).format("HH:mm"), dayjs(values["work-hours"][1]).format("HH:mm")],
            "rest-hours": [dayjs(values["rest-hours"][0]).format("HH:mm"), dayjs(values["rest-hours"][1]).format("HH:mm")],
            "matin": [dayjs(values["work-hours"][0]).format("HH:mm"),dayjs(values["rest-hours"][0]).format("HH:mm")],
            "après-midi": [dayjs(values["rest-hours"][1]).format("HH:mm"),dayjs(values["work-hours"][1]).format("HH:mm")] 
        }
        console.log(temp);
    } catch (error) {
      message.error(
        error?.response?.data?.message ||
          "Erreur lors de la sauvegarde des paramètres généraux"
      );
    }
  };
  return (
    <div>
      <div>
        <Typography.Title level={4}>Horaires ⏰</Typography.Title>
        <Typography.Paragraph>
          ‼️ Note: Si l&apos;horaire de pause ne sont pas définis, les congés
          demi journée ne seront pas disponibles.
        </Typography.Paragraph>
        <Form layout="vertical" disabled onFinish={handleFinish}>
          <Form.Item
            label="Horaire de travail"
            name={"work-hours"}
            rules={[
              {
                required: true,
                message: "Veuillez entrer l'horaire de travail",
              },
            ]}
          >
            <TimePicker.RangePicker />
          </Form.Item>
          <Form.Item
            label="Horaire de pause"
            name={"rest-hours"}
            rules={[
              {
                required: true,
                message: "Veuillez entrer l'horaire de pause",
              },
            ]}
          >
            <TimePicker.RangePicker />
          </Form.Item>
          <Button htmlType="submit">
            Sauvegarder
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default GeneralSettings;
