import { Button, message, Modal } from "antd";
import { useState, useRef, useContext } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import UploadInput from "../UploadInput";
import axiosInstance from "../../Utils/axios";
import GlobalContext from "../../Context/GlobalContext";

const ImageModal = ({ imageSrc, setRefresh }) => {
  const { profile, setProfile } = useContext(GlobalContext);
  const cropperRef = useRef();
  const [newImgSrc, setNewImgSrc] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [croppedImgSrc, setCroppedImgSrc] = useState("");

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = async () => {
    try {
      await axiosInstance.patch("/api/employees/upload/profile/pic", {
        imgProfile: croppedImgSrc,
      });
      setRefresh();
      setProfile({ ...profile, imgProfile: croppedImgSrc });

      message.success("La photo de profil a été modifiée avec succès");
      setIsModalOpen(false);
    } catch (error) { }
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onCrop = () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    setCroppedImgSrc(cropper.getCroppedCanvas().toDataURL());
  };

  return (
    <>
      <Button type="primary" size="small" shape="round" onClick={showModal}>
        Modifier photo de profil
      </Button>
      <Modal
        title="Changer photo de profile"
        open={isModalOpen}
        onOk={handleOk}
        okText={"Modifier"}
        onCancel={handleCancel}
      >
        <div className="my-2">
          Nouvelle photo <UploadInput setFile={setNewImgSrc} />
        </div>
        <Cropper
          style={{ maxWidth: "600px", height: "400px" }}
          ref={cropperRef}
          crop={onCrop}
          src={newImgSrc ? newImgSrc : imageSrc}
          aspectRatio={9 / 9}
        />
      </Modal>
    </>
  );
};

export default ImageModal;
