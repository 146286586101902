import React, { useState } from "react";
import { Button, Modal, Form, Input, Space, Checkbox, message } from "antd";
import axiosInstance from "../../../Utils/axios";

const FormService = ({ services, setServices, servers }) => {
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();

  const onChange = (list) => {
    form.setFieldValue(
      "server",
      list.map((elem) => {
        return { _id: elem, url: "" };
      })
    );
  };

  const handleSubmit = async (values) => {
    try {
      const { data } = await axiosInstance.post(
        "/api/administration/service/create",
        values
      );

      const temp = [...services, data?.data?.service];
      setServices(temp);
      form.resetFields();
      setOpen(false);
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };
  return (
    <>
      <Button type="primary" shape="rounded" onClick={() => setOpen(true)}>
        Nouveau service
      </Button>
      <Modal
        title="✅ Nouveau service"
        centered
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        width={800}
        footer={[
          <Space>
            <Button key="back" onClick={() => setOpen(false)}>
              Annuler
            </Button>
            <Button key="submit" type="primary" onClick={() => form.submit()}>
              Enregistrer
            </Button>
          </Space>,
        ]}
      >
        <Form
          onFinish={handleSubmit}
          form={form}
          layout="vertical"
          className="row rounded-3 p-3"
        >
          <Form.Item
            className="col-lg-6"
            name={"name"}
            label="Nom du service"
            rules={[
              {
                whitespace: true,
                required: true,
                message: "Le nom du service est requis",
              },
            ]}
          >
            <Input placeholder="Nom du service" />
          </Form.Item>
          <div className="border rounded-3 py-2 shadow-sm my-2">
            <Form.Item label="Serveur(s) associé(s)">
              <Checkbox.Group
                onChange={onChange}
                options={servers?.map((elem) => {
                  return {
                    label: elem?.pseudo !== "" && elem?.pseudo ? elem?.pseudo : elem?.hostName,
                    value: elem?._id,
                  };
                })}
              />
            </Form.Item>
            <Form.List name="server">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }) => {
                    return (
                      <>
                        <p>
                          URL de{" "}
                          {servers.find(
                            (elem) =>
                              elem._id ===
                              form.getFieldValue("server")[key]?._id
                          )?.pseudo !== "" &&
                            servers.find(
                              (elem) =>
                                elem._id ===
                                form.getFieldValue("server")[key]?._id
                            )?.pseudo
                            ? servers.find(
                              (elem) =>
                                elem._id ===
                                form.getFieldValue("server")[key]?._id
                            )?.pseudo
                            : servers.find(
                              (elem) =>
                                elem._id ===
                                form.getFieldValue("server")[key]?._id
                            )?.hostName}{" "}
                          :
                        </p>
                        <div key={key} className="row">
                          <Form.Item
                            className="col-3"
                            {...restField}
                            name={[name, "_id"]}
                          >
                            <Input disabled />
                          </Form.Item>
                          <Form.Item
                            className="col-9"
                            {...restField}
                            name={[name, "url"]}
                            rules={[
                              {
                                required: true,
                                message: "Missing URL",
                              },
                            ]}
                          >
                            <Input placeholder="URL" />
                          </Form.Item>
                          {/* <MinusCircleOutlined onClick={() => remove(name)} /> */}
                        </div>
                      </>
                    );
                  })}
                </>
              )}
            </Form.List>
          </div>
          <Form.Item name={"description"} label="Description">
            <Input.TextArea placeholder="Description" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
export default FormService;
