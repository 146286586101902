import React from "react";
// import UploadInput from "../../../Components/UploadInput";
import Dragger from "antd/es/upload/Dragger";
import { InboxOutlined } from "@ant-design/icons";
import * as XLSX from "xlsx";
import dayjs from "dayjs";
import { Button, Card, Typography } from "antd";

const EquipmentFormList = ({
  uploadedData,
  setUploadedData,
  loading,
  setLoading,
}) => {
  const handleUpload = (info) => {
    const { originFileObj } = info.fileList[info.fileList.length - 1];

    const reader = new FileReader();

    reader.onload = (event) => {
      const data = event.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const json = XLSX.utils.sheet_to_json(sheet, { raw: true });
      const temp = json.map((elem) => {
        const matricule = elem["UTILISATEUR (Matricule)"]?.slice(1, 4);
        return {
          _current_holder: elem["UTILISATEUR (Matricule)"] ? matricule : "",
          _ref: elem["N°SERIE"],
          _brand: elem["MARQUE"],
          _categorie: elem["DESIGNATION"],
          _num_bill: elem["N° FACTURE"],
          _provider: elem["Nom du Fournisseur"],
          _status: elem["UTILISATEUR (Matricule)"]
            ? "AFFECTED_TO"
            : "AVAILABLE",
          _time_line: elem["UTILISATEUR (Matricule)"]
            ? [
              {
                _action:
                  "Affecter l'équipement à " +
                  elem["UTILISATEUR (Nom & prénom)"] +
                  " #" +
                  matricule,
                _start_date: dayjs(elem["DATE D'AQUISITION"]).format(
                  "DD/MM/YYYY"
                ),
              },
              {
                _action: "Ajouter l'équipement au stock.",
                _start_date: dayjs(elem["DATE D'AQUISITION"]).format(
                  "DD/MM/YYYY"
                ),
                _end_date: dayjs(elem["DATE D'AQUISITION"]).format(
                  "DD/MM/YYYY"
                ),
              },
            ]
            : [
              {
                _action: "Ajouter l'équipement au stock.",
                _start_date: dayjs().format("DD/MM/YYYY"),
                _end_date: dayjs().format("DD/MM/YYYY"),
              },
            ],
        };
      });

      setUploadedData(temp);
    };
    reader.readAsBinaryString(originFileObj);
  };

  const handleDownload = () => {
    const data = [
      {
        "UTILISATEUR (Nom & prénom)": "",
        "UTILISATEUR (Matricule)": "",
        DESIGNATION: "",
        "DATE D'AQUISITION": "'JJ/MM/AAAA'",
        MARQUE: "",
        "N°SERIE": "",
        "N° FACTURE": "",
        "Nom du Fournisseur": "",
      },
    ];

    const worksheet = XLSX.utils.json_to_sheet(data);

    const workbook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    XLSX.writeFile(
      workbook,
      `Liste d'equipements ${dayjs().format("DD/MM/YYYY")}.xlsx`
    );
  };

  return (
    <div className="container   p-4 ">
      <div className="mb-3">
        <Typography.Title level={4}>Ajouter à partir un document Excel</Typography.Title>
      </div>
      <Card>
      <Typography.Text>
        Téléchargez et remplissez ce document avant de télécharger le fichier.
      </Typography.Text>
      <Button
        // type="primary"
        onClick={handleDownload}
      >
        Télécharger
      </Button>
      <Dragger
        style={{ marginTop: "10px" }}
        type="file"
        disabled={loading}
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        onChange={handleUpload}
        beforeUpload={() => false}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Cliquez ou faites glisser le fichier dans cette zone
        </p>
      </Dragger>
      </Card>
    </div>
  );
};

export default EquipmentFormList;
