import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { Table, Tooltip, ConfigProvider, Space, Button, message } from "antd";
import axiosInstance from "../../../Utils/axios";
import { faFileExport } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FileSaver from "file-saver";

const colorValues = {
  "Congé annuel": {
    color: "#FFE58F",
    label: "Cg.A",
    value: "Congé annuel",
  },
  "Congé Special": {
    color: "#FFBB96",
    label: "Cg.S",
    value: "Congé spécial",
  },
  "Congé Maladie": {
    color: "#FFADD1",
    label: "Cg.M",
    value: "Congé maladie",
  },
  "Congé sans solde": {
    color: "#FFBB96",
    label: "Cg.SS",
    value: "Congé sans solde",
  },
  // Autorisation: {
  //   color: "#87E8DE",
  //   label: "Auth",
  //   value: "Autorisation",
  // },
  "Absence non justifier": {
    color: "#FFA39E",
    label: "Abs",
    value: "Absence non justifier",
  },
  "Absence de reporting": {
    color: "#91CAFF",
    label: "Abs.R",
    value: "Absence de reporting",
  },
  "Demi-journée": {
    color: "#D3ADF7",
    label: "Cg 1/2",
    value: "Demi-journée",
  },
};

const exportColorValues = {
  "Cg 1/2": "#D3ADF7",
  "Cg.A": "#FFE58F",
  "Cg.M": "#FFADD1",
  "Cg.SS": "#FFBB96",
  Abs: "#FFA39E",
  "Abs.R": "#91CAFF",
  Dimanche: "#808080",
  Samedi: "#808080",
};

const ActiveCollabView = ({ selectedMonth }) => {
  const month = dayjs(selectedMonth).month(); // Get the month from the selectedMonth prop
  const daysInMonth = dayjs(selectedMonth).daysInMonth(); // Get the number of days in the selected month
  const [collection, setCollection] = useState([]); // Create a state to hold the data from the API
  const [loading, setLoading] = useState(true);
  const daysArray = Array.from({ length: daysInMonth }, (_, index) =>
    dayjs(selectedMonth)
      .month(month)
      .date(index + 1)
  ); // Create an array of dayjs objects representing each day in the selected month

  const columns = [
    {
      title: "Collaborateur",
      dataIndex: "user_name",
      key: "collab",
      fixed: "left",
      className: "text-center",
    },
    ...daysArray.map((day) => ({
      title: () => (
        <Tooltip
          title={
            day.day() === 0 || day.day() === 6
              ? "Fin de semaine"
              : "Journée de travail"
          }
        >
          {day.format("DD")}
        </Tooltip>
      ),
      dataIndex: day.format("MM/DD"),
      className:
        day.day() === 0 || day.day() === 6
          ? "text-center"
          : "text-center cursor-pointer",
      render: (_, record) => (
        <Tooltip
          title={
            record["auth-" + day.format("MM/DD")]
              ? `Autorisation: ${
                  record["auth-" + day.format("MM/DD")]
                } Heure(s)`
              : record["half-" + day.format("MM/DD")]
              ? `Demi-journée (${
                  record["half-" + day.format("MM/DD")]?.type
                }): ${record["half-" + day.format("MM/DD")]?.duration}`
              : colorValues[record[day.format("MM/DD")]]
              ? colorValues[record[day.format("MM/DD")]].value
              : record[day.format("MM/DD")]
          }
        >
          <div
            style={{
              backgroundColor: record["auth-" + day.format("MM/DD")]
                ? colorValues["Autorisation"].color
                : record["half-" + day.format("MM/DD")]
                ? colorValues["Demi-journée"].color
                : colorValues[record[day.format("MM/DD")]]
                ? colorValues[record[day.format("MM/DD")]].color
                : typeof record[day.format("MM/DD")] === "number"
                ? "#B8EA8F"
                : "#D9D9D9",
            }}
          >
            {record["auth-" + day.format("MM/DD")]
              ? record[day.format("MM/DD")]
              : record["half-" + day.format("MM/DD")]
              ? record[day.format("MM/DD")]
              : colorValues[record[day.format("MM/DD")]]
              ? 0
              : typeof record[day.format("MM/DD")] === "number"
              ? record[day.format("MM/DD")]
              : typeof record[day.format("MM/DD")] === "string"
              ? "J.F"
              : record[day.format("MM/DD")]}
          </div>
        </Tooltip>
      ),
    })),
  ]; // Create an array of columns for the table

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const { data } = await axiosInstance.get(
          `api/leaves/record/monthly/active-collab/${selectedMonth}`
        );
        setCollection(data.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        message.error(
          error?.response?.data?.message || "Quelque chose s'est mal passé"
        );
      }
    }

    if (selectedMonth) {
      fetchData();
    }
  }, [selectedMonth]);

  const handleExportToXLSX = async () => {
    const ExcelJS = await import("exceljs");
    // Create a new array called "data" by mapping over the "collection" array
    const data = collection.map((record) => {
      // Create a new object with "Matricule" and "Collaborateur" properties
      const obj = {
        Matricule: record.matricule,
        Collaborateur: record.user_name,
      };

      // Iterate over each day in the "daysArray"
      daysArray.forEach((day) => {
        const dayKey = day.format("MM/DD").toString();

        // Apply the different cases
        obj[dayKey] =
          // CASE 1: If the record has an "auth-" property for the current day demi-journé Color: #D3ADF7
          record["half-" + day.format("MM/DD")]
            ? colorValues["Demi-journée"].label
            : // CASE 2: if the record has one of the colorValues of the current day display his label
            colorValues[record[day.format("MM/DD")]]
            ? colorValues[record[day.format("MM/DD")]].label
            : // CASE 3: if the record has a number for the current day display it with green color
            typeof record[day.format("MM/DD")] === "number"
            ? record[day.format("MM/DD")]
            : // CASE 4: if the record has a string for the current day display "J.F" with grey color
            typeof record[day.format("MM/DD")] === "string"
            ? "J.F"
            : // CASE 5: if the record has no value for the current day display "0" with no color
              0;
      });

      // Return the object
      return obj;
    });

    // Create a new workbook and worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet1");

    // Add header row
    const headers = [
      "Matricule",
      "Collaborateur",
      ...daysArray.map((day) => day.format("MM/DD").toString()),
    ];
    worksheet.addRow(headers);

    // Add data rows
    data.forEach((record) => {
      const row = worksheet.addRow(headers.map((header) => record[header]));

      // Apply styles to each cell based on the conditions
      row.eachCell((cell, colNumber) => {
        const header = headers[colNumber - 1];
        const value = record[header];
        let fill = null;

        if (header !== "Matricule" && header !== "Collaborateur") {
          if (exportColorValues[value]) {
            fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: exportColorValues[value].slice(1) },
            };
          } else if (typeof value === "number" && value > 0) {
            fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "C6EFCE" }, // Green for numbers
            };
          } else if (typeof value === "string" && value === "J.F") {
            fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "D9D9D9" }, // Grey for J.F
            };
          } // No color for 0 or default FS
        }

        if (fill) {
          cell.fill = fill;
        }
      });
    });

    // Write to file
    workbook.xlsx
      .writeBuffer()
      .then((buffer) =>
        FileSaver.saveAs(
          new Blob([buffer]),
          `register_mensuel_${selectedMonth}.xlsx`
        )
      )
      .catch((err) => console.log("Error writing excel export", err));
  };

  return (
    <div>
      <ConfigProvider
        theme={{
          components: {
            Table: {
              cellPaddingBlock: 0,
              cellPaddingInline: 0,
            },
          },
        }}
      >
        <Table
          title={() => (
            <div className="d-flex justify-content-between align-items-center">
              <Space className="py-2 px-2">
                Clés:
                {Object.entries(colorValues).map(([key, value]) => (
                  <Tooltip title={value.value} key={key}>
                    <div
                      className="px-2 py-1 rounded"
                      style={{ backgroundColor: value.color }}
                    >
                      {value.label}
                    </div>
                  </Tooltip>
                ))}
              </Space>

              <Button
                type="primary"
                className="mb-2"
                style={{ marginRight: "5px" }}
                onClick={handleExportToXLSX}
                disabled={!(collection.length > 0)}
              >
                <FontAwesomeIcon
                  icon={faFileExport}
                  style={{ marginRight: "5px" }}
                />{" "}
                Exporter
              </Button>
            </div>
          )}
          loading={loading}
          dataSource={collection}
          columns={columns}
          pagination={{ pageSize: 50 }}
          scroll={{
            x: "max-content",
          }}
          bordered
        />
      </ConfigProvider>
    </div>
  );
};

export default ActiveCollabView;
