import { Card, Empty, Timeline, Typography } from "antd";
import React from "react";
import dayjs from "dayjs";
import house from "../../../Assets/house.png";

const TeleworkCollab = ({ todayRemote }) => {
  return (
    <div className="w-100 my-4    px-3 py-2 rounded-3">
      <h1 style={{ fontWeight: "500", fontSize: "14px" }}>
        Collaborateur(s) en télétravail
      </h1>
      <Card
        style={{
          height: "234px",
          borderRadius: "10px",
          overflowY: "scroll",
        }}
      >
        {todayRemote?.length !== 0 ? (
          <Timeline className="mt-3">
            {todayRemote.map((leave, idx) => {
              return (
                leave?._except?.find(
                  (val) => val === dayjs().format("dddd")
                ) === undefined && (
                  <Timeline.Item
                    key={idx}
                    dot={
                      <img
                        src={house}
                        alt="house"
                        style={{ height: "20px", marginTop: "-3px" }}
                      />
                    }
                  >
                    <Typography.Text strong>{leave.fullName}</Typography.Text>
                  </Timeline.Item>
                )
              );
            })}
          </Timeline>
        ) : (
          <>
            <Empty description={"Aucun collaborateur en télétravail"} />
          </>
        )}
      </Card>
    </div>
  );
};

export default TeleworkCollab;
