import { useState, useEffect } from "react";
import { Button, Card, Image, Modal, Skeleton, Typography } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSkype } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";

import { EyeOutlined } from '@ant-design/icons';

import AvatarProfile from "../../Assets/avatar-svgrepo-com.svg";
import axios from "../../Utils/axios";

const { Paragraph } = Typography;

const EmployeeCard = ({ employee }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});

  const capitalizeFirstLetter = (str) => {
    const capitalized = str[0].toUpperCase() + str.slice(1).toLowerCase();
    return capitalized;
  };

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const { data } = await axios.get(
        "/api/employees/byId/" + employee.matricule
      );
      setData(data.employee);
      setLoading(false);
    }
    fetchData();
  }, [employee]);

  return (
    <Card
      key={employee?._id}
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      {loading ? (
        <div>
          <div className="d-flex">
            <Skeleton.Image
              active={true}
              style={{ borderRadius: "50%", width: "64px", height: "64px" }}
            />
            <div className="px-2 pt-2">
              <Skeleton.Input active={true} size="small" block />
              <Skeleton.Input
                size="small"
                active={true}
                block
                className="mt-2"
              />
            </div>
          </div>
          {/* <div className="d-flex flex-column mt-3">
            <div className="d-flex flex-row align-items-center">
              <FontAwesomeIcon
                icon={faSkype}
                style={{
                  fontSize: "20px",
                  marginRight: "5px",
                  color: theme.color,
                }}
              />
              <Skeleton.Input
                size="small"
                active={true}
                block={true}
                className="mx-2"
              />
            </div>
            <div className="d-flex flex-row align-items-center my-2">
              <FontAwesomeIcon
                icon={faPhone}
                style={{
                  fontSize: "20px",
                  marginRight: "5px",
                  color: theme.color,
                }}
              />
              <Skeleton.Input
                size="small"
                active={true}
                block={true}
                className="mx-2"
              />
            </div>
            <div className="d-flex flex-row align-items-center ">
              <FontAwesomeIcon
                icon={faEnvelope}
                style={{
                  fontSize: "20px",
                  marginRight: "5px",
                  color: theme.color,
                }}
              />
              <Skeleton.Input
                size="small"
                active={true}
                block={true}
                className="mx-2"
              />
            </div>
          </div> */}
        </div>
      ) : (
        <div>
          <div className="d-flex">
            <Image
              src={data?.imgProfile ? data?.imgProfile : AvatarProfile}
              style={{ borderRadius: "50%", width: "64px", height: "64px" }}
            />
            <div className="px-2 pt-2">
              <span style={{ fontSize: "16px", fontWeight: "600" }}>
                {capitalizeFirstLetter(data?.fullName)}
              </span>
              <br />
              <span
                style={{ fontSize: "16px", fontWeight: "400" }}
                className="text-muted"
              >
                {capitalizeFirstLetter(data?.Poste)}
              </span>

            </div>
          </div>
          <div className="d-flex justify-content-end">
            <Button size="small" type="primary" shape="round" icon={<EyeOutlined />} onClick={() => {
              Modal.info({
                title: capitalizeFirstLetter(data?.fullName),
                content: (
                  <div className="d-flex flex-column mt-3">
                    <div>
                      <Paragraph
                        className="d-flex flex-row align-items-center"
                        style={{
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                        copyable={{
                          text: data?.skype ? data?.skype : "N/A",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faSkype}
                          style={{
                            fontSize: "20px",
                            marginRight: "5px",
                          }}
                        />{" "}
                        {data?.skype ? data?.skype : "N/A"}
                      </Paragraph>
                    </div>
                    <div>
                      <Paragraph
                        className="d-flex flex-row align-items-center"
                        style={{
                          fontSize: "14px",
                          fontWeight: "400",
                        }}
                        copyable={{
                          text: data?.phoneNum ? data?.phoneNum : "N/A",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faPhone}
                          style={{
                            fontSize: "20px",
                            marginRight: "5px",
                          }}
                        />{" "}
                        {data?.phoneNum ? data?.phoneNum : "N/A"}
                      </Paragraph>
                    </div>
                    <div className="d-flex flex-row align-items-center">
                      <div className="d-flex flex-row align-items-center">
                        <Paragraph
                          className="d-flex flex-row align-items-center"
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                          }}
                          copyable={{
                            text: data?.email ? data?.email : "N/A",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faEnvelope}
                            style={{
                              fontSize: "20px",
                              marginRight: "5px",
                            }}
                          />{" "}
                          {data?.email ? data?.email : "N/A"}
                        </Paragraph>
                      </div>
                    </div>
                  </div>
                ),
                onOk() { },
              });
            }} >
              Contact
            </Button>
          </div>

        </div>
      )}
    </Card>
  );
};

export default EmployeeCard;
