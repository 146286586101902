import React, { useEffect, useState } from "react";
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  ConfigProvider,
  Form,
  Input,
  Select,
  Space,
  Spin,
  message,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk } from "@fortawesome/free-solid-svg-icons";
import axiosInstance from "../../../Utils/axios";

const SkillFormList = () => {
  const [categories, setCategories] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await axiosInstance.get(
          "/api/factory/skills/categories"
        );
        setCategories(data?.categories);
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    }

    fetchData();
  }, []);

  return (
    <ConfigProvider>
      {categories === null ? (
        <div className="d-flex justify-content-center">
          <Spin />
        </div>
      ) : (
        <Card>
        <Form.List name="skills">
          {(fields, { add, remove }) => (
            <div className="row container">
              {fields.map(({ key, name, ...restField }) => (
                <div className="col-12 col-lg-4 col-md-5 px-2 ">
                  <div
                    className="px-2 rounded-3 d-flex align-items-center"
                    style={{
                      fontSize: "14px",
                      padding: "3px 9px",
                    }}
                  >
                    <Space.Compact className="w-100 ">
                      <Form.Item
                        {...restField}
                        name={[name, "label"]}
                        className="w-100"
                      >
                        <Input
                          className="w-100"
                          placeholder="Compétence"
                          size="large"
                        />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, "category"]}
                        className="w-100"
                      >
                        <Select
                          className="w-100"
                          placeholder="Catégorie"
                          size="large"
                        >
                          {categories.map((elem) => {
                            return (
                              <Select.Option value={elem}>{elem}</Select.Option>
                            );
                          })}
                        </Select>
                      </Form.Item>


                    </Space.Compact>
                    <MinusCircleOutlined
                      onClick={() => remove(name)}
                      style={{ color: "red", marginTop: -12, paddingLeft: 5 }}
                    />
                  </div>
                </div>
              ))}

              <Form.Item>
                <Space>
                  <Button
                    className="fw-bold"
                    type="primary"
                    shape="round"
                    onClick={() => add()}
                    icon={<PlusCircleOutlined />}
                  >
                    Ajouter une Compétance
                  </Button>
                  <Button
                    className="fw-bold"
                    htmlType="submit"
                    type="primary"
                    shape="round"
                    icon={<FontAwesomeIcon icon={faFloppyDisk} />}
                  >
                    Sauvgarder
                  </Button>
                </Space>
              </Form.Item>
            </div>
          )}
        </Form.List>
        </Card>
      )}
    </ConfigProvider>
  );
};

export default SkillFormList;
