import React, { useContext, useEffect, useState } from "react";
import { Button, Space, Table, Tooltip, Typography } from "antd";

import axios from "../../Utils/axios";
import GlobalContext from "../../Context/GlobalContext";

const EquipmentList = () => {
  const { equipments, setEquipments } = useContext(GlobalContext);
  const [loading, setLoading] = useState(true);

  const columns = [
    {
      title: "Réference",
      dataIndex: "_ref",
      key: "Catégorie",
    },
    {
      title: "Catégorie",
      dataIndex: "_categorie",
      key: "Référence",
    },
    {
      title: "Marque",
      dataIndex: "_brand",
      key: "Marque",
    },

    {
      title: "Actions",
      render: (record) => (
        <Space>
          <Tooltip title="Under Development">
            <Button type="primary">Demander</Button>
          </Tooltip>
        </Space>
      ),
    },
  ];

  useEffect(() => {
    async function fetchData() {
      const { data } = await axios.get(`/api/equipments/?status=AVAILABLE`);

      setEquipments(data.equipments);
      setLoading(false);
    }

    fetchData();
  // eslint-disable-next-line
  }, []);

  return (
    <div className="container p-4mt-4">
      <div className="mb-3">
        <Typography.Title level={4}>Liste des équipements disponible</Typography.Title>
      </div>

      <Table
        dataSource={equipments}
        loading={loading}
        columns={columns}
      />
    </div>
  );
};

export default EquipmentList;
