import { useEffect, useState } from "react";
import { Form, Input, DatePicker, notification, Timeline } from "antd";
import axiosInstance from "../../../Utils/axios";
import { SmileOutlined, LoadingOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import UploadInput from "../../../Components/UploadInput";
import StepsForm from "../../Components/StepsForm";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

const AccountCredentials = () => {
  const [loading, setLoading] = useState(true);
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();

  const openNotification = (name) => {
    api.open({
      message: "Bienvenue " + name,
      description:
        "Veuillez remplir toutes les données nécessaires pour accéder à votre compte.",
      icon: (
        <SmileOutlined
          style={{
            color: "#108ee9",
          }}
        />
      ),
      duration: 0,
    });
  };

  const { id } = useParams();
  const Navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await axiosInstance.get(
          "/api/employees/invitation/" + id
        );
        if (data.employee.status === true && data.employee.selfGen === false) {
          api.open({
            message: "L'opération n'est pas autorisée.",
            description: "Vous serez redirigé vers la page de connexion",
            icon: (
              <LoadingOutlined
                style={{
                  fontSize: 24,
                }}
                spin
              />
            ),
            type: "error",
            duration: 0,
          });
          setTimeout(() => {
            Navigate("/login");
          }, [3000]);
        } else {
          form.setFieldsValue({ ...data.employee });
          openNotification(data.employee.fullName);
          setLoading(false);
        }
      } catch (error) {
        api.open({
          message: "Erreur",
          description: error.response.data.message,
          type: "error",
          duration: 4.5,
        });
      }
    }
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setCV = (base64) => {
    form.setFieldsValue({
      CV: base64,
    });
  };

  const setProfilePic = (base64) => {
    form.setFieldsValue({
      imgProfile: base64,
    });
  };

  const setCIN = (base64) => {
    form.setFieldsValue({
      _cin_File: base64,
    });
  };

  const setRIB = (base64) => {
    form.setFieldsValue({
      _rib_File: base64,
    });
  };

  const steps = [
    {
      title: "Généralités",
      content: (
        <Timeline>
          <Timeline.Item>
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12">
                <Form.Item
                  label="Numéro de télèphone"
                  name="phoneNum"
                  validateTrigger={"onDone"}
                  rules={[
                    { required: true, message: "Merci de remplir le champ" },
                  ]}
                >
                  <PhoneInput defaultCountry="TN" placeholder="12 345 678" />
                </Form.Item>
              </div>
            </div>
          </Timeline.Item>
          <Timeline.Item>
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12">
                <Form.Item
                  name="CV"
                  label="CV"
                  validateTrigger={"onDone"}
                  rules={[
                    { required: true, message: "Merci de remplir le champ" },
                  ]}
                >
                  <UploadInput setFile={setCV} />
                </Form.Item>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <Form.Item
                  name="imgProfile"
                  label="Photo de profil"
                  rules={[
                    { required: true, message: "Merci de remplir le champ" },
                  ]}
                >
                  <UploadInput setFile={setProfilePic} />
                </Form.Item>
              </div>
            </div>
          </Timeline.Item>
        </Timeline>
      ),
    },
    {
      title: "Personnelle",
      content: (
        <Timeline>
          <Timeline.Item>
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12">
                <Form.Item
                  label="Date de naissance"
                  name="birthDate"
                  rules={[
                    { required: true, message: "Merci de remplir le champ" },
                  ]}
                >
                  <DatePicker style={{ width: "100%" }} format={"DD/MM/YYYY"} />
                </Form.Item>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <Form.Item
                  label="Lieu de naissance"
                  name="birthPlace"
                  rules={[
                    { required: true, message: "Merci de remplir le champ" },
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>
            </div>
          </Timeline.Item>
          <Timeline.Item>
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12">
                {" "}
                <Form.Item
                  label="Adresse"
                  name="address"
                  rules={[
                    { required: true, message: "Merci de remplir le champ" },
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>
            </div>
          </Timeline.Item>
          <Timeline.Item>
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12">
                {" "}
                <Form.Item
                  maxLength={8}
                  minLength={8}
                  type="Number"
                  label="CIN"
                  name="CIN"
                  rules={[
                    {
                      pattern: /^[\d]{8,8}$/,
                      message: "CIN ne doit avoir que 8 chiffres",
                    },
                    { required: true, message: "Merci de remplir le champ" },
                  ]}
                  validateTrigger="onDone"
                >
                  <Input
                    style={{ width: "100%" }}
                    maxLength={8}
                    onChange={(e) => {
                      form.setFieldsValue({
                        CIN: e.target.value.replace(/[^0-9]/g, ""),
                      });
                    }}
                  />
                </Form.Item>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                {" "}
                <Form.Item
                  name="_cin_File"
                  label="Joindre CIN"
                  rules={[
                    { required: true, message: "Merci de remplir le champ" },
                  ]}
                >
                  <UploadInput setFile={setCIN} />
                </Form.Item>
              </div>
            </div>
          </Timeline.Item>
        </Timeline>
      ),
    },
    {
      title: "Liés au travail",
      content: (
        <Timeline>
          <Timeline.Item>
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12">
                <Form.Item
                  label="RIB"
                  name="Rib"
                  rules={[
                    { required: true, message: "Merci de remplir le champ" },
                    {
                      pattern: /^[\d]{20,20}$/,
                      message: "RIB ne doit avoir que 20 chiffres",
                    },
                  ]}
                >
                  <Input
                    style={{ width: "100%" }}
                    maxLength={20}
                    onChange={(e) => {
                      form.setFieldsValue({
                        Rib: e.target.value.replace(/[^0-9]/g, ""),
                      });
                    }}
                  />
                </Form.Item>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <Form.Item
                  name="_rib_File"
                  tooltip="Relevés d’Identité Bancaire ou Postal (R.I.B ou R.I.P) avec cachet humide"
                  label="Joindre RIB"
                  rules={[
                    { required: true, message: "Merci de remplir le champ" },
                  ]}
                >
                  <UploadInput setFile={setRIB} />
                </Form.Item>
              </div>
            </div>
          </Timeline.Item>
        </Timeline>
      ),
    },
    {
      title: "Personne à prévenir en cas d'urgence",
      content: (
        <Timeline>
          <Timeline.Item>
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12">
                <Form.Item
                  name="_urgence_name"
                  label="Nom et prénom"
                  rules={[
                    { required: true, message: "Merci de remplir le champ" },
                  ]}
                >
                  <Input
                    onChange={(e) => {
                      form.setFieldsValue({
                        _urgence_name: e.target.value.replace(
                          /[^a-zA-Z ]/g,
                          ""
                        ),
                      });
                    }}
                  />
                </Form.Item>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                {" "}
                <Form.Item
                  validateTrigger={"onDone"}
                  name="_urgence_relation"
                  label="Relation"
                  rules={[
                    { required: true, message: "Merci de remplir le champ" },
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                {" "}
                <Form.Item
                  name="_urgence_phone"
                  label="Numéro de télèphone"
                  rules={[
                    { required: true, message: "Merci de remplir le champ" },
                  ]}
                >
                  <PhoneInput defaultCountry="TN" placeholder="12 345 678" />
                </Form.Item>
              </div>
            </div>
          </Timeline.Item>
        </Timeline>
      ),
    },
  ];

  const handleFinish = async () => {
    const formValues = form.getFieldsValue(true);
    setLoading(true);
    try {
      // eslint-disable-next-line no-unused-vars
      const { data } = await axiosInstance.patch(
        "/api/employees/save/" + id,
        formValues
      );
      api.open({
        message: "Le compte a été mis à jour avec succès.",
        description:
          "Consultez votre email pour obtenir le mot de passe de votre compte.",
        type: "success",
        duration: 0,
      });

      setTimeout(() => {
        Navigate("/login");
      }, [4500]);
    } catch (error) {
      api.open({
        message: "Erreur.",
        description: error.response.data.message,
        type: "error",
        duration: 0,
      });
      setLoading(false);
    }
  };

  return (
    <div
      className="min-vh-100 vw-100 pt-5 pb-5"
      style={{ backgroundColor: "#F4F5F7" }}
    >
      <div className="container p-5   shadow-md rounded-5">
        {contextHolder}
        <Form
          form={form}
          disabled={loading}
          layout="vertical"
          size="middle"
          onFinish={handleFinish}
        >
          <StepsForm steps={steps} form={form} loading={loading} />
        </Form>
      </div>
    </div>
  );
};

export default AccountCredentials;
