import {
  faCubes,
  faFileArrowDown,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Select, Space, Table, message, Tag, Tabs } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import axiosInstance from "../../../Utils/axios";

import dayjs from "dayjs";

const UserSkills = () => {
  const [skills, setSkills] = useState([]);
  const [loading, setLoading] = useState(true);
  const [skillsGrid, setSkillsGrid] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();
  const [selectedSkill, setSelectedSkill] = useState(null);
  const [selectedLevel, setSelectedLevel] = useState(null);

  const columns = [
    {
      title: "Nom et Prénom",
      dataIndex: "fullName",
      width: 200,
    },
    {
      title: "Soumettre le",
      dataIndex: "postedDate",
      width: 170,
      render: (text) => (
        <span style={{ fontWeight: "500" }}>
          {dayjs(text).format("DD/MM/YYYY HH:mm")}
        </span>
      ),
    },
    {
      title: "Niveau",
      children: [
        {
          title: "N/A",
          render: (_, record) => (
            <div>
              {record?.notApplicable?.map((elem) => (
                <Tag className="my-1">{elem?.label}</Tag>
              ))}
            </div>
          ),
        },
        {
          title: <span style={{ color: "#D4380D" }}>Débutant</span>,
          render: (_, record) => (
            <div>
              {record?.beginner?.map((elem) => (
                <Tag className="my-1" color="red">
                  {elem?.label}
                </Tag>
              ))}
            </div>
          ),
        },
        {
          title: <span style={{ color: "#D46B08" }}>Moyen</span>,
          render: (_, record) => (
            <div>
              {record?.average?.map((elem) => (
                <Tag className="my-1" color="orange">
                  {elem?.label}
                </Tag>
              ))}
            </div>
          ),
        },
        {
          title: <span style={{ color: "#389E0D" }}>Avancé</span>,
          render: (_, record) => (
            <div>
              {record?.advanced?.map((elem) => (
                <Tag className="my-1" color="green">
                  {elem?.label}
                </Tag>
              ))}
            </div>
          ),
        },
      ],
    },
  ];

  useEffect(() => {
    async function fetchSkills() {
      try {
        const { data } = await axiosInstance?.get("/api/grid/skills");
        setSkills(data?.skills);
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    }

    async function fetchGridResults() {
      try {
        const { data } = await axiosInstance.get(
          "/api/grid/skills/buckets/users"
        );

        setSkillsGrid(data?.usersSkillBuckets);
        setLoading(false);
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    }
    fetchSkills();
    fetchGridResults();
  }, []);

  const filterOption = (input, option) => {
    return (option?.value ?? "").toLowerCase().includes(input.toLowerCase());
  };

  const handleSelectSkills = (e) => {
    setSelectedSkill(e);
  };

  const handleSelectLevel = (e) => {
    setSelectedLevel(e);
  };

  const handleSearch = async () => {
    if (!selectedSkill || !selectedLevel) {
      return message.info(
        "Veuillez sélectionner à la fois la compétance et le niveau."
      );
    }

    messageApi.open({
      type: "loading",
      content: "Recherche en cours..",
      duration: 0,
    });

    try {
      const { data } = await axiosInstance.get(
        `/api/grid/skills/buckets/users/search?skill=${selectedSkill}&level=${selectedLevel}`
      );
      setTimeout(() => {
        setSearchResult(data?.users);
        messageApi.destroy();
      }, 500);
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  const searchColumns = [
    {
      title: "Nom et Prénom",
      dataIndex: "fullName",
      render: (_, record) => (
        <Link
          to={
            "/administration/employees/skills/" +
            record?.matricule +
            "?name=" +
            record?.fullName
          }
          style={{ textDecoration: "none", fontWeight: "600" }}
        >
          {record?.fullName}
        </Link>
      ),
    },
    {
      title: "Soumettre le",
      dataIndex: "postedDate",
      render: (text) => <>{dayjs(text).format("DD/MM/YYYY HH:mm")}</>,
    },
  ];

  const handleExportToXLSX = () => {
    const data = skillsGrid.map((record) => {
      let temp = {};
      temp["Matricule"] = record?.matricule;
      temp["Nom et Prénom"] = record?.fullName;
      temp["Date"] = dayjs(record?.postedDate).format("DD/MM/YYYY");

      record?.advanced?.forEach((elem) => {
        temp[elem?.label] = "Avancé";
      });
      record?.average?.forEach((elem) => {
        temp[elem?.label] = "Moyen";
      });
      record?.beginner?.forEach((elem) => {
        temp[elem?.label] = "Débutant";
      });
      record?.notApplicable?.forEach((elem) => {
        temp[elem?.label] = "N/A";
      });

      return temp;
    });

    const worksheet = XLSX.utils.json_to_sheet(data);

    const workbook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workbook, worksheet, "Grid");

    XLSX.writeFile(
      workbook,
      `Grille_de_compétances_${dayjs().format("DD_MM_YYYY_HH_mm")}.xlsx`
    );
  };

  const Navigate = useNavigate();

  const items = [
    {
      key: "1",
      label: "Rercherche",
      children: (
        <div>
          <Space.Compact className="col-lg-6 col-md-8 col-12 mb-3">
            <Select
              className="w-100"
              showSearch
              filterOption={filterOption}
              onSelect={handleSelectSkills}
            >
              {skills?.map((elem) => {
                return (
                  <Select.Option value={elem?.label}>
                    {elem?.label}
                  </Select.Option>
                );
              })}
            </Select>
            <Select className="w-100" onSelect={handleSelectLevel}>
              <Select.Option value="notApplicable">N/A</Select.Option>
              <Select.Option value="beginner">Débutant</Select.Option>
              <Select.Option value="average">Moyen</Select.Option>
              <Select.Option value="advanced">Avancé</Select.Option>
            </Select>
            <Button
              onClick={handleSearch}
              icon={<FontAwesomeIcon icon={faMagnifyingGlass} />}
            >
              Rechercher
            </Button>
          </Space.Compact>

          <Table columns={searchColumns} dataSource={searchResult} />
        </div>
      ),
    },
    {
      key: "2",
      label: "Export",
      children: (
        <div>
          <div className="d-flex justify-content-end">
            <Button
              shape="round"
              className="mb-2"
              icon={
                loading ? (
                  <LoadingOutlined />
                ) : (
                  <FontAwesomeIcon icon={faFileArrowDown} />
                )
              }
              onClick={handleExportToXLSX}
              disabled={loading}
            >
              Exporter
            </Button>
          </div>
          <Table
            loading={loading}
            columns={columns}
            dataSource={skillsGrid}
            scroll={{ x: "1080px" }}
          />
        </div>
      ),
    },
  ];

  return (
    <div>
      {contextHolder}
      <div className="d-flex justify-content-end">
        <div>
          <Button
            shape="round"
            type="primary"
            onClick={() => {
              Navigate("/administration/employees/skills/buckets");
            }}
            icon={<FontAwesomeIcon icon={faCubes} />}
          >
            Campagnes
          </Button>
        </div>
      </div>
      <div>
        <Tabs defaultActiveKey="1" items={items} className="w-100" />
      </div>
    </div>
  );
};

export default UserSkills;
