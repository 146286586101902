import React, { useContext, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import globalContext from "../../../../Context/GlobalContext";
import { Button, Modal } from "antd";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import dayjs from "dayjs";
const PlusModal = ({ data, date }) => {
  const { theme } = useContext(globalContext);
  const [modalOpen, setModalOpen] = useState(false);
  const capitalizeFirstLetter = (str) => {
    const capitalized = str[0].toUpperCase() + str.slice(1).toLowerCase();
    return capitalized;
  };
  return (
    <>
      <Button
        block
        type="primary"
        size="small"
        style={{ fontWeight: "500" }}
        onClick={() => setModalOpen(true)}
      >
        Voir Plus
      </Button>
      <Modal
        okButtonProps={{
          disabled: false,
        }}
        footer={null}
        title={
          <span style={{ color: "#2B96FF", fontSize: "14px" }}>
            <FontAwesomeIcon icon={faCalendar} /> Calendrier
          </span>
        }
        centered
        open={modalOpen}
        onOk={() => setModalOpen(false)}
        onCancel={() => setModalOpen(false)}
      >
        <div>
          <h1 style={{ fontSize: "18px", fontWeight: "600" }}>
            {dayjs(date).format("DD MMMM YYYY")}
          </h1>
          <h2 style={{ fontSize: "14px", fontWeight: "400" }}>
            {data?.length} Collaborateur(s) en congé(s)
          </h2>
        </div>
        <div
          style={{ height: "317px", overflowY: "scroll" }}
          className="d-flex flex-column"
        >
          {data?.map((elem) => {
            return (
              <div className="d-flex justify-content-between my-1">
                <span style={{ fontWeight: "500" }}>
                  {capitalizeFirstLetter(elem?.fullName)}
                </span>
                <span
                  style={{
                    backgroundColor: elem?._is_half_day
                      ? "#FFBE0B"
                      : elem?._typeLeave === "Congé annuel"
                      ? "#1ECB44"
                      : elem?._typeLeave === "Autorisation"
                      ? "#8338EC"
                      : elem?._typeLeave === "Congé sans solde"
                      ? "#FB0707"
                      : elem?._typeLeave === "Congé Special"
                      ? "#FB33FF"
                      : "#3A86FF",
                    borderRadius: "20px",
                    color: "#FFFFFF",
                    fontSize: "12px",
                    fontWeight: "500",
                    padding: "4px 7px",
                    textAlign: "center",
                  }}
                >
                  {elem?._is_half_day
                    ? "Congé 1/2"
                    : elem?._typeLeave === "Congé annuel"
                    ? "Congé"
                    : elem?._typeLeave === "Autorisation"
                    ? "Autorisation"
                    : elem?._typeLeave === "Congé sans solde"
                    ? "Congé"
                    : elem?._typeLeave === "Congé Special"
                    ? "Congé"
                    : "Congé"}
                </span>
              </div>
            );
          })}
        </div>
      </Modal>
    </>
  );
};
export default PlusModal;
