import React from "react";

import OtherRequestsTable from "../../Components/OtherRequestsTable";

const OtherRequests = () => {
  return (
    <div className="">
      <div className="p-4">
        <div className="mb-3 d-flex justify-content-between">
          <h4>Autre(s) demande(s)</h4>
          {/* <Button
            shape="round"
            onClick={() => Navigate("/request")}
            type="primary"
          >
            Nouvelle demande
          </Button> */}
        </div>
        <OtherRequestsTable size={"default"} />
      </div>
    </div>
  );
};

export default OtherRequests;
