import {
  Button,
  Card,
  DatePicker,
  Form,
  Input,
  Modal,
  Radio,
  Select,
  Space,
  Timeline,
  Typography,
} from "antd";
import React, { useContext, useState } from "react";
import axiosInstance from "../../../Utils/axios";
import GlobalContext from "../../../Context/GlobalContext";
import { useNavigate } from "react-router-dom";

const SelfAccountForm = ({ employees, setInitFormValues }) => {
  const { theme } = useContext(GlobalContext);
  const [form] = Form.useForm();
  const [isAlternate, setIsAlternate] = useState(false);

  const handleSubmit = async (formValues) => {
    formValues.Role = "EMPLOYEE";
    try {
      const { data } = await axiosInstance.post(
        "/api/employees/half",
        formValues
      );
      if (data) {
        Modal.success({
          title: "Invitation envoyée avec succès !",
          content: (
            <p>
              L'invitation de {formValues?.fullName} a été envoyée à l'email
              suivant. <br />
              <Typography.Text code>
                {formValues?._personal_email}
              </Typography.Text>
            </p>
          ),
        });
        form.resetFields();
      }
    } catch (error) {
      Modal.error({
        title: "Impossible de créer un compte!",
        content: <p>{error.response.data.message}</p>,
      });
    }
  };

  const Navigate = useNavigate();

  return (
    <Form
      onFinish={handleSubmit}
      form={form}
      className="p-3 gap-4 d-flex flex-column"
      layout="vertical"
      size="middle"
    >
      <Card title=" 🔗 Généralités">
        <div className="row w-100 p-3">
          <div className="col-lg-4 col-md-6 col-sm-12">
            <Space>
              <Form.Item label="Civilité" name="civility">
                <Radio.Group defaultValue={"M."}>
                  <Radio value={"Mme"}>Mme</Radio>
                  <Radio value={"M."}>M.</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                label="Nom et prénom"
                name="fullName"
                validateTrigger={"onDone"}
                rules={[
                  { required: true, message: "Merci de remplir le champ" },
                ]}
              >
                <Input
                  onChange={(e) => {
                    form.setFieldsValue({
                      fullName: e.target.value.replace(/[^a-zA-Z ]/g, ""),
                    });
                  }}
                />
              </Form.Item>
            </Space>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <Form.Item
              label="Email Personnel"
              name="_personal_email"
              validateTrigger={"onDone"}
              rules={[
                { type: "email", message: "Veuillez entrer un email valid" },
                { required: true, message: "Merci de remplir le champ" },
              ]}
            >
              <Input placeholder="email@gmail.com" />
            </Form.Item>
          </div>
          <div className="col-lg-2 col-md-6 col-sm-12">
            <Form.Item
              label="Matricule"
              name="matricule"
              rules={[{ required: true, message: "Merci de remplir le champ" }]}
            >
              <Input maxLength={5} />
            </Form.Item>
          </div>
          <div className="col-lg-2 col-md-6 col-sm-12">
            <Form.Item
              label="Date d'inscription"
              name="echeanceDate"
              rules={[{ required: true, message: "Merci de remplir le champ" }]}
            >
              <DatePicker placeholder="Date d'échéance" />
            </Form.Item>
          </div>

          <div className="row">
            <div className="col-lg-2 col-md-4 col-sm-12">
              <Form.Item
                label="Date d'embauche"
                name="EmbaucheDate"
                rules={[
                  { required: true, message: "Merci de remplir le champ" },
                ]}
              >
                <DatePicker placeholder="Date d'embauche" />
              </Form.Item>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-12">
              <Form.Item label="Alternance" name="_is_alternate">
                <Radio.Group
                  onChange={(e) => {
                    form.setFieldsValue({
                      ...form.getFieldsValue(),
                      _is_alternate: e.target.value,
                    });
                    setIsAlternate(e.target.value);
                  }}
                  defaultValue={false}
                  buttonStyle="solid"
                >
                  <Radio.Button value={true}>Oui</Radio.Button>
                  <Radio.Button value={false}>Non</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              {isAlternate && (
                <Form.Item
                  label="École"
                  name="_alternant_school"
                  rules={[
                    { required: true, message: "Veuillez remplir le champ" },
                  ]}
                >
                  <Input />
                </Form.Item>
              )}
            </div>
          </div>
        </div>
      </Card>

      <Card title='👥 Equipe'>
        <div className="row w-100 p-3">
          <div className="col-lg-4 col-md-6 col-sm-12">
            <Form.Item label="Responsable" name="_idResponsable">
              <Select placeholder="choisir un responsable pour cet employé">
                {employees.map((emp) => {
                  return (
                    <Select.Option value={emp.matricule}>
                      {emp.fullName} #{emp.matricule}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </div>
          <div className="col-lg-4 col-md-12 col-sm-12">
            <Form.Item
              label="Poste"
              name="Poste"
              rules={[{ required: true, message: "Merci de remplir le champ" }]}
            >
              <Input />
            </Form.Item>
          </div>
        </div>
      </Card>

      <div className="d-flex justify-content-between">
        <Button
          shape="round"
          size="large"
          onClick={() => {
            Navigate("/administration/employees/list");
          }}
        >
          Annuler
        </Button>
        <Button type="primary" shape="round" size="large" htmlType="submit">
          Inviter
        </Button>
      </div>
    </Form>
  );
};

export default SelfAccountForm;
