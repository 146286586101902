import React, { useState } from "react";
import { Button, Form, Input, message, Modal, Tooltip } from "antd";
import axiosInstance from "../../../../Utils/axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const RejectModal = ({ record, refresh, setRefresh }) => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = async () => {
    form
      .validateFields()
      .then((res) => {
        axiosInstance.patch(`/api/request/access/teamlead/${record?._id}`, {
          status: false,
          motif: res?.motif,
        });
        setIsModalOpen(false);
        setRefresh(!refresh);
      })
      .catch((error) => {
        message.error(error?.response?.data?.message || "Quelque chose s'est mal passé");
      });
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Tooltip title="Rejeter la demande">
        <Button
          type="primary"
          shape="circle"
          size="small"
          disabled={record?.answered_by[0] || record?.status !== "PENDING"}
          onClick={showModal}
          danger
        >
          <FontAwesomeIcon icon={faXmark} />
        </Button>
      </Tooltip>
      <Modal
        title="Etes-vous sûr de rejeter la demande ?"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Soumettre"
      >
        <Form layout="vertical" form={form}>
          <Form.Item
            label="Motif"
            name="motif"
            rules={[{ whitespace: true,required: true, message: "Veuillez fournir un motif." }]}
          >
            <Input.TextArea
              placeholder="Veuillez fournir un motif..."
              rows={4}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
export default RejectModal;
