import { Button, Form, Input, Typography, message, Radio, Spin, Card } from "antd";
import Dragger from "antd/es/upload/Dragger";
import {
  InboxOutlined,
  LoadingOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import addDocument from "../../../Assets/Addfiles-rafiki.svg";
import axios from "../../../Utils/axios";

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
    spin
  />
);

const options = [
  {
    label: "Lien(s)",
    value: "LINK",
  },
  {
    label: "Fichier(s)",
    value: "FILE",
  },
];

const optionCategorie = [
  {
    label: "Best Practice",
    value: "Best Practise",
  },
  {
    label: "Assurance",
    value: "Assurance",
  },
  {
    label: "Administratif",
    value: "Administratif",
  },
];

const SharedDocuments = () => {
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [type, setType] = useState("FILE");
  const [typeCat, setTypeCat] = useState("Best Practice");
  const [loader, setLoader] = useState(false);
  const handleUpload = async (info) => {
    let fileList = [...info.fileList];
    // Accept 5 files only
    fileList = fileList.slice(-5);

    fileList.forEach(function (file, index) {
      let reader = new FileReader();
      reader.onload = (e) => {
        file.base64 = e.target.result;
      };
      reader.readAsDataURL(file.originFileObj);
    });

    setFileList(fileList);
  };

  useEffect(() => {
    form.setFieldsValue({ ...form.getFieldsValue(), _type: "LINK" });
    /* eslint-disable-next-line */
  }, []);

  const onChange = ({ target: { value } }) => {
    setType(value);
  };
  const onChangeCat = ({ target: { value } }) => {
    setTypeCat(value);
  };

  const Navigate = useNavigate();

  const hnadleFinish = async (formValues) => {
    setLoader(true);
    if (type === "FILE") {
      formValues._file = fileList;
      formValues._link = [];
    } else {
      formValues._file = [];
    }
    formValues._type = type;
    try {
      const { data } = await axios.post("/api/docs/", formValues);
      message.success(data.message);
      setLoader(false);
      Navigate("/administration/documents/list");
    } catch (error) {
      setLoader(false);
    }
  };
  return (
    <div className="  p-4 ">
      <div className="mb-2">
        <Typography.Title level={4}>Nouveau document</Typography.Title>
      </div>
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-12 pt-5">
          <Card>
          <Form
            disabled={loader}
            form={form}
            onFinish={hnadleFinish}

            layout="vertical"
          >
            <Form.Item
              name="_title"
              rules={[{ required: true, message: "Veuillez saisir un titre" }]}
              label={
                <Typography.Text >
                  Titre
                </Typography.Text>
              }
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="_categorie"
              rules={[
                { required: true, message: "Veuillez choisir une catégorie" },
              ]}
              label={
                <Typography.Text >
                  Catégorie
                </Typography.Text>
              }
            >
              <Radio.Group
                options={optionCategorie}
                defaultValue={"Best Practice"}
                onChange={onChangeCat}
                value={typeCat}
              />
            </Form.Item>
            <Form.Item
              label={
                <Typography.Text >
                  Type
                </Typography.Text>
              }
            >
              <Radio.Group
                options={options}
                defaultValue={"FILE"}
                onChange={onChange}
                value={type}
                optionType="button"
              />
            </Form.Item>
            {type === "FILE" ? (
              <Form.Item
                name="_file"
                label={
                  <Typography.Text >
                    Fichier(s)
                  </Typography.Text>
                }
              >
                <Dragger
                  multiple={true}
                  onChange={handleUpload}
                  beforeUpload={() => false}
                >
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Cliquez ou faites glisser le fichier dans cette zone
                  </p>
                </Dragger>
              </Form.Item>
            ) : (
              <Form.Item
                label={
                  <Typography.Text >
                    Lien(s)
                  </Typography.Text>
                }
              >
                <Form.List
                  name="_link"
                  rules={[
                    {
                      validator: async (_, names) => {
                        if (!names || names.length < 1) {
                          return Promise.reject(new Error("At"));
                        }
                      },
                    },
                  ]}
                >
                  {(fields, { add, remove }, { errors }) => (
                    <>
                      {fields.map((field, index) => (
                        <Form.Item required={false} key={field.key}>
                          <Form.Item
                            {...field}
                            validateTrigger={["onChange", "onBlur"]}
                            rules={[
                              {
                                required: true,
                                whitespace: true,
                                message:
                                  "Veuillez saisir un lien ou supprimer ce champ.",
                              },
                            ]}
                            noStyle
                          >
                            <Input
                              placeholder="https//mywebsite.com"
                              style={{
                                width: "90%",
                                marginRight: "10px",
                              }}
                            />
                          </Form.Item>
                          {fields.length > 1 ? (
                            <MinusCircleOutlined
                              className="dynamic-delete-button text-danger pt-2"
                              onClick={() => remove(field.name)}
                            />
                          ) : null}
                        </Form.Item>
                      ))}
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          style={{
                            width: "60%",
                          }}
                          icon={<PlusOutlined />}
                        >
                          Ajouter un lien
                        </Button>

                        <Form.ErrorList errors={errors} />
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Form.Item>
            )}
            <Form.Item
              name="_description"
              rules={[
                { required: true, message: "Veuillez fournir une description" },
              ]}
              label={
                <Typography.Text >
                  Description
                </Typography.Text>
              }
            >
              <Input.TextArea />
            </Form.Item>
            <Form.Item
            >
              <Button size="large" type="primary" htmlType="submit">
                Soumettre!
              </Button>
              {loader && (
                <Spin
                  style={{ marginLeft: "10px" }}
                  size="large"
                  indicator={antIcon}
                />
              )}
            </Form.Item>
          </Form>
          </Card>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-12">
          <img src={addDocument} alt="cover" />
        </div>
      </div>
    </div>
  );
};

export default SharedDocuments;
