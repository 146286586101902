import React, { useContext } from "react";
import voteFilledd from "./VoteUpFilled.svg";
import axiosInstance from "../../Utils/axios";
import GlobalContext from "../../Context/GlobalContext";

const VoteUp = ({ post, setPost }) => {
  const { profile } = useContext(GlobalContext);
  const handleLike = async () => {
    try {
      const { data } = await axiosInstance.patch(
        "/api/suggestion/like/" + post._id
      );
      setPost({
        ...post,
        _like: data.like._like,
        _dislike: data.like._dislike,
      });
    } catch (error) {}
  };

  return (
    <div className="vote" onClick={handleLike}>
      {post?._like?.find((elem) => elem._liked_by === profile.matricule) ? (
        <img src={voteFilledd} alt="votefilled" />
      ) : (
        <svg
          width="19"
          height="20"
          viewBox="0 0 19 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.8463 10.4838C18.2213 9.98831 18.4289 9.38117 18.4289 8.74947C18.4289 7.74724 17.8687 6.79858 16.9669 6.26956C16.7347 6.13339 16.4704 6.06172 16.2012 6.06197H10.8486L10.9825 3.31867C11.0137 2.65572 10.7794 2.02626 10.324 1.54635C10.1005 1.3098 9.83094 1.1216 9.53186 0.993369C9.23278 0.865138 8.91058 0.799602 8.58517 0.800813C7.42446 0.800813 6.39767 1.58206 6.08964 2.70037L4.17222 9.64233H1.28606C0.890974 9.64233 0.571777 9.96153 0.571777 10.3566V18.4816C0.571777 18.8767 0.890974 19.1959 1.28606 19.1959H14.7079C14.9133 19.1959 15.1142 19.1557 15.2995 19.0754C16.362 18.6222 17.0472 17.5843 17.0472 16.4325C17.0472 16.1513 17.007 15.8745 16.9267 15.6066C17.3017 15.1111 17.5093 14.5039 17.5093 13.8722C17.5093 13.591 17.4691 13.3142 17.3887 13.0463C17.7637 12.5508 17.9713 11.9437 17.9713 11.312C17.9669 11.0307 17.9267 10.7517 17.8463 10.4838ZM2.17892 17.5888V11.2495H3.98696V17.5888H2.17892ZM16.3843 9.7093L15.8954 10.1334L16.2057 10.7004C16.3079 10.8871 16.3609 11.0968 16.3597 11.3097C16.3597 11.678 16.199 12.0285 15.9222 12.2696L15.4334 12.6937L15.7437 13.2606C15.8459 13.4474 15.8989 13.6571 15.8977 13.87C15.8977 14.2383 15.737 14.5888 15.4602 14.8298L14.9713 15.2539L15.2816 15.8209C15.3838 16.0077 15.4368 16.2174 15.4356 16.4303C15.4356 16.9303 15.141 17.3812 14.6856 17.5865H5.41553V11.178L7.63651 3.13117C7.69378 2.92492 7.81672 2.74296 7.9867 2.61286C8.15667 2.48276 8.36443 2.41161 8.57847 2.41019C8.74812 2.41019 8.91553 2.4593 9.04946 2.55974C9.27044 2.72492 9.38874 2.97492 9.37535 3.24055L9.16106 7.66912H16.1789C16.5762 7.91242 16.8218 8.32313 16.8218 8.74947C16.8218 9.11778 16.6611 9.46599 16.3843 9.7093Z"
            fill="#B6B6B6"
          />
        </svg>
      )}
    </div>
  );
};

export default VoteUp;
