import { useContext, useEffect, useState } from "react";
import {
  Space,
  Table,
  Tag,
  Typography,
  message,
  Form,
  Modal,
  Button,
  Tooltip,
  Popconfirm,
  Input,
} from "antd";

import { ExclamationCircleFilled } from "@ant-design/icons";

import dayjs from "dayjs";
import axios from "../../Utils/axios";
import UploadInput from "../../Components/UploadInput";
import GlobalContext from "../../Context/GlobalContext";

import { useNavigate } from "react-router-dom";
import LeaveRequestsDrawer from "../../Drawers/LeaveRequestsDrawer";
import {
  faArrowsRotate,
  faBan,
  faFileArrowUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const { confirm } = Modal;
const { Text, Paragraph } = Typography;

const OwnLeaves = () => {
  const { redirectData, profile } = useContext(GlobalContext);
  const [form] = Form.useForm();

  const [leaves, setLeaves] = useState([]);
  // eslint-disable-next-line
  const [loading, setLoading] = useState(true);

  const optionsTab =[
    "_typeLeave",
    "_posted_date",
    "_answered_by",
    "_event",
    "BeginingDate",
    "EndingDate",
    "Durée",
    "status",
  ];

  const setFile = (base64) => {
    form.setFieldsValue({
      OptionalFiles: base64,
    });
  };

  const columns = [
    optionsTab.find((elem) => elem === "_posted_date")
      ? {
        title: "Soumis le",
        dataIndex: "_posted_date",
        sorter: (a, b) => dayjs(a._posted_date) - dayjs(b._posted_date),
        render: (_, requestedLeave) => (
          <Text strong>
            {requestedLeave._posted_date
              ? dayjs(requestedLeave._posted_date).format("DD/MM/YYYY HH:mm")
              : "N/A"}
          </Text>
        ),
      }
      : { width: 0, className: "d-none" },
    optionsTab.find((elem) => elem === "_typeLeave")
      ? {
        title: "Type",
        dataIndex: "_typeLeave",
        width: 200,
        render: (_, requestedLeaves) => (
          <>
            {requestedLeaves._special_leave ? (
              <Paragraph
                style={{ margin: 0 }}
                ellipsis={{
                  rows: 2,
                  expandable: true,
                  symbol: "Plus",
                }}
              >
                {requestedLeaves._typeLeave +
                  `${requestedLeaves._special_leave &&
                  " - " + requestedLeaves._special_leave
                  }`}
              </Paragraph>
            ) : (
              <Text>{requestedLeaves._typeLeave}</Text>
            )}
          </>
        ),
      }
      : { width: 0, className: "d-none" },
    optionsTab.find((elem) => elem === "BeginingDate")
      ? {
        title: "Date de début",
        dataIndex: "BeginingDate",
        sorter: (a, b) => dayjs(a.BeginingDate) - dayjs(b.BeginingDate),
        render: (text) => <Text>{dayjs(text).format("DD/MM/YYYY")}</Text>,
      }
      : { width: 0, className: "d-none" },
    optionsTab.find((elem) => elem === "EndingDate")
      ? {
        title: "Date de Fin",
        dataIndex: "EndingDate",
        sorter: (a, b) => dayjs(a.EndingDate) - dayjs(b.EndingDate),
        render: (text) => <Text>{dayjs(text).format("DD/MM/YYYY")}</Text>,
      }
      : { width: 0, className: "d-none" },
    optionsTab.find((elem) => elem === "Durée")
      ? {
        title: "Durée",
        render: (_, requestedLeaves) => (
          <Text>
            {requestedLeaves._typeLeave === "Autorisation"
              ? requestedLeaves._duration
              : !requestedLeaves._is_half_day
                ? requestedLeaves.DaysNumber + " Jour(s)"
                : requestedLeaves._is_half_day}
          </Text>
        ),
      }
      : { width: 0, className: "d-none" },
    optionsTab.find((elem) => elem === "status")
      ? {
        title: "Statut",
        dataIndex: "status",
        filters: [
          {
            text: <Tag color={"orange"}>En Attente</Tag>,
            value: "Pending",
          },

          {
            text: <Tag color={"green"}>Approuvée</Tag>,
            value: "Accepted",
          },

          {
            text: <Tag color={"cyan"}>En attente d'approbation</Tag>,
            value: "Validating",
          },

          {
            text: <Tag color={"red"}>Rejeté</Tag>,
            value: "Rejected",
          },

          {
            text: <Tag color="red">Annulée</Tag>,
            value: "Canceled",
          },

          {
            text: <Tag color={"red"}>Non encore justifié</Tag>,
            value: "Unjustified",
          },
          {
            text: <Tag>Échue</Tag>,
            value: "Failed",
          },
        ],
        defaultFilteredValue:
          redirectData?.type === "DEFAULT" && redirectData?.filters
            ? redirectData?.filters
            : [],
        onFilter: (value, record) => {
          return record.status.includes(value);
        },
        render: (_, record) => (
          <>
            {record.status === "Pending" && (
              <Tag color={"orange"}>En Attente</Tag>
            )}
            {record.status === "Accepted" && (
              <Tag color={"green"}>Approuvée</Tag>
            )}
            {record.status === "Validating" && (
              <Tag color={"green"}>En attente d'approbation</Tag>
            )}
            {record.status === "Rejected" && <Tag color={"red"}>Rejeté</Tag>}
            {record.status === "Canceled" && <Tag color="red">Annulée</Tag>}
            {record.status === "Unjustified" && (
              <Tag color={"red"}>Non encore justifiée</Tag>
            )}
            {record.status === "Failed" && <Tag color={"red"}>Échue</Tag>}
          </>
        ),
      }
      : { width: 0, className: "d-none" },
    {
      title: "Actions",
      render: (_, record) => (
        <>
          <Space>
            {record.status === "Unjustified" && (
              <>
                {/* eslint-disable-next-line  */}
                <Space>
                  <Tooltip title="Annuler vote demande.">
                    <Button
                      onClick={() => {
                        handleActions({
                          _id: record._id,
                          value: "Canceled",
                        });
                      }}
                      type="primary"
                      size="small"
                      shape="circle"
                    >
                      <FontAwesomeIcon icon={faBan} />
                    </Button>
                  </Tooltip>
                  <Tooltip title="Justifier">
                    <Button
                      size="small"
                      shape="circle"
                      type="primary"
                      onClick={() => {
                        Modal.info({
                          title: "Joindre un justificatif",
                          okText: "Joindre",
                          content: (
                            <>
                              <Form form={form} layout="vertical">
                                <Form.Item
                                  name="OptionalFiles"
                                  label="Justificatif:"
                                >
                                  <UploadInput setFile={setFile} />
                                </Form.Item>
                              </Form>
                            </>
                          ),
                          onOk: async () => {
                            const formValues = form.getFieldsValue();
                            if (formValues.OptionalFiles) {
                              await axios.patch("/api/leaves/" + record._id, {
                                OptionalFiles: formValues.OptionalFiles,
                                status: "Validating",
                              });
                              let newLeaves = leaves.map((RL) => {
                                if (RL._id === record._id) {
                                  RL.status = "Validating";
                                }
                                return RL;
                              });

                              setLeaves(newLeaves);
                              confirm({
                                title: "Attention",
                                icon: <ExclamationCircleFilled />,
                                width: 380,
                                content: (
                                  <p>
                                    📢 Vous devrez également fournir une copie
                                    physique de ce justificatif à
                                    l'administration dans les plus brefs délais.
                                  </p>
                                ),

                                okText: "J'accepte",
                              });
                            }
                          },
                        });
                      }}
                    >
                      <FontAwesomeIcon icon={faFileArrowUp} />
                    </Button>
                  </Tooltip>
                </Space>
              </>
            )}

            {record.status === "Pending" && (
              <>
                <Tooltip title="Annuler vote demande.">
                  <Button
                    onClick={() => {
                      handleActions({
                        _id: record._id,
                        value: "Canceled",
                      });
                    }}
                    type="primary"
                    shape="circle"
                    size="small"
                  >
                    <FontAwesomeIcon icon={faBan} />
                  </Button>
                </Tooltip>
                <Tooltip title="Relancer votre demande, ce qui génère un nouveau e-mail de rappel à votre supérieur">
                  <Button
                    size="small"
                    shape="circle"
                    onClick={() => handleReminder(record?._id)}
                  >
                    <FontAwesomeIcon icon={faArrowsRotate} />
                  </Button>
                </Tooltip>
              </>
            )}
            {(record.status === "Rejected" ||
              record.status === "Failed" ||
              record.status === "Canceled" ||
              record.status === "Validating") && (
                <Tooltip title="Annuler vote demande.">
                  <Button type="primary" shape="circle" size="small" disabled>
                    <FontAwesomeIcon icon={faBan} />
                  </Button>
                </Tooltip>
              )}
            {record.status === "Accepted" && (
              <Popconfirm
                placement="topLeft"
                disabled={dayjs().isSameOrAfter(dayjs(record.BeginingDate))}
                title="êtes-vous sûr d'annuler la demande"
                okText="Oui"
                onConfirm={() => {
                  Modal.info({
                    title: "Annuler la demande",
                    content: (
                      <>
                        <Form form={form} layout="vertical">
                          <Form.Item name="_motif" label="Motif:" required>
                            <Input.TextArea />
                          </Form.Item>
                        </Form>
                      </>
                    ),
                    onOk: () => {
                      handleCancel(record._id);
                    },
                  });
                }}
              >
                <Tooltip title="Annuler vote demande.">
                  <Button
                    shape="circle"
                    disabled={dayjs().isSameOrAfter(
                      dayjs(record.BeginingDate)
                    )}
                    type="primary"
                    size="small"
                  >
                    <FontAwesomeIcon icon={faBan} />
                  </Button>
                </Tooltip>
              </Popconfirm>
            )}
            <LeaveRequestsDrawer record={record} />
          </Space>
        </>
      ),
    },
  ];

  const handleReminder = async (id) => {
    try {
      const { data } = await axios.post("/api/reminder/leave/" + id);

      if (data.status === "success") {
        message.success(data.message);
      }
    } catch (error) {
      message.error(error.response.data.message);
    }
  };

  const handleActions = async (options) => {
    try {
      const { data } = await axios.patch("/api/leaves/options/" + options._id, {
        status: options.value,
        self: true,
      });
      if (data.status === "success") {
        message.success(data.message);
        const newRequestedLeaves = leaves.map((RL) => {
          if (RL._id === options._id) {
            RL.status = options.value;
            RL._answered_at = dayjs();
            RL._answered_by = profile?.fullName;
          }
          return RL;
        });
        setLeaves(newRequestedLeaves);
      }
    } catch (error) { }
  };

  const handleCancel = async (id) => {
    const formValues = form.getFieldsValue();
    if (!formValues._motif) {
      return message.warning("Veuillez remplir le motif");
    }
    try {
      const { data } = await axios.patch("/api/leaves/cancel/" + id, {
        _motif: formValues._motif,
      });
      if (data.status === "success") {
        const temp = leaves.map((leave) => {
          if (leave._id === id) {
            leave.status = "Canceled";
          }
          return leave;
        });

        setLeaves(temp);
        message.success(data.message);
      }
    } catch (error) {
      message.error(error.response.data.message);
    }
  };

  useEffect(() => {
    async function fetchLeaves() {
      const { data } = await axios.get("/api/leaves/");
      setLoading(false);
      setLeaves(data.Leaves);
      return data;
    }
    fetchLeaves();
  }, []);

  const Navigate = useNavigate();
  return (
    <div className="">
      <div className="p-4">
        <div className="mb-3 d-flex justify-content-between">
          <Typography.Title level={4}>📋 Historique de mes demandes de Congés/Autorisations :</Typography.Title>
          <Button
            shape="round"
            onClick={() => Navigate("/request")}
            type="primary"
          >
            Nouvelle demande
          </Button>
        </div>

        <Table
          loading={loading}
          columns={columns}
          scroll={{ x: "max-content" }}
          dataSource={leaves}
        />
      </div>
    </div>
  );
};

export default OwnLeaves;
