import {
  Space,
  Steps,
  Table,
  Tag,
  Typography,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  LoadingOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  MinusCircleTwoTone,
  CheckCircleOutlined,
  CloseCircleOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";

import axiosInstance from "../../../Utils/axios";
import EquipmentRequestsDrawer from "../../../Drawers/EquipmentRequestsDrawer";

const EquipmentsTable = ({ size, getColumnSearchProps }) => {
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(true);
  // const [refresh, setRefresh] = useState(false);

  // const handleApprove = async (id) => {
  //   try {
  //     const { data } = await axiosInstance.patch(
  //       "/api/request/access/teamlead/" + id,
  //       { status: true }
  //     );
  //     if (data?.status === "success") {
  //       message.success(data?.message);
  //       setRefresh(!refresh);
  //     }
  //   } catch (error) {
  //     message.error(error?.response?.data?.message);
  //   }
  // };

  useEffect(() => {
    async function fetchRecords() {
      try {
        const { data } = await axiosInstance?.get(
          "/api/request/equipments/teamlead"
        );

        if (data?.status === "success") {
          setRecords(data?.requests);
          setLoading(false);
        }
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    }
    fetchRecords();
  }, []);

  const columns = [
    {
      title: "Demandeur",
      dataIndex: "sender",
      ...getColumnSearchProps("sender", "nom et prénom"),
    },
    {
      title: "Équipement demandé",
      dataIndex: "request",
      ...getColumnSearchProps("request", "équipement demandé"),
      render: (_, requestedLeaves) => (
        <Typography.Paragraph>
          <Tag
            color={
              JSON.parse(requestedLeaves?.json)?.type === "Réparation"
                ? "red"
                : "blue"
            }
          >
            {JSON.parse(requestedLeaves?.json)?.type}
          </Tag>{" "}
          {requestedLeaves.request}
        </Typography.Paragraph>
      ),
    },

    {
      title: "Statut",
      filters: [
        {
          text: (
            <Tag color="red">
              Rejetée <CloseCircleOutlined style={{ marginLeft: "5px" }} />
            </Tag>
          ),
          value: "REJECTED",
        },
        {
          text: (
            <Tag color="green">
              Approuvée <CheckCircleOutlined style={{ marginLeft: "5px" }} />
            </Tag>
          ),
          value: "APPROVED",
        },
        {
          text: (
            <Tag color="orange">
              En attente <LoadingOutlined style={{ marginLeft: "5px" }} />
            </Tag>
          ),
          value: "PENDING",
        },
        {
          text: (
            <Tag color="cyan">
              Annulée <MinusCircleOutlined style={{ marginLeft: "5px" }} />
            </Tag>
          ),
          value: "CANCELLED",
        },
      ],
      onFilter: (value, record) => record.status.includes(value),
      render: (_, record) => (
        <Steps
          items={[
            {
              title: "Responsable",
              status:
                record?.status !== "PENDING"
                  ? "finish"
                  : record?.status === !record?.answered_by[0]?.by
                  ? "process"
                  : "finish",
              description:
                record?.status === "CANCELLED"
                  ? "Annulé"
                  : record?.answered_by[0]?.by || "En attente",
              icon:
                record?.status !== "PENDING" && !record?.answered_by[0]?.by ? (
                  <MinusCircleTwoTone twoToneColor={"#b1b1b1"} />
                ) : !record?.answered_by[0]?.by ? (
                  <LoadingOutlined />
                ) : record?.answered_by[0].status ? (
                  <CheckCircleTwoTone twoToneColor={"#87d068"} />
                ) : (
                  <CloseCircleTwoTone twoToneColor={"#f3531e"} />
                ),
            },
            {
              title: "RH",
              status:
                record?.status !== "PENDING"
                  ? "finish"
                  : !record?.answered_by[0]?.by ||
                    !record?.answered_by[0]?.status
                  ? "wait"
                  : record?.answered_by[0]?.by && !record?.answered_by[1]?.by
                  ? "process"
                  : "finish",
              description:
                record?.status === "CANCELLED"
                  ? "Annulé"
                  : record?.answered_by[1]?.by || "En attente",
              icon:
                record?.status !== "PENDING" && !record?.answered_by[1]?.by ? (
                  <MinusCircleTwoTone twoToneColor={"#b1b1b1"} />
                ) : !record?.answered_by[0]?.by ||
                  !record?.answered_by[0]?.status ? (
                  <MinusCircleTwoTone twoToneColor={"#b1b1b1"} />
                ) : record?.answered_by[0]?.by &&
                  !record?.answered_by[1]?.by ? (
                  <LoadingOutlined />
                ) : record?.answered_by[1].status ? (
                  <CheckCircleTwoTone twoToneColor={"#87d068"} />
                ) : (
                  <CloseCircleTwoTone twoToneColor={"#f3531e"} />
                ),
            },
            {
              title: "Sys-Admin",
              status:
                record?.status !== "PENDING"
                  ? "finish"
                  : !record?.answered_by[1]?.by ||
                    !record?.answered_by[1]?.status
                  ? "wait"
                  : record?.answered_by[1]?.by && !record?.answered_by[2]?.by
                  ? "process"
                  : "finish",
              description:
                record?.status === "CANCELLED"
                  ? "Annulé"
                  : record?.answered_by[2]?.by || "En attente",
              icon:
                record?.status !== "PENDING" && !record?.answered_by[2]?.by ? (
                  <MinusCircleTwoTone twoToneColor={"#b1b1b1"} />
                ) : !record?.answered_by[1]?.by ||
                  !record?.answered_by[1]?.status ? (
                  <MinusCircleTwoTone twoToneColor={"#b1b1b1"} />
                ) : record?.answered_by[1]?.by &&
                  !record?.answered_by[2]?.by ? (
                  <LoadingOutlined />
                ) : record?.answered_by[2].status ? (
                  <CheckCircleTwoTone twoToneColor={"#87d068"} />
                ) : (
                  <CloseCircleTwoTone twoToneColor={"#f3531e"} />
                ),
            },
          ]}
          size="small"
        />
      ),
    },
    {
      title: "Action",
      render: (_, record) => (
        <Space>
          <EquipmentRequestsDrawer record={record} />
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Table
        columns={columns}
        loading={loading}
        dataSource={records}
        scroll={{ x: "max-content" }}
      />
    </div>
  );
};

export default EquipmentsTable;
