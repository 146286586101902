import React, { useEffect, useState } from "react";
import { Button, Drawer, Form, Input, Select, Space } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import ImageUpload from "./ImageUpload";
const EditTeam = ({ teamsData, setTeamsData, record }) => {
    const [picture, setPicture] = useState(null);
    const [open, setOpen] = useState(false);
    const [form] = Form.useForm();

    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };
    const onFinish = () => {
        form
            .validateFields()
            .then((values) => {
                let tempTeamsData = { ...teamsData };

                tempTeamsData.teams[record?.id] = {
                    id: record?.id,
                    name: values?.name,
                    description: values?.description,
                    team_lead_id: values?.team_lead_id,
                };

                tempTeamsData.columns[record?.id] = {
                    ...tempTeamsData.columns[record?.id],
                    id: record?.id,
                };

                setTeamsData(tempTeamsData);

                setOpen(false);
            })
            .catch(() => {
                // notification.error({
                //     description: info?.errorFields[0]?.errors[0]
                // })
                return;
            });
    };

    useEffect(() => {
        form.setFieldsValue({
            name: record?.name,
            description: record?.description,
            team_lead_id: record?.team_lead_id,
            // tasks: record?.tasks,
        });
    }, [record])
    return (
        <>
            <span
                onClick={showDrawer}
            >
                Modifier
            </span>

            <Drawer
                title={`Modifier ${record?.name}`}
                placement="right"
                size={"large"}
                onClose={onClose}
                open={open}
                extra={
                    <Space>
                        <Button onClick={onClose}>Annuler</Button>
                        <Button type="primary" onClick={onFinish}>
                            Modifier
                        </Button>
                    </Space>
                }
            >
                <Form form={form} size="large" layout="vertical">
                    <Form.Item
                        name="name"
                        label="Nom du projet"
                        rules={[
                            { required: true, message: "Veuillez entrer le nom du projet" },
                        ]}
                    >
                        <Input placeholder="Nom du projet" />
                    </Form.Item>
                    <Form.Item label="Image">
                        <ImageUpload pic={picture} setPic={setPicture} />
                    </Form.Item>
                    <Form.Item label="Description" name="description">
                        <Input.TextArea placeholder="Description" />
                    </Form.Item>
                    <Form.Item
                        name="team_lead_id"
                        label="Chef de projet"
                        rules={[
                            {
                                required: true,
                                message: "Veuillez selectioner le chef de projet",
                            },
                        ]}
                    >
                        <Select
                            options={Object.keys(teamsData?.members).map((key) => {
                                return {
                                    value: key,
                                    label: teamsData?.members[key].fullName,
                                }
                            })}
                        />
                    </Form.Item>
                    <div className="bg-light p-3 rounded-2">
                        <Form.List
                            name="tasks"
                            rules={[
                                {
                                    validator: async (_, names) => {
                                        if (!names || names.length < 2) {
                                            return Promise.reject(new Error("Au moins 2 objectifs"));
                                        }
                                    },
                                },
                            ]}
                        >
                            {(fields, { add, remove }, { errors }) => (
                                <>
                                    {fields.map((field, index) => (
                                        <Form.Item
                                            label={index === 0 ? "Objectifs et Fonctionnalités" : ""}
                                            required={false}
                                            key={field.key}
                                        >
                                            <div className="d-flex">
                                                <Form.Item
                                                    {...field}
                                                    validateTrigger={["onChange", "onBlur"]}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            whitespace: true,
                                                            message:
                                                                "Veuillez saisir un objectif ou supprimer ce champ.",
                                                        },
                                                    ]}
                                                    noStyle
                                                >
                                                    <Input.TextArea placeholder="Objectif" />
                                                </Form.Item>

                                                <MinusCircleOutlined
                                                    style={{ color: "red", marginLeft: "15px" }}
                                                    onClick={() => remove(field.name)}
                                                />
                                            </div>
                                        </Form.Item>
                                    ))}
                                    <Form.Item>
                                        <Button
                                            onClick={() => add()}
                                            style={{
                                                width: "60%",
                                            }}
                                            icon={<PlusOutlined />}
                                        >
                                            Objectif / Fonctionnalité
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </div>
                </Form>
            </Drawer>
        </>
    );
};
export default EditTeam;
