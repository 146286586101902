import React, { useEffect, useState } from "react";
import axiosInstance from "../../../Utils/axios";
import { Badge, message, Typography } from "antd";
import EmployeeCard from "../../../Components/EmployeeCard";

const Team = () => {
  const [employees, setEmployees] = useState([]);
  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await axiosInstance.get("/api/employees/team/me");
        setEmployees(data?.team);
        return data;
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    }

    fetchData();
  }, []);
  return (
    <div className="p-4">
      <div style={{ minHeight: "80vh" }}>
        <div style={{ maxHeight: "82vh", overflowY: "scroll" }}>
          <div className="container">
            <Typography.Title level={4}>🧑‍💻 Equipe de projet :</Typography.Title>

            <div className="row mt-2">
              {employees?.map((employee) => {
                return (
                  <div
                    className="col-lg-4 col-md-6 col-sm-12 my-2"
                    key={employee?._id || employee?.matricule}
                  >
                    {employee?.sub_role === "TEAM_LEAD" ? (
                      <Badge.Ribbon text="Chef de projet">
                        <EmployeeCard employee={employee} />
                      </Badge.Ribbon>
                    ) : (
                      <EmployeeCard employee={employee} />
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
