import PostForm from "../../Components/PostForm";

import { CaretRightOutlined } from "@ant-design/icons";
import { Collapse, Typography } from "antd";

import SondageForm from "../../Components/SondageForm/SondageForm";

const { Panel } = Collapse;

const Suggestion = () => {
  return (
    <div className="container   rounded-4 my-3 py-2 ">
      <div>
        <div>
          <h5 className=" border-bottom py-2">Espace suggestion / sondage</h5>
        </div>
        <Collapse
          bordered={false}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined
              style={{ color: "#284c8b" }}
              rotate={isActive ? 90 : 0}
            />
          )}
          style={{
            background: "white",
          }}
        >
          <Panel
            header={
              <Typography.Text style={{ color: "#284c8b" }} type="primary">
                Ajouter une suggestion
              </Typography.Text>
            }
            key="1"
            style={{
              marginBottom: 24,
              background: "#f8f9fa",
              borderRadius: "13px",
              border: "none",
            }}
          >
            <PostForm />
          </Panel>

          <Panel
            header={
              <Typography.Text style={{ color: "#284c8b" }} type="primary">
                Ajouter un sondage
              </Typography.Text>
            }
            key="2"
            style={{
              marginBottom: 24,
              background: "#f8f9fa",
              borderRadius: "13px",
              border: "none",
            }}
          >
            <SondageForm />
          </Panel>
        </Collapse>
      </div>
    </div>
  );
};

export default Suggestion;
