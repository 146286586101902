import { Button, Typography } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import AccessTable from "../OwnLeaves/AccessTable";
// import AccessRequestDrawer from "../../Components/LayoutClient/AccessRequestDrawer";

const AccessRequests = () => {
  // const [refresh, setRefresh] = useState(false);
  const Navigate = useNavigate();
  return (
    <div className="">
      <div className="p-4">
        <div className="mb-3 d-flex justify-content-between">
          <Typography.Title level={4}>📋 Historique de mes demandes d'accés :</Typography.Title>
          <Button type="primary" shape="round" onClick={() => Navigate('/self/access/request')}>
            Nouvelle demande
          </Button>
          {/* <AccessRequestDrawer refresh={refresh} setRefresh={setRefresh} /> */}
        </div>
        <AccessTable  />
      </div>
    </div>
  );
};

export default AccessRequests;
