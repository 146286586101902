import React from "react";
import { Button, Form, Input, message } from "antd";
import axios from "../../../Utils/axios";
import { useNavigate } from "react-router-dom";

const EditPassword = () => {
  const Navigate = useNavigate();
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const onSubmit = async (formValues) => {
    try {
      await axios.patch("/api/auth/edit/password", formValues);
      messageApi
        .open({
          type: "loading",
          content:
            "Le mot de passe a été modifié avec succès, vous serez redirigé vers la connexion...",
          duration: 3.5,
        })
        .then(() => {
          message.success("Redirection pour se connecter...", 1);
          setTimeout(() => {
            localStorage.removeItem("access_token");
            Navigate("/login");
          }, [1000]);
        });
    } catch (error) {
      message.error(error.response.data.message);
    }
  };
  return (
    <div>
      {contextHolder}
      {/* <h5>Modifier mot de passe</h5> */}
      <Form
        form={form}
        onFinish={onSubmit}
        layout="vertical"
        className="col-lg-7 col-md-12 col-sm-12"
      >
        <Form.Item
          label="Mot de passe actuel"
          name="actual_password"
          rules={[
            {
              required: true,
              message: "S’il vous plaît entrer votre mot de passe actuel !",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="Nouveau mot de passe"
          name="password"
          rules={[
            {
              required: true,
              message: "S’il vous plaît entrer votre nouveau mot de passe!",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="Confirmer le nouveau mot de passe"
          name="passwordConfirm"
          rules={[
            {
              required: true,
              message: "S’il vous plaît confirmer votre mot de passe!",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Button htmlType="submit" type="primary">
          Modifier
        </Button>
      </Form>
    </div>
  );
};

export default EditPassword;
