import React, { useState, useRef, useEffect, useContext } from "react";
import {
  Button,
  DatePicker,
  Table,
  Typography,
  message,
  Input,
  Space,
  Tag,
  Tooltip,
} from "antd";

import * as XLSX from "xlsx";

import Highlighter from "react-highlight-words";

import { SearchOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport, faWrench } from "@fortawesome/free-solid-svg-icons";

import dayjs from "dayjs";
import axios from "../../../Utils/axios";
import GlobalContext from "../../../Context/GlobalContext";
import ActiveCollabView from "./ActiveCollabView";

const { Text } = Typography;

const LeavesList = () => {
  const { role, rolePrevilleges } = useContext(GlobalContext);
  const [searchText, setSearchText] = useState("");
  const [mode, setMode] = useState("default");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [loading, setLoading] = useState(false);
  const searchInput = useRef(null);

  useEffect(() => {
    message.info("Sélectionner un mois");
  }, []);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleExportToXLSX = () => {
    const data = monthlyRecords.map((record) => {
      return {
        Matricule: record.matricule,
        "Nom et Prénom": record.fullname,
        "Congé special": record.special,
        "Congé maladie": record.sickness,
        "Congé sans solde": record.sansSold,
        "Congé annuel": record.yearly,
        Absence: record.unjustified,
        "Absence Reporting": record.missingReport,
        "Jours fériés": record.holidays,
        "Nbr total des jours non travaillés": record.totalLeaves,
        "Jours travaillés": record.workedDays,
        "Nbr autorisation": record.authNumber,
        "Durée totale d'autorisation (H)": record.authHours,
        "Solde de congé actuel": record.oldSold,
        "Solde cumulé": record.sold,
      };
    });

    const worksheet = XLSX.utils.json_to_sheet(data);

    const workbook = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    XLSX.writeFile(
      workbook,
      `Register mensuel ${dayjs().format("DD/MM/YYYY HH-mm")}.xlsx`
    );
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex, title) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Rechercher ${title}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
          // style={{
          //   width: 90,
          // }}
          >
            Rechercher
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Réinitialiser
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Collaborateur",
      dataIndex: "fullname",
      ...getColumnSearchProps("fullname", "Nom collaborateur"),
      render: (_, record) => (
        <Text strong>
          {record.fullname} <Text mark>#{record.matricule}</Text>
        </Text>
      ),
    },
    {
      title: "Jours non travaillés",
      children: [
        {
          title: "J.Fériés",
          dataIndex: "holidays",
          render: (text) => (
            <Text>
              <Tag color="cyan">
                <span>{text}</span> Jour(s)
              </Tag>
            </Text>
          ),
        },
        {
          title: "Cong Special",
          dataIndex: "special",
          sorter: (a, b) => a.special - b.special,
          render: (_, record) => (
            <>
              {record.special === 0 && (
                <Tag color="green">
                  <span>{record.special}</span> Jour(s)
                </Tag>
              )}
              {record.special > 0 && record.special <= 2 && (
                <Tag color="gold">
                  <span>{record.special}</span> Jour(s)
                </Tag>
              )}
              {record.special >= 3 && (
                <Tag color="red">
                  <span>{record.special}</span> Jour(s)
                </Tag>
              )}
            </>
          ),
        },
        {
          title: "Cong Annuel",
          dataIndex: "yearly",
          sorter: (a, b) => a.yearly - b.yearly,
          render: (text) => (
            <Tag color="cyan">
              <span>{text}</span> Jour(s)
            </Tag>
          ),
        },
        {
          title: "Cong sans solde",
          dataIndex: "sansSold",
          sorter: (a, b) => a.yearly - b.yearly,
          render: (text) => (
            <Tag color="cyan">
              <span>{text}</span> Jour(s)
            </Tag>
          ),
        },
        {
          title: "Cong Maladie",
          dataIndex: "sickness",
          sorter: (a, b) => a.sickness - b.sickness,
          render: (_, record) => (
            <>
              {record.sickness === 0 && (
                <Tag color="green">
                  <span>{record.sickness}</span> Jour(s)
                </Tag>
              )}
              {record.sickness > 0 && record.sickness <= 2 && (
                <Tag color="gold">
                  <span>{record.sickness}</span> Jour(s)
                </Tag>
              )}
              {record.sickness >= 3 && (
                <Tag color="red">
                  <span>{record.sickness}</span> Jour(s)
                </Tag>
              )}
            </>
          ),
        },
        {
          title: "Absence",
          dataIndex: "unjustified",
          sorter: (a, b) => a.unjustified - b.unjustified,
          render: (_, record) => (
            <>
              {record.unjustified === 0 && (
                <Tag color="green">
                  <span>{record.unjustified}</span> Jour(s)
                </Tag>
              )}
              {record.unjustified > 0 && record.unjustified <= 2 && (
                <Tag color="gold">
                  <span>{record.unjustified}</span> Jour(s)
                </Tag>
              )}
              {record.unjustified >= 3 && (
                <Tag color="red">
                  <span>{record.unjustified}</span> Jour(s)
                </Tag>
              )}
            </>
          ),
        },
        {
          title: "Total",
          dataIndex: "absenceDaysDisplay",
          sorter: (a, b) => a.absenceDaysDisplay - b.absenceDaysDisplay,
          render: (_, record) => (
            <>
              {record.absenceDaysDisplay === 0 && (
                <Tag color="green">
                  <span className="fw-bold">{record.absenceDaysDisplay}</span>{" "}
                  Jour(s)
                </Tag>
              )}
              {record.absenceDaysDisplay > 0 &&
                record.absenceDaysDisplay <= 3 && (
                  <Tag color="gold">
                    <span className="fw-bold">{record.absenceDaysDisplay}</span>{" "}
                    Jour(s)
                  </Tag>
                )}
              {record.absenceDaysDisplay > 3 && (
                <Tag color="red">
                  <span className="fw-bold">{record.absenceDaysDisplay}</span>{" "}
                  Jour(s)
                </Tag>
              )}
            </>
          ),
        },
      ],
    },
    {
      title: <Text style={{ top: "0" }}>Jours travaillés</Text>,
      dataIndex: "workedDaysDisplay",
      sorter: (a, b) => a.workedDaysDisplay - b.workedDaysDisplay,
      render: (_, record) => (
        <>
          {record.workedDaysDisplay >= 16 && record.workedDaysDisplay && (
            <Tag color="green">
              <span className="fw-bold">{record.workedDaysDisplay}</span>{" "}
              Jour(s)
            </Tag>
          )}
          {record.workedDaysDisplay < 16 && record.workedDaysDisplay > 8 && (
            <Tag color="gold">
              <span className="fw-bold">{record.workedDaysDisplay}</span>{" "}
              Jour(s)
            </Tag>
          )}
          {record.workedDaysDisplay <= 8 && record.workedDaysDisplay >= 0 && (
            <Tag color="red">
              <span className="fw-bold">{record.workedDaysDisplay}</span>{" "}
              Jour(s)
            </Tag>
          )}
          {record.workedDaysDisplay < 0 && (
            <Tag color="red">
              <span>{0}</span> Jour(s)
            </Tag>
          )}
        </>
      ),
    },
    {
      title: "Solde",
      dataIndex: "oldSold",
      sorter: (a, b) => a.oldSold - b.oldSold,
    },
    {
      title: "Solde cumulé",
      dataIndex: "sold",
      sorter: (a, b) => a.sold - b.sold,
    },
  ];

  const [monthlyRecords, setMonthlyRecords] = useState([]);
  const [RecordDate, setRecordDate] = useState("");

  const onChange = async (date, dateString) => {
    setLoading(true);
    setRecordDate(dateString);
    if (!date) {
      setLoading(false);
      message.info("Aucune date n'est sélectionnée");
    }

    const { data } = await axios.get(
      "/api/leaves/record/monthly/" + dateString
    );
    setMonthlyRecords(data);
    setLoading(false);
  };

  const disabledDate = (current) => {
    return current && current > dayjs().endOf("month");
  };

  const UpdateSold = async () => {
    if (RecordDate === "") {
      return message.error("Aucune date n'est sélectionnée");
    }
    try {
      const { data } = await axios.patch(
        "/api/employees/monthlyUpdate/" + RecordDate,
        monthlyRecords
      );
      if (data) {
        message.info("Sold mensuelles ont été mises à jour");
      }
    } catch (error) {
      message.error(error.response.data.message);
    }
  };

  const expandedRowRender = (record) => {
    const columns = [
      {
        title: "Type",
        dataIndex: "_typeLeave",
      },
      {
        title: "Répondu par",
        dataIndex: "_answered_by",
        render: (_, requestedLeaves) => (
          <>
            {requestedLeaves.status === "Pending" ? (
              <Tag color={"orange"}>En attente</Tag>
            ) : requestedLeaves._answered_by ? (
              <Tooltip
                title={
                  "Répondu à " +
                  dayjs(requestedLeaves._answered_at).format(
                    "DD/MM/YYYY HH:mm"
                  )
                }
              >
                <Text> {requestedLeaves._answered_by} </Text>
              </Tooltip>
            ) : (
              <Text>N/A</Text>
            )}
          </>
        ),
      },
      {
        title: "Date début",
        dataIndex: "BeginingDate",
        sorter: (a, b) => a.BeginingDate - b.BeginingDate,
        render: (text) => <Text>{dayjs(text).format("DD/MM/YYYY")}</Text>,
      },
      {
        title: "Date fin",
        dataIndex: "EndingDate",
        sorter: (a, b) => a.EndingDate - b.EndingDate,
        render: (text) => <Text>{dayjs(text).format("DD/MM/YYYY")}</Text>,
      },
      {
        title: "Durée",
        render: (_, requestedLeaves) => (
          <Text>
            {requestedLeaves._typeLeave === "Autorisation"
              ? requestedLeaves._duration
              : !requestedLeaves._is_half_day
                ? requestedLeaves.DaysNumber + " Jour(s)"
                : requestedLeaves._is_half_day}
          </Text>
        ),
      },
      {
        title: "Statut",
        dataIndex: "status",
        render: (_, record) => (
          <>
            {record.status === "Pending" && (
              <Tag color={"orange"}>En attente</Tag>
            )}
            {record.status === "Accepted" && (
              <Tag color={"green"}>Approuvée</Tag>
            )}
            {record.status === "Validating" && (
              <Tag color={"cyan"}>En attente d'approbation</Tag>
            )}
            {record.status === "Rejected" && <Tag color={"red"}>Rejeté</Tag>}
            {record.status === "Canceled" && (
              <Tag color={"geekblue"}>Annuler</Tag>
            )}
            {record.status === "Unjustified" && (
              <Tag color={"gold"}>Non encore justifié</Tag>
            )}
            {record.status === "Failed" && (
              <Tooltip title="Considérée comme absence non justifiée">
                <Tag>Échue</Tag>
              </Tooltip>
            )}
          </>
        ),
      },
    ];

    return (
      <Table
        rowKey={record.key}
        columns={columns}
        dataSource={record.monthlyLeaves}
        pagination={false}
      />
    );
    // }
  };

  return (
    <div className="  p-4  ">
      <div className="mb-3">
        <Typography.Title level={4}>Registre mensuel</Typography.Title>
      </div>
      <Typography.Text>Sélectionner un mois</Typography.Text>
      <br />
      <div className="row flex-column  flex-lg-row justify-content-between">
        <div className="col-lg-4 col-md-7 col-sm-12 ">
          <DatePicker
            disabledDate={disabledDate}
            onChange={onChange}
            style={{ width: "100%", marginRight: "5px" }}
            picker="month"
            className="mb-2"
          />
        </div>
        <div className="col-lg-5 col-md-5 col-sm-12 d-flex flex-column flex-md-row flex-lg-row justify-content-sm-start justify-content-lg-end">
          <Button
            className="mx-2"
            onClick={() =>
              setMode(mode === "default" ? "activeCollab" : "default")
            }
          >
            Mode {mode === "default" ? "ActiveCollab" : "Normal"}
          </Button>
          {rolePrevilleges?.previllegesList?.find(
            (elem) => elem?.code === "INCREMENT_LEAVE_BALANCE"
          ) && (
              <Button
                onClick={UpdateSold}
                type="primary"
                className="mb-2"
                style={{ marginRight: "5px" }}
              >
                <FontAwesomeIcon icon={faWrench} style={{ marginRight: "5px" }} />{" "}
                Incrémenter les soldes de congé
              </Button>
            )}
        </div>
      </div>
      {mode === "default" ? (
        <Table
          loading={loading}
          columns={columns}
          title={() => (
            <div className="d-flex justify-content-end">
              {rolePrevilleges?.previllegesList?.find(
                (elem) => elem?.code === "EXPORT_MONTHLY_REGISTER"
              ) && (
                  <Button
                    type="primary"
                    className="mb-2"
                    style={{ marginRight: "5px" }}
                    onClick={handleExportToXLSX}
                    disabled={!(monthlyRecords.length > 0)}
                  >
                    <FontAwesomeIcon
                      icon={faFileExport}
                      style={{ marginRight: "5px" }}
                    />{" "}
                    Exporter
                  </Button>
                )}
            </div>
          )}
          expandable={{
            expandedRowRender,
          }}
          scroll={{ x: "max-content" }}
          dataSource={monthlyRecords}
        />
      ) : (
        <ActiveCollabView selectedMonth={RecordDate} />
      )}
    </div>
  );
};

export default LeavesList;
