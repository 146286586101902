import React from "react";
import UserSkills from "./UserSkills";
import { Typography } from "antd";

const SkillsGrid = () => {
  return (
    <div className="pt-2">
      <div className="mb-3">
        <Typography.Title level={4}>Grille des Compétances</Typography.Title>
      </div>
      <div className="  p-4  ">
        <div>
          <UserSkills />
        </div>
      </div>
    </div>
  );
};

export default SkillsGrid;
