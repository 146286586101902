import { useState } from "react";
import {
  Button,
  Form,
  Input,
  Spin,
  notification,
  DatePicker,
  Space,
} from "antd";
import {
  LoadingOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import axiosInstance from "../../Utils/axios";
import dayjs from "dayjs";
const { RangePicker } = DatePicker;
const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
    spin
  />
);

const SondageForm = () => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };
  const [api, contextHolder] = notification.useNotification();
  const [dateRange, setDateRange] = useState("");
  const onChange = (date) => {
    if (date) {
      const start_date = date[0];
      const end_date = date[1];
      setDateRange({ startDate: start_date, endDate: end_date });
    } else {
      setDateRange("");
    }
  };

  const hnadleFinish = async (formValues) => {
    setLoading(true);
    formValues.start_date = dateRange.startDate;
    formValues.end_date = dateRange.endDate;

    if (formValues.options.length < 2) {
      setLoading(false);
      return api.error({
        message: "Impossible de crée un sondage!",
        description: "Veuillez ajouter 2 options au minimum",
      });
    }
    try {
      const { data } = await axiosInstance.post("/api/survey/", formValues);
      api["success"]({
        message: "Soumis avec succès",
        description: data.message,
      });
      setLoading(false);
      form.resetFields();
    } catch (error) {
      api["error"]({
        message: "Oups! Une erreur est survenue",
        description: error.response.data.message,
      });
      setLoading(false);
    }
    handleCancel();
  };

  const handleCancel = () => {
    form.resetFields();
  };

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current <= dayjs().endOf("day");
  };

  const disabledRangeTime = (_, type) => {
    if (type === "start") {
      return {
        disabledHours: () => range(0, 60).splice(4, 20),
        disabledMinutes: () => range(30, 60),
        disabledSeconds: () => [55, 56],
      };
    }
    return {
      disabledHours: () => range(0, 60).splice(20, 4),
      disabledMinutes: () => range(0, 31),
      disabledSeconds: () => [55, 56],
    };
  };

  return (
    <div className=" ">
      {contextHolder}
      <div className="d-flex container-fluid   custum-container-border">
        <Form
          style={{ marginLeft: "5px" }}
          className="my-2 w-100"
          layout="vertical"
          disabled={loading}
          form={form}
          onFinish={hnadleFinish}
        >
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 d-flex flex-column justify-content-between">
              <div>
                <Form.Item
                  label="Description"
                  name="description"
                  rules={[
                    { required: true, message: "Veuillez remplir le champ" },
                  ]}
                >
                  <Input.TextArea
                    allowClear
                    placeholder="Text..."
                    autoSize={{
                      minRows: 5,
                      maxRows: 5,
                    }}
                  />
                </Form.Item>
              </div>
            </div>
            <div className="col-lg-5 col-md-12 col-sm-12">
              <Form.Item label="Durée du vote">
                <RangePicker
                  className="w-100"
                  disabledDate={disabledDate}
                  disabledTime={disabledRangeTime}
                  onChange={onChange}
                  showTime={{
                    hideDisabledOptions: true,
                    defaultValue: [
                      dayjs("00:00:00", "HH:mm:ss"),
                      dayjs("11:59:59", "HH:mm:ss"),
                    ],
                  }}
                  format="YYYY-MM-DD HH:mm"
                />
              </Form.Item>

              <Form.Item
                label="Option(s)"
                rules={[
                  { required: true, message: "Veuillez ajouter une option" },
                ]}
              >
                <Form.List name="options" initialValue={[""]}>
                  {(sondages, { add, remove }, { errors }) => (
                    <>
                      {sondages.map((sondage, index) => (
                        <Form.Item
                          name="value"
                          required={false}
                          key={sondage.key}
                        >
                          <Form.Item
                            name="value"
                            {...sondage}
                            validateTrigger={["onChange", "onBlur"]}
                            rules={[
                              {
                                required: false,
                                whitespace: true,
                                message:
                                  "Veuillez saisir un option ou supprimer cette option.",
                              },
                            ]}
                            noStyle
                          >
                            <Input
                              placeholder="Exp: Oui/Non"
                              style={{
                                width: "calc(100% - 1em - 10px )",
                                marginRight: "10px",
                              }}
                            />
                          </Form.Item>
                          {sondages.length >= 1 ? (
                            <MinusCircleOutlined
                              className="dynamic-delete-button text-danger pt-2"
                              onClick={() => remove(sondage.name)}
                            />
                          ) : null}
                        </Form.Item>
                      ))}
                      <Form.Item>
                        {/* <Button
                          type="dashed"
                          onClick={() => add()}
                          style={{
                            width: "100%",
                          }}
                          className="d-flex align-items-center justify-content-center"
                        >
                          <PlusOutlined />
                          Ajouter une option
                        </Button> */}
                        <button
                          type="button"
                          onClick={() => add()}
                          style={{}}
                          className="custum-btn-link"
                        >
                          <PlusOutlined />
                          Ajouter une option
                        </button>

                        <Form.ErrorList errors={errors} />
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Form.Item>
            </div>
          </div>
          {/* <Form.Item className="d-flex">
            <Button onClick={handleReset}>Annuler</Button>
            <Button type="primary" htmlType="submit" className="mx-1">
              Poste
            </Button>
            {loading && (
              <Spin
                style={{ marginLeft: "10px" }}
                size="large"
                indicator={antIcon}
              />
            )}
          </Form.Item> */}
          <Form.Item className="d-flex justify-content-center">
            <Space>
              <Button onClick={handleCancel}>Annuler</Button>
              <Button htmlType="submit" type="primary">
                Soumettre
              </Button>

              {loading && (
                <Spin
                  style={{ marginLeft: "10px" }}
                  size="large"
                  indicator={antIcon}
                />
              )}
            </Space>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default SondageForm;
