import React from "react";
import {
    Avatar,
    Button,
    Card,
    Collapse,
    Divider,
    Dropdown,
    Empty,
    Popconfirm,
    Space,
    Typography,
    message,
} from "antd";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { DownOutlined } from "@ant-design/icons";
import AddTeam from "./AddTeam";
import EditTeam from "./EditTeam";

const data = {
    teams: {
        as_solution: {
            id: "as_solution",
            name: "AS Solution",
            description: "AS Solution",
            team_lead_id: "084",
            columnSize: "col-lg-6",
        },
        geoprod: {
            id: "geoprod",
            name: "Geoprod",
            description: "Geoprod",
            team_lead_id: "075",
            columnSize: "col-lg-6",
        },
        trinita: {
            id: "trinita",
            name: "Trinita",
            description: "Trinita",
            team_lead_id: "001",
            columnSize: "col-lg-6",
        },
        bi: {
            id: "bi",
            name: "BI",
            description: "BI",
            team_lead_id: "094",
            columnSize: "col-lg-6",
        },
        espace_distributeur: {
            id: "espace_distributeur",
            name: "Espace Distributeur / Client",
            description: "Espace Distributeur / Client",
            team_lead_id: "",
            columnSize: "col-lg-6",
        },
        response_indiv: {
            id: "response_indiv",
            name: "Réponsabilité Individuelle",
            description: "Réponsabilité Individuelle",
            team_lead_id: "",
            columnSize: "col-lg-12",
        },
        members: {
            id: "members",
            name: "Membre(s) sans projet",
            description: "Membres",
            team_lead_id: "",
            columnSize: "col-lg-12",
        },
    },
    columns: {
        members: {
            id: "members",
            teamIds: ["066", "031"],
            taskIds: [],
        },

        as_solution: {
            id: "as_solution",
            teamIds: ["082", "101", "076", "086"],
            taskIds: ["01", "02"],
        },
        geoprod: {
            id: "geoprod",
            teamIds: ["085", "079", "077", "070"],
            taskIds: ["01", "04"],
        },
        trinita: {
            id: "trinita",
            teamIds: ["093", "103", "088", "089", "073"],
            taskIds: ["01", "02", "03", "04"],
        },
        bi: {
            id: "bi",
            teamIds: ["080", "104"],
            taskIds: ["03", "04"],
        },
        espace_distributeur: {
            id: "espace_distributeur",
            teamIds: ["069", "063"],
            taskIds: ["01", "02", "03", "04"],
        },
        response_indiv: {
            id: "response_indiv",
            teamIds: ["074", "090", "020", "105", "087", "102", "099", "106", "100"],
            taskIds: [],
        },
    },
    columnsOrder: [
        "as_solution",
        "geoprod",
        "trinita",
        "bi",
        "espace_distributeur",
        "response_indiv",
    ],
    members: {
        "066": {
            _id: "6470d4fc60e02717738753f9",
            fullName: "ACHRAF KHELIL",
            matricule: "066",
        },
        "074": {
            _id: "6470d720c5d7ba023d805470",
            fullName: "AMINE OUNI",
            matricule: "074",
        },
        "001": {
            _id: "6470d4cc60e02717738753e6",
            fullName: "AMIRA HAMZAOUI",
            matricule: "001",
        },
        "082": {
            _id: "6470d702c5d7ba023d805454",
            fullName: "ARIJ MABROUK",
            matricule: "082",
        },
        "090": {
            _id: "6470d6bfc5d7ba023d805415",
            fullName: "ATEF BEDRI",
            matricule: "090",
        },
        "084": {
            _id: "6470d712c5d7ba023d805462",
            fullName: "AZIZ SOUABNI",
            matricule: "084",
        },
        "093": {
            _id: "6470d6d5c5d7ba023d80542a",
            fullName: "BILEL YAKOUBI",
            matricule: "093",
        },
        "085": {
            _id: "6470d719c5d7ba023d805469",
            fullName: "FERIEL JENDOUBI",
            matricule: "085",
        },
        "075": {
            _id: "6470d699c5d7ba023d8053f2",
            fullName: "GHAZI EL GHOUL",
            matricule: "075",
        },
        "103": {
            _id: "6515378ebb50f4fb23212e52",
            fullName: "HAYTHEM HMANDI",
            matricule: "103",
        },
        "020": {
            _id: "6470d4d660e02717738753ed",
            fullName: "ICHRAF KHELIL",
            matricule: "020",
        },
        "069": {
            _id: "6470d665c5d7ba023d8053c8",
            fullName: "INES KHEMIR",
            matricule: "069",
        },
        "105": {
            _id: "6548d4363529952cc826dcef",
            fullName: "JANNETTE HENAIEN",
            matricule: "105",
        },
        "094": {
            _id: "6470d6ebc5d7ba023d80543f",
            fullName: "KHAOULA ZARDOUM",
            matricule: "094",
        },
        "079": {
            _id: "6470d6a1c5d7ba023d8053f9",
            fullName: "LINDA MEDDEB",
            matricule: "079",
        },
        "063": {
            _id: "6470d60fc5d7ba023d8053c1",
            fullName: "MAJDA LABIADH",
            matricule: "063",
        },
        "101": {
            _id: "64c7f3b2450db620a03aedf7",
            fullName: "MOHAMED ALI CHAKHARI",
            matricule: "101",
        },
        "031": {
            _id: "6470d689c5d7ba023d8053e4",
            fullName: "MOHAMED AMEUR STAMBOULI",
            matricule: "031",
        },
        "087": {
            _id: "6470d6e4c5d7ba023d805438",
            fullName: "MOHAMED YASSINE YOUNES",
            matricule: "087",
        },
        "076": {
            _id: "6470d6b0c5d7ba023d805407",
            fullName: "NADIA BEN MANSOUR",
            matricule: "076",
        },
        "088": {
            _id: "6470d6ddc5d7ba023d805431",
            fullName: "RAHMA BOUSSAFFA",
            matricule: "088",
        },
        "102": {
            _id: "64c7f5a3450db620a03aee02",
            fullName: "RIHEM WADAA",
            matricule: "102",
        },
        "089": {
            _id: "6470d6cec5d7ba023d805423",
            fullName: "SINDA ABDAOUI",
            matricule: "089",
        },
        "099": {
            _id: "6470d754c5d7ba023d80548c",
            fullName: "SIRINE HAMMAMI",
            matricule: "099",
        },
        "106": {
            _id: "657b2c2629b02580941ad00a",
            fullName: "Sahbi Beji",
            matricule: "106",
        },
        "073": {
            _id: "6470d678c5d7ba023d8053d6",
            fullName: "TAHER MAAMOURI",
            matricule: "073",
        },
        "100": {
            _id: "6470d761c5d7ba023d805493",
            fullName: "WAEL CHEMKHI",
            matricule: "100",
        },
        "086": {
            _id: "6470d6f3c5d7ba023d805446",
            fullName: "WAEL JENDOUBI",
            matricule: "086",
        },
        "077": {
            _id: "6470d6b7c5d7ba023d80540e",
            fullName: "WAEL YAHIAOUI",
            matricule: "077",
        },
        "080": {
            _id: "6470d70ac5d7ba023d80545b",
            fullName: "YASMINE BRAHEM",
            matricule: "080",
        },
        104: {
            _id: "651abd3fbb50f4fb23214498",
            fullName: "YOSR BEN ABDALLAH",
            matricule: "104",
        },
        "070": {
            _id: "6470d66fc5d7ba023d8053cf",
            fullName: "YOSRA BAHRI",
            matricule: "070",
        },
    },
    tasks: {
        "01": {
            id: "01",
            title: "Task 1",
            description: "Task 1 description",
        },
        "02": {
            id: "02",
            title: "Task 2",
            description: "Task 2 description",
        },
        "03": {
            id: "03",
            title: "Task 3",
            description: "Task 3 description",
        },
        "04": {
            id: "04",
            title: "Task 4",
            description: "Task 4 description",
        },
        "05": {
            id: "05",
            title: "Task 5",
            description: "Task 5 description",
        },
    },
};

const TeamDropDownMenu = ({ record, teamsData, setTeamsData }) => {
    const handleDelete = () => {
        try {
            let tempTeams = { ...teamsData };
            tempTeams.columns["members"].teamIds = tempTeams.columns["members"].teamIds.concat(tempTeams.columns[record?.id]?.teamIds);
            delete tempTeams.teams[record?.id];
            delete tempTeams.columns[record?.id];
            tempTeams.columnsOrder = tempTeams.columnsOrder.filter((item) => item !== record?.id);

            if (record?.team_lead_id)
                tempTeams.columns["members"].teamIds.push(record?.team_lead_id);

            setTeamsData(tempTeams);
        } catch (error) {
            message.error(error?.response?.data?.message || "Quelque chose s'est mal passé");
        }
    }

    const items = [
        {
            label: (
                <EditTeam
                    record={record}
                    teamsData={teamsData}
                    setTeamsData={setTeamsData}
                />
            ),
            key: "0",
        },
        {
            label: <Popconfirm onConfirm={handleDelete} title="Etes-vous sûr de vouloir supprimer ce projet ?"><span>Supprimer</span></Popconfirm>,
            key: "1",
        },
    ];
    return (
        <Dropdown
            menu={{
                items,
            }}
            trigger={["click"]}
        >
            <Button type="primary" shape="round" onClick={(e) => e.preventDefault()}>
                <Space>
                    Opérations
                    <DownOutlined />
                </Space>
            </Button>
        </Dropdown>
    );
};
const Member = ({ member, index, memberId }) => {
    return (
        <Draggable draggableId={member?.matricule} index={index}>
            {(provided) => (
                <Card
                    bordered={true}
                    hoverable
                    size="small"
                    className="mt-1"
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                >
                    <Space>
                        <Avatar size={"large"}>{member?.fullName[0]}</Avatar>
                        {member?.fullName}
                    </Space>
                </Card>
            )}
        </Draggable>
    );
};
const Team = ({ members, team, tasks, columnId, teamsData, setTeamsData }) => {
    return (
        <div className={`col-lg-12 px-3 `}>
            <div className="d-flex justify-content-end">
                <TeamDropDownMenu
                    teamsData={teamsData}
                    setTeamsData={setTeamsData}
                    record={{
                        name: team?.name,
                        description: team?.description,
                        team_lead_id: team?.team_lead_id,
                        id: columnId,
                    }}
                />
            </div>
            <Divider orientation="left">
                {team?.team_lead_id ? (
                    <Space>
                        <Avatar>{data?.members[team?.team_lead_id]?.fullName[0]}</Avatar>
                        {data?.members[team?.team_lead_id]?.fullName} (Chef de projet)
                    </Space>
                ) : (
                    "N/A"
                )}
            </Divider>
            <Collapse
                className="my-3 shadow-md"
                size="large"
                expandIconPosition={"end"}
                items={[
                    {
                        key: "1",
                        label: "Membres de l'équipe",
                        children: (
                            <Droppable droppableId={columnId}>
                                {(provided) => (
                                    <div
                                        style={{ minHeight: "100px" }}
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                    >
                                        {members.map((member, index) => (
                                            <Member
                                                key={member}
                                                member={data?.members[member]}
                                                index={index}
                                                memberId={member}
                                            />
                                        ))}
                                        {members?.length === 0 && <Empty />}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        ),
                    },
                ]}
            />
            <Collapse
                className="my-3 shadow-md"
                size="large"
                expandIconPosition={"end"}
                items={[
                    {
                        key: "1",
                        label: "Objectifs et Fonctionnalités",
                        children: (
                            <ul>
                                {tasks.map((task) => {
                                    return (
                                        <li key={task}>
                                            <Typography
                                                style={{ fontSize: "16px", fontWeight: "500" }}
                                            >
                                                {data?.tasks[task]?.title}
                                            </Typography>
                                            <Typography>{data?.tasks[task]?.description}</Typography>
                                        </li>
                                    );
                                })}
                            </ul>
                        ),
                    },
                ]}
            />
        </div>
    );
};

const FixedTeam = ({ members, team, columnId }) => {
    return (
        <Card
            className="p-2 "
            style={{ height: "60vh", width: "100%", overflowY: "scroll" }}
        >
            <div>
                <Space style={{ fontWeight: "500" }}>
                    <Avatar size={"large"}>{team?.name[0]}</Avatar>
                    {team?.name}
                </Space>
            </div>
            <Divider />
            <Droppable droppableId={columnId}>
                {(provided) => (
                    <div
                        style={{ minHeight: "100px" }}
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                    >
                        {members.map((member, index) => (
                            <Member
                                key={member}
                                member={data?.members[member]}
                                index={index}
                                memberId={member}
                            />
                        ))}
                        {members?.length === 0 && <Empty />}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </Card>
    );
};

const TeamsManagement = () => {
    const [teamsData, setTeamsData] = React.useState(data);

    const onDragEnd = (result) => {
        // Handle drag and drop logic here
        const { destination, source, draggableId } = result;

        if (!destination) {
            return;
        }

        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return;
        }

        const start = teamsData.columns[source.droppableId];
        const finish = teamsData.columns[destination.droppableId];
        if (start === finish) {
            const newSectionIds = Array.from(start.teamIds);
            newSectionIds.splice(source.index, 1);
            newSectionIds.splice(destination.index, 0, draggableId);

            const newColumn = {
                ...start,
                teamIds: newSectionIds,
            };

            let tempColumns = teamsData.columns;
            tempColumns = {
                ...teamsData.columns,
                [newColumn.id]: newColumn,
            };
            setTeamsData({
                ...teamsData,
                columns: tempColumns,
            });

            return;
        }

        // Moving from one list to another
        const startSectionIds = Array.from(start.teamIds);
        startSectionIds.splice(source.index, 1);
        const newStart = {
            ...start,
            teamIds: startSectionIds,
        };

        const finishSectionIds = Array.from(finish.teamIds);
        finishSectionIds.splice(destination.index, 0, draggableId);
        const newFinish = {
            ...finish,
            teamIds: finishSectionIds,
        };

        let tempColumns = teamsData.columns;
        tempColumns = {
            ...teamsData.columns,
            [newStart.id]: newStart,
            [newFinish.id]: newFinish,
        };

        setTeamsData({
            ...teamsData,
            columns: tempColumns,
        });
    };



    const MemoizedFixedTeam = React.memo(FixedTeam);
    return (
        <div className="pt-2">
            <div className="mb-3">
                <h4>Gestion des équipes</h4>
            </div>
            <div
                className="  p-4 "
                style={{ height: "80vh", overflow: "hidden" }}
            >
                <DragDropContext onDragEnd={onDragEnd}>
                    <div className="row">
                        <div className="col-lg-5 my-3">
                            <MemoizedFixedTeam
                                key={"members-" + teamsData?.columnsOrder?.length}
                                members={teamsData?.columns["members"].teamIds}
                                columnId={"members"}
                                team={teamsData?.teams["members"]}
                            />
                            <AddTeam teamsData={teamsData} setTeamsData={setTeamsData} />
                        </div>

                        <div
                            className="col-lg-7"
                            style={{ height: "75vh", overflowY: "scroll" }}
                        >
                            {
                                teamsData?.columnsOrder?.map((columnId, index) => {
                                    const column = teamsData?.columns[columnId];
                                    const team = teamsData?.teams[columnId];
                                    return <Collapse
                                        className="my-3"
                                        size="large"
                                        expandIconPosition={"end"}
                                        items={[{
                                            key: index.toString(),
                                            label: (
                                                <Space style={{ fontWeight: "500" }}>
                                                    <Avatar size={"large"}>{team?.name[0]}</Avatar>
                                                    {team?.name}
                                                </Space>
                                            ),
                                            children: (
                                                <Team
                                                    teamsData={teamsData}
                                                    setTeamsData={setTeamsData}
                                                    key={columnId}
                                                    members={column?.teamIds}
                                                    tasks={column?.taskIds}
                                                    columnId={columnId}
                                                    team={team}
                                                />
                                            ),
                                        }]} />

                                })
                            }

                        </div>
                    </div>
                </DragDropContext>
            </div>
        </div>
    );
};

export default TeamsManagement;
