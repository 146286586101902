import {
  Form,
  Input,
  Select,
  Button,
  message,
  DatePicker,
  Typography,
  Card,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import {
  faPlus,
  faHeadphones,
  faComputerMouse,
  faPlug,
  faKeyboard,
  faLaptop,
  faDesktop,
  faMobile,
  faSimCard,
} from "@fortawesome/free-solid-svg-icons";

import GlobalContext from "../../../Context/GlobalContext";
import axios from "../../../Utils/axios";

import dayjs from "dayjs";

const { Option } = Select;

const EquipmentForm = () => {
  const [form] = Form.useForm();
  const { equipments, setEquipments } = useContext(GlobalContext);

  const handleFinish = async (formValues) => {
    try {
      const { data } = await axios.post("/api/equipments/", formValues);

      if (data) {
        formValues._time_line = [
          {
            _start_date: dayjs(formValues.date),
            _end_date: dayjs(formValues.date),
          },
        ];
        setEquipments([formValues, ...equipments]);

        message.success(data.message);

        form.resetFields();
      }
    } catch (error) {
      message.error(error.response.data.message);
    }
  };

  return (
    <div className="container p-4 ">
      <div className="mb-3">
        <Typography.Title level={4}>
          Ajouter un nouvel équipement
        </Typography.Title>
      </div>
      <Card>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleFinish}
          className="row"
        >
          <div className="col-lg-6 col-md-12 col-sm-12">
            <Form.Item
              name="_categorie"
              rules={[
                {
                  required: true,
                  message: "Veuillez remplir le champ",
                },
              ]}
              label="Designation"
            >
              <Select placeholder="Choisir une catégorie">
                <Option value="ECRAN">
                  Ecran <FontAwesomeIcon icon={faDesktop} />
                </Option>
                <Option value="PC">
                  PC <FontAwesomeIcon icon={faLaptop} />
                </Option>
                <Option value="CHARGEUR">
                  CHARGEUR <FontAwesomeIcon icon={faPlug} />
                </Option>
                <Option value="SOURIS">
                  Souris <FontAwesomeIcon icon={faComputerMouse} />
                </Option>
                <Option value="CLAVIER">
                  Clavier <FontAwesomeIcon icon={faKeyboard} />
                </Option>
                <Option value="CABLE">
                  Cable <FontAwesomeIcon icon={faPlug} />
                </Option>
                <Option value="CASQUE">
                  Casque <FontAwesomeIcon icon={faHeadphones} />
                </Option>
                <Option value="TELEPHONE_PORTABLE">
                  Téléphone portable <FontAwesomeIcon icon={faMobile} />
                </Option>
                <Option value="LIGNE_TELEPHONIQUE">
                  Ligne téléphonique <FontAwesomeIcon icon={faSimCard} />
                </Option>
              </Select>
            </Form.Item>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12">
            <Form.Item
              name="_ref"
              rules={[
                {
                  required: true,
                  message: "Veuillez remplir le champ",
                },
              ]}
              label="N°Serie"
            >
              <Input placeholder="S/N" />
            </Form.Item>
          </div>

          <div className="col-lg-6 col-md-12 col-sm-12">
            <Form.Item
              name="_brand"
              rules={[
                {
                  required: true,
                  message: "Veuillez remplir le champ",
                },
              ]}
              label="Marque"
            >
              <Input placeholder="Mark (HP/DELL/SAMSUNG)" />
            </Form.Item>
          </div>

          <div className="col-lg-6 col-md-12 col-sm-12">
            <Form.Item
              name="date"
              rules={[
                {
                  required: true,
                  message: "Veuillez remplir le champ",
                },
              ]}
              label="Date d'acquisition"
            >
              <DatePicker format={"DD/MM/YYYY"} className="w-100" />
            </Form.Item>
          </div>

          <Form.Item name="_num_bill" label="N° de facture">
            <Input placeholder="D7845" />
          </Form.Item>
          <Form.Item>
            <Button
              htmlType="submit"
              type="primary"
              icon={<FontAwesomeIcon icon={faPlus} />}
            >
              Ajouter
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default EquipmentForm;
