import React, { useContext, useEffect, useState } from "react";
import {
  Space,
  Table,
  Tag,
  Typography,
  message,
  Button,
  Popconfirm,
  Steps,
} from "antd";

import {
  CloseCircleTwoTone,
  LoadingOutlined,
  CheckCircleTwoTone,
  MinusCircleTwoTone,
} from "@ant-design/icons";

import dayjs from "dayjs";
import axios from "../../../Utils/axios";
import GlobalContext from "../../../Context/GlobalContext";

import TeleworkRequestsDrawer from "../../../Drawers/TeleworkRequestsDrawer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
const { Text } = Typography;

const TeleworkTable = () => {
  const { redirectData } = useContext(GlobalContext);

  const size = "default";
  const [refresh, setRefresh] = useState(false);
  const [leaves, setLeaves] = useState([]);
  const [loading, setLoading] = useState(true);

  const optionsTab = [
    "_typeLeave",
    "_posted_date",
    "_answered_by",
    "_event",
    "fullName",
    "BeginingDate",
    "EndingDate",
    "Durée",
    "status",
  ];

  const columns = [
    optionsTab.find((elem) => elem === "_posted_date")
      ? {
          title: "Soumis le",
          dataIndex: "_posted_date",
          sorter: (a, b) => dayjs(a._posted_date) - dayjs(b._posted_date),
          render: (_, requestedLeave) => (
            <Text strong>
              {requestedLeave._posted_date
                ? dayjs(requestedLeave._posted_date).format("DD/MM/YYYY HH:mm")
                : "N/A"}
            </Text>
          ),
        }
      : { width: 0, className: "d-none" },
    optionsTab.find((elem) => elem === "fullName")
      ? {
          title: "Demandeur",
          dataIndex: "fullName",
          key: "1",
          render: (_, requestedLeaves) => <>{requestedLeaves.fullName}</>,
        }
      : { width: 0, className: "d-none" },
    optionsTab.find((elem) => elem === "BeginingDate")
      ? {
          title: "Date de début",
          dataIndex: "BeginingDate",
          sorter: (a, b) => dayjs(a.BeginingDate) - dayjs(b.BeginingDate),
          render: (text) => <Text>{dayjs(text).format("DD/MM/YYYY")}</Text>,
        }
      : { width: 0, className: "d-none" },
    optionsTab.find((elem) => elem === "EndingDate")
      ? {
          title: "Date de Fin",
          dataIndex: "EndingDate",
          sorter: (a, b) => dayjs(a.EndingDate) - dayjs(b.EndingDate),
          render: (text) => <Text>{dayjs(text).format("DD/MM/YYYY")}</Text>,
        }
      : { width: 0, className: "d-none" },
    optionsTab.find((elem) => elem === "Durée")
      ? {
          title: "Durée",
          render: (_, requestedLeaves) => (
            <Text>
              {requestedLeaves._typeLeave === "Autorisation"
                ? requestedLeaves._duration
                : !requestedLeaves._is_half_day
                ? requestedLeaves.DaysNumber + " Jour(s)"
                : requestedLeaves._is_half_day}
            </Text>
          ),
        }
      : { width: 0, className: "d-none" },
    optionsTab.find((elem) => elem === "status")
      ? {
          title: "Statut",
          dataIndex: "status",
          filters: [
            {
              text: <Tag color={"orange"}>En Attente</Tag>,
              value: "Pending",
            },

            {
              text: <Tag color={"green"}>Approuvée</Tag>,
              value: "Accepted",
            },

            {
              text: <Tag color={"cyan"}>En attente d'approbation</Tag>,
              value: "Validating",
            },

            {
              text: <Tag color={"red"}>Rejeté</Tag>,
              value: "Rejected",
            },

            {
              text: <Tag color="red">Annulée</Tag>,
              value: "Canceled",
            },

            {
              text: <Tag color={"red"}>Non encore justifié</Tag>,
              value: "Unjustified",
            },
            {
              text: <Tag>Échue</Tag>,
              value: "Failed",
            },
          ],
          defaultFilteredValue:
            redirectData?.type === "DEFAULT" && redirectData?.filters
              ? redirectData?.filters
              : [],
          onFilter: (value, record) => {
            return record.status.includes(value);
          },
          render: (_, record) => (
            <Steps
              size="small"
              items={[
                {
                  title: "Chef D'équipe",
                  status:
                    record?.status === "Pending"
                      ? record?.validatedByTL
                        ? "finish"
                        : "process"
                      : "finish",
                  icon:
                    record?.status === "Pending" ? (
                      !record?.validatedByTL ? (
                        <LoadingOutlined />
                      ) : (
                        <CheckCircleTwoTone twoToneColor={"#87d068"} />
                      )
                    ) : record?.status !== "Accepted" ? (
                      record?.status === "Canceled" ? (
                        <MinusCircleTwoTone twoToneColor={"#b1b1b1"} />
                      ) : (
                        <CloseCircleTwoTone twoToneColor={"#f3531e"} />
                      )
                    ) : (
                      <CheckCircleTwoTone twoToneColor={"#87d068"} />
                    ),
                },
                {
                  title: "Resources Humaine",
                  status:
                    record?.status === "Pending"
                      ? record?.validatedByTL
                        ? record?.validatedByHR
                          ? "finish"
                          : "process"
                        : "finish"
                      : "finish",
                  icon:
                    record?.status === "Pending" ? (
                      record?.validatedByTL ? (
                        record?.validatedByHR ? (
                          <CheckCircleTwoTone twoToneColor={"#87d068"} />
                        ) : (
                          <LoadingOutlined />
                        )
                      ) : (
                        <MinusCircleTwoTone twoToneColor={"#b1b1b1"} />
                      )
                    ) : record?.status !== "Accepted" ? (
                      record?.status === "Canceled" ? (
                        <MinusCircleTwoTone twoToneColor={"#b1b1b1"} />
                      ) : (
                        <CloseCircleTwoTone twoToneColor={"#f3531e"} />
                      )
                    ) : (
                      <CheckCircleTwoTone twoToneColor={"#87d068"} />
                    ),
                },
              ]}
            />
          ),
        }
      : { width: 0, className: "d-none" },

    {
      title: "Actions",
      render: (_, record) => (
        <>
          <Space>
            <Popconfirm
              placement="topLeft"
              title="Etes-vous sûr de rejeter la demande de télétravail ?"
              onConfirm={() => {
                handleResponse(false, record?._id);
              }}
              okText="Oui"
            >
              <Button
                disabled={
                  record?.status !== "Pending" ||
                  record?.validatedByTL === true ||
                  record?.validatedByTL === false
                }
                size="small"
                shape="circle"
                danger
                type="primary"
              >
                <FontAwesomeIcon icon={faXmark} />
              </Button>
            </Popconfirm>
            <Popconfirm
              placement="topLeft"
              title="Etes-vous sûr d'approuver la demande de télétravail ?"
              onConfirm={() => {
                handleResponse(true, record?._id);
              }}
              okText="Oui"
            >
              <Button
                disabled={
                  record?.status !== "Pending" ||
                  record?.validatedByTL === true ||
                  record?.validatedByTL === false
                }
                size="small"
                shape="circle"
                type="primary"
              >
                <FontAwesomeIcon icon={faCheck} />
              </Button>
            </Popconfirm>
            <TeleworkRequestsDrawer record={record} />
          </Space>
        </>
      ),
    },
  ];

  const handleResponse = async (status, id) => {
    try {
      await axios.patch("/api/leaves/answer/telework/team/" + id, {
        status: status,
      });
      setRefresh(!refresh);
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    async function fetchRequestedLeaves() {
      setLoading(true);
      const { data } = await axios.get("/api/leaves/requested/leaves");
      const tempData = data?.filter((elem) => {
        return (
          (elem?.status === "Pending" ||
            elem?.status === "Validating" ||
            elem?.status === "Unjustified") &&
          elem?._typeLeave === "Télétravail"
        );
      });
      setLeaves(tempData);
      setLoading(false);
      return data;
    }

    fetchRequestedLeaves();
  }, [refresh]);

  return (
    <div className="">
      <Table
        loading={loading}
        columns={columns}
        scroll={{ x: "max-content" }}
        dataSource={leaves}
      />
    </div>
  );
};

export default TeleworkTable;
