import React, { useEffect } from "react";
import SkillFormList from "./SkillFormList";
import { Button, Form, message, Typography } from "antd";
import axiosInstance from "../../../Utils/axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faCircleChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const SkillForm = () => {
  const [form] = Form.useForm();
  const Navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await axiosInstance?.get("/api/grid/skills");
        form.setFieldValue("skills", data?.skills || []);
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    }
    fetchData();
  }, []);

  const handleSave = async (formValues) => {
    try {
      const { data } = await axiosInstance.post(
        "/api/grid/save/skills",
        formValues
      );
      if (data?.status === "success") message.success("Enregistré avec succès");
    } catch (error) {
      message.error(error?.response?.data?.message);
    }
  };
  return (
    <div className="pt-2">
      <div className="mb-3">
        <Typography.Title level={4}>Compétances</Typography.Title>
      </div>
      <div className="  p-4 ">
        <div className="d-flex justify-content-between">
          <Button
            onClick={() => Navigate("/administration/employees/skills/buckets")}
            shape="round"
            icon={<FontAwesomeIcon icon={faCircleChevronLeft} />}
          >
            Campagnes
          </Button>
          <Button
            onClick={() => {
              Navigate("/administration/manage/categories");
            }}
            shape="round"
            type="primary"
            icon={<FontAwesomeIcon icon={faBars} />}
          >
            Catégories
          </Button>
        </div>
        <Form className="mt-3" form={form} onFinish={handleSave}>
          <SkillFormList />
        </Form>
      </div>
    </div>
  );
};

export default SkillForm;
