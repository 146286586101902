import React, { useContext, useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
// UTILS
import LocalStorageService from "./Utils/localStorageService";

// ADMIN PAGES
import Layout from "./Admin/Components/Layout";
import EmployeeForm from "./Admin/Pages/EmployeeForm";
import EmployeeProfile from "./Admin/Pages/EmployeeProfile";
import EmployeesList from "./Admin/Pages/EmployeesList";
import LeaveFormAdmin from "./Admin/Pages/LeaveFormAdmin";
import RequestsList from "./Admin/Pages/RequestsList";
import LeavesList from "./Admin/Pages/LeavesList";
import Holidays from "./Admin/Pages/Holidays";
import EquipmentsList from "./Admin/Pages/EquipmentsList";
import AbsenceListAdmin from "./Admin/Pages/AbsenceListAdmin";
import Logs from "./Admin/Pages/Logs";
import Home from "./Admin/Pages/Home";

// PERSONNELLE PAGES
import LayoutClient from "./Components/LayoutClient";
import Profile from "./Pages/Profile";
import LeaveForm from "./Pages/LeaveForm";
import Default from "./Pages/Default/Default";
import LeavesListEmployees from "./Pages/LeavesListEmployees";
import AcceptePage from "./Pages/AcceptePage";
import RefusePage from "./Pages/RefusePage";
import OwnLeaves from "./Pages/OwnLeaves";
import AbsencesList from "./Pages/AbsencesList";

// COMMUN PAGES
import Login from "./Pages/Login";
import PasswordReset from "./Pages/PasswordReset";
import ForgotPassword from "./Pages/ForgotPassword";
import ErrorPage from "./Pages/ErrorPage";

// UTILS
import axios from "./Utils/axios";
import MaintainancePage from "./Pages/MaintainancePage";
import Parameters from "./Admin/Pages/Parameters";
import RequestInfoComponents from "./Pages/RequestInfoComponents";
import FaqPage from "./Pages/FaqPage";
import SharedDocuments from "./Admin/Pages/SharedDocuments";
import DocumentsList from "./Admin/Pages/DocumentsList";
import SharedDocs from "./Pages/SharedDocs";
import Faq from "./Admin/Pages/Faq";
import Trombinoscope from "./Pages/Trombinoscope";
import MyEquipments from "./Pages/MyEquipments";
import HistoryLeave from "./Admin/Pages/HistoryLeaves";
import GlobalContext from "./Context/GlobalContext";
import AttestationTravail from "./Pages/AttestationTravail";
import EquipmentsManagment from "./Admin/Pages/EquipmentsManagment";
import EquipmentList from "./Pages/EquipmentList";
import EditRequest from "./Admin/Pages/EditRequest";
import Suggestion from "./Pages/Suggestion";
import AccountCredentials from "./Admin/Pages/AccountCredentials";
import { HappyProvider } from "@ant-design/happy-work-theme";
import frFR from "antd/lib/locale/fr_FR";
import { ConfigProvider, FloatButton, theme as antdTheme, message } from "antd";
import TeamCalendar from "./Pages/TeamCalendar";
import BalanceArchive from "./Pages/BalanceArchive";
import OtherActions from "./Admin/Components/OtherActions";
import RolesAndPrevs from "./Admin/Components/RolesAndPrevs";
import EditPassword from "./Admin/Components/EditPassword";
import ManageAccess from "./Admin/Pages/ManageAccess";
import UsersAccessList from "./Admin/Pages/UsersAccessList";
import OtherRequests from "./Pages/OtherRequests";
import TeleworkList from "./Pages/TeleworkList";
import TeleworkForm from "./Pages/TeleworkForm";
import AccessRequests from "./Pages/AccessRequests";
import PendingRequests from "./Pages/PendingRequests";
import ArchiveEmployeesRequests from "./Pages/ArchiveEmployeesRequests";
import ContractsManagement from "./Admin/Pages/ContractsManagement";
import SkillsGrid from "./Admin/Pages/SkillsGrid";
import KpisManagement from "./Admin/Pages/KpisManagement/KpisManagement";
import ShareSkillBucket from "./Admin/Pages/ShareSkillBucket";
import Skills from "./Admin/Pages/Skills";
import SkillsCategories from "./Admin/Pages/SkillsCategories";
import UserSkills from "./Admin/Pages/UserSkills";
import SkillsBucketResults from "./Admin/Pages/SkillsBucketResults";
import OtherAbsenceListAdmin from "./Admin/Pages/OtherAbsenceListAdmin";
import InvitedUsers from "./Admin/Pages/InvitedUsers";
import Todos from "./Admin/Pages/Todos";
import PlannedTasks from "./Admin/Pages/PlannedTasks";
import AccessConfiguration from "./Admin/Pages/AccessConfiguration";
import TeamsManagement from "./Admin/Pages/TeamsManagement";
import EmployeesObjectives from "./Admin/Pages/EmployeesObjectives/EmployeesObjectives";

import OneSignal from "react-onesignal";
import AccessNewRequest from "./Pages/AccessNewRequest/AccessNewRequest";
import axiosInstance from "./Utils/axios";
import Documents from "./Pages/Profile/Components/Documents";
import Team from "./Pages/Profile/Components/Team";
import Acces from "./Pages/Profile/Components/Acces";
import Equipments from "./Pages/Profile/Components/Equipments";
import SkillsMe from "./Pages/Profile/Components/SkillsMe";
import ServicesConfiguration from "./Admin/Pages/ServicesConfiguration";
import ServersConfiguration from "./Admin/Pages/ServersConfiguration";

import { SunOutlined, MoonOutlined } from "@ant-design/icons";
import GeneralSettings from "./Admin/Pages/GeneralSettings";

const { darkAlgorithm, compactAlgorithm, defaultAlgorithm } = antdTheme;

const App = () => {
  const { role, setRole } = useContext(GlobalContext);
  const [theme, setTheme] = React.useState("light");

  const [token, setToken] = React.useState(
    LocalStorageService().getAccessToken() || null
  );

  const [restrict, setRestrict] = React.useState(true);

  useEffect(() => {
    async function initOneSignal() {
      await OneSignal.init({
        appId: "c2101146-fb59-4f25-9956-3b1664202347",
        allowLocalhostAsSecureOrigin: true,
      });

      OneSignal.Slidedown.promptPush();

      // Get OneSignal user ID
      const session_id = OneSignal.User.PushSubscription.id;

      // If the user's subscription status changes
      if (session_id !== null && session_id !== undefined && token) {
        try {
          const user = jwtDecode(token);
          await axiosInstance.post(
            `/api/notif/save/userId/${user?.idEmployee}`,
            {
              userId: session_id,
            }
          );
        } catch (error) {
          message.error(error?.response?.data?.message || "Quelque chose s'est mal passé");
        }
      }
    }
    initOneSignal();
  }, [token]);

  useEffect(() => {
    async function platformStatus() {
      const { data } = await axios.get("/api/platform/");
      setRestrict(data.status);
      return data;
    }
    platformStatus();
  }, []);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#2B96FF",
          colorInfo: "#2B96FF",
        },
        algorithm: [
          theme === "light" ? defaultAlgorithm : darkAlgorithm,
          compactAlgorithm,
        ],
      }}
      locale={frFR}
    >
      <HappyProvider>
        <Routes>
          {restrict && (
            <>
              <Route
                path="/accept/:id"
                exact
                element={
                    <AcceptePage />
                }
              />
              <Route
                path="/refuse/:id"
                exact
                element={
                    <RefusePage />
                }
              />

              <Route
                path="/reset/:token"
                exact
                element={
                    <PasswordReset theme={theme}/>
                }
              />
              <Route
                path="/forgot-password"
                exact
                element={
                    <ForgotPassword theme={theme}/>
                }
              />
            </>
          )}
          {/* ADMIN AND SUPER_ADMIN ROUTES */}
          {token &&
            (role === "SUPER_ADMIN" ||
              role === "SYS_ADMIN" ||
              role === "ACCOUNTANT") && (
              <>
                <Route
                  path="/administration"
                  element={<Layout role={role} theme={theme} />}
                >
                  <Route
                    path="/administration/equipments/managment"
                    element={<EquipmentsManagment />}
                  />
                  <Route path="employees/invited" element={<InvitedUsers />} />
                  <Route
                    path=""
                    element={<Home status={restrict} setStatus={setRestrict} />}
                  />
                  <Route
                    path="users/access/list"
                    element={<UsersAccessList />}
                  />
                  <Route
                    path="config/services"
                    element={<ServicesConfiguration />}
                  />
                  <Route
                    path="config/servers"
                    element={<ServersConfiguration />}
                  />
                  <Route path="planned/tasks" element={<PlannedTasks />} />
                  <Route path="employees/form" element={<EmployeeForm />} />
                  <Route path="employees/list" element={<EmployeesList />} />
                  <Route path="employees/access" element={<ManageAccess />} />
                  <Route
                    path="access/configuration"
                    element={<AccessConfiguration />}
                  />
                  <Route path="teams" element={<TeamsManagement />} />
                  <Route
                    path="employees/objectives"
                    element={<EmployeesObjectives />}
                  />
                  <Route
                    path="employees/:matricule"
                    element={<EmployeeProfile />}
                  />
                  <Route
                    path="employees/contracts"
                    element={<ContractsManagement />}
                  />
                  <Route path="employees/skills" element={<SkillsGrid />} />
                  <Route path="todos" element={<Todos />} />
                  <Route path="employees/skills/:id" element={<UserSkills />} />
                  <Route
                    path="employees/skills/bucket/:id"
                    element={<SkillsBucketResults />}
                  />
                  <Route path="manage/skills" element={<Skills />} />
                  <Route
                    path="manage/categories"
                    element={<SkillsCategories />}
                  />
                  <Route path="employees/kpis" element={<KpisManagement />} />
                  <Route
                    path="employees/skills/buckets"
                    element={<ShareSkillBucket />}
                  />
                  <Route path="equipments/list" element={<EquipmentsList />} />
                  <Route path="leaves/list" element={<LeavesList />} />
                  <Route path="absences/list" element={<AbsenceListAdmin />} />
                  <Route
                    path="absences/list/others"
                    element={<OtherAbsenceListAdmin />}
                  />
                  <Route path="leaves/form" element={<LeaveFormAdmin />} />
                  <Route path="requests" element={<RequestsList />} />
                  <Route path="requests/archive" element={<HistoryLeave />} />
                  <Route path="holidays" element={<Holidays />} />
                  <Route path="logs" element={<Logs />} />
                  <Route
                    path="parametre"
                    element={
                      <Parameters status={restrict} setStatus={setRestrict} />
                    }
                  >
                    <Route index element={<GeneralSettings />} />
                    <Route path="account" element={<EditPassword />} />
                    <Route path="other" element={<OtherActions />} />
                    <Route path="roles" element={<RolesAndPrevs />} />
                  </Route>
                  <Route path="documents/form" element={<SharedDocuments />} />
                  <Route path="documents/list" element={<DocumentsList />} />
                  <Route
                    path="edit/request/:_id/:matricule"
                    element={<EditRequest />}
                  />
                  {/* <Route path="suggestions" element={<Suggestions />} /> */}
                  <Route path="faq" element={<Faq />} />
                </Route>

                {/* FORCE TO HOME PAGE IF ROUTE UNDEFINED */}
                <Route
                  path="*"
                  exact
                  element={<Navigate to="/administration/requests" />}
                />
              </>
            )}

          {/* PERSONNELLE ROUTES */}
          {((token && role === "EMPLOYEE") ||
            (token && role === "TEAM_LEAD")) &&
            restrict === true && (
              <Route path="/" exact element={<LayoutClient theme={theme} />}>
                <Route path="/" exact element={<Default />} />
                <Route path="/faq" exact element={<FaqPage />} />
                <Route path="/profile" exact element={<Profile />} />
                <Route path="/docs/me" exact element={<Documents />} />
                <Route path="/team/me" exact element={<Team />} />
                <Route path="/access/me" exact element={<Acces />} />
                <Route path="/equipments/me" exact element={<Equipments />} />
                <Route path="/skills" exact element={<SkillsMe />} />
                <Route path="/tools/" exact element={<SharedDocs />} />
                <Route path="/request" exact element={<LeaveForm />} />
                <Route
                  path="/balance/archive"
                  exact
                  element={<BalanceArchive />}
                />
                <Route
                  path="/manage/subordinates"
                  element={<LeavesListEmployees />}
                />
                <Route
                  path="/manage/subordinates/archive"
                  element={<ArchiveEmployeesRequests />}
                />
                <Route path="/manage/self" element={<OwnLeaves />} />
                <Route path="/suggestions" element={<Suggestion />} />
                <Route
                  path="/request/equipments"
                  element={<RequestInfoComponents />}
                />
                <Route path="/pending/requests" element={<PendingRequests />} />
                <Route path="/self/equipments" element={<MyEquipments />} />
                <Route
                  path="/equipments/available"
                  element={<EquipmentList />}
                />
                <Route path="/absences" element={<AbsencesList />} />

                <Route path="/trombinoscope" element={<Trombinoscope />} />
                <Route path="/team/calendar" element={<TeamCalendar />} />
                <Route path="/other/requests" element={<OtherRequests />} />
                <Route
                  path="/manage/self/telework"
                  element={<TeleworkList />}
                />
                <Route path="/self/access" element={<AccessRequests />} />
                <Route
                  path="/self/access/request"
                  element={<AccessNewRequest />}
                />

                <Route path="/request/telework" element={<TeleworkForm />} />
                <Route
                  path="/work/certificate"
                  element={<AttestationTravail />}
                />

                {/* FORCE TO HOME PAGE IF ROUTE UNDEFINED */}
                <Route path="*" exact element={<Navigate to="/" />} />
              </Route>
            )}

          {/* COMMUN ROUTES */}
          <Route
            path="/login"
            exact
            element={
              <Login
                theme={theme}
                _setToken={setToken}
                _setRole={setRole}
                maintainance={restrict}
              />
            }
          />
          <Route
            path="/credentials/:id"
            exact
            element={<AccountCredentials />}
          />

          {!restrict && <Route path="*" exact element={<MaintainancePage />} />}

          {/* FORCE TO LOGIN PAGE IF NOT LOGGED IN */}
          <Route path="/" element={<Navigate to="/login" />} />

          {/* ERROR ROUTES */}
          {restrict && <Route path="*" exact element={<ErrorPage />} />}
        </Routes>
        <FloatButton
          shape="circle"
          type="primary"
          onClick={() => setTheme(theme === "light" ? "dark" : "light")}
          icon={theme === "light" ? <MoonOutlined /> : <SunOutlined />}
        />
      </HappyProvider>
    </ConfigProvider>
  );
};

export default App;
