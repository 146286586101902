import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  message,
  Drawer,
  DatePicker,
  Space,
  Divider,
  Typography,
} from "antd";
import axios from "../../Utils/axios";
import UploadInput from "../UploadInput";
import PhoneInput from "react-phone-number-input";
import dayjs from "dayjs";

const RequestChangeModal = (props) => {
  const {
    onClose,
    visible,
    variables,
    drawerSize,
    setVariables,
  } = props;
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();
  variables.birthDate = dayjs(variables.birthDate);
  variables.embaucheDate = dayjs(variables.embaucheDate);

  const setCIN = (base64) => {
    form.setFieldsValue({
      _cin_File: base64,
    });
  };
  const setCV = (base64) => {
    form.setFieldsValue({
      CV: base64,
    });
  };
  const setRIB = (base64) => {
    form.setFieldsValue({
      _rib_File: base64,
    });
  };

  useEffect(() => {
    form.setFieldsValue({ ...variables });
    // eslint-disable-next-line
  }, [variables]);

  const UpdateUser = async () => {
    setLoading(true);
    const formValues = form.getFieldsValue();
    try {
      const _edit = JSON.stringify(formValues);
      const { data } = await axios.patch(`/api/employees/request/edit`, {
        _edit,
      });
      setLoading(false);
      if (data.status === "success") {
        message.success(data.message);
        setVariables(formValues);
        onClose();
      }
    } catch (error) {
      setLoading(false);
      message.error(error.response.data.message);
    }
    onClose();
  };
  return (
    <>
      <Drawer
        onClose={onClose}
        width={drawerSize}
        visible={visible}
        title="Modification des informations"
        extra={
          <Space>
            <Button onClick={onClose}>Annuler</Button>
            <Button onClick={UpdateUser} type="primary">
              Modifier
            </Button>
          </Space>
        }
      >
        <Typography.Paragraph style={{ fontSize: 12 }}>
          Veuillez remplir et soumettre le formulaire ci-dessous pour mettre à
          jour vos informations. Notre équipe vérifiera votre demande. Vous
          recevrez une notification par e-mail une fois approuvée.{" "}
        </Typography.Paragraph>
        <Divider />
        <Form form={form} layout="vertical" size="middle" disabled={loading}>
          <div className="row border-bottom mb-3">
            <div className="col-lg-6 col-sm-12">
              <Form.Item label="Nom et prénom" name="fullName">
                <Input
                  onChange={(e) => {
                    form.setFieldsValue({
                      fullName: e.target.value.replace(/[^a-zA-Z ]/g, ""),
                    });
                  }}
                />
              </Form.Item>
              <Form.Item label="Email Professionel" name="email">
                <Input />
              </Form.Item>
              <Form.Item label="Email Personel" name="_personal_email">
                <Input type="email" />
              </Form.Item>
              <Form.Item label="Numéro de télèphone" name="phoneNum">
                <PhoneInput defaultCountry="TN" placeholder="12 345 678" />
              </Form.Item>

              <Form.Item label="RIB" name="Rib">
                <Input status={!variables.Rib && "error"} />
              </Form.Item>
              <Form.Item label="CIN" name="CIN">
                <Input />
              </Form.Item>
              <Form.Item label="Adresse" name="address">
                <Input />
              </Form.Item>
            </div>
            <div className="col-lg-6 col-sm-12">
              <Form.Item label="Matricule" name="matricule">
                <Input />
              </Form.Item>
              <Form.Item label="Date d'embauche" name="embaucheDate">
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
              <Form.Item label="Date de naissance" name="birthDate">
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>
              <Form.Item label="Lieu de naissance" name="birthPlace">
                <Input />
              </Form.Item>
              <Form.Item label="Type de contrat" name="contractType">
                <Input disabled />
              </Form.Item>
              <Form.Item label="Poste" name="Poste">
                <Input />
              </Form.Item>
            </div>
          </div>
          <div>
            <h4>Personne à prévenir en cas d'urgence</h4>
          </div>
          <div className="col-lg-6 col-sm-12">
            <Form.Item name="_urgence_name" label="Nom et prénom">
              <Input />
            </Form.Item>
            <Form.Item name="_urgence_relation" label="Relation">
              <Input />
            </Form.Item>
            <Form.Item name="_urgence_phone" label="Numéro de télèphone">
              <Input />
            </Form.Item>
          </div>
          <div>
            <div className="border-top pt-3">
              <h4>Pièces a joindre</h4>
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <Form.Item name="_cin_File" label="CIN">
                  <UploadInput setFile={setCIN} />
                </Form.Item>
                <Form.Item name="CV" label="CV">
                  <UploadInput setFile={setCV} />
                </Form.Item>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <Form.Item name="_rib_File" label="RIB">
                  <UploadInput setFile={setRIB} />
                </Form.Item>
              </div>
            </div>
          </div>
        </Form>
      </Drawer>
    </>
  );
};

export default RequestChangeModal;
