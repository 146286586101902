import React, { useEffect } from 'react'
import Jenkins from './Jenkins'
import Plesk from './Plesk'
import { Button, message } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'
import axiosInstance from '../../../Utils/axios'

const AccessConfiguration = () => {
    const Navigate = useNavigate();
    const [formData, setFormData] = React.useState({});

    useEffect(() => {
        async function fetchData() {
            try {
                const { data } = await axiosInstance.get("/api/platform/config-access");

                setFormData(data?.config);
            } catch (error) {
                return message.error(error?.response?.data?.message);
            }
        }

        fetchData();
    }, [])
    return (
        <div >
            <Button shape='round' icon={<FontAwesomeIcon icon={faArrowLeft} />} onClick={() => Navigate("/administration/users/access/list")}>Retour</Button>
            <div className='  mt-3 p-4 ' style={{ minHeight: "60vh" }}>
                <div className="mb-3 d-flex">
                    <h4 className="fw-bold">Configuration des serveurs</h4>
                </div>
                <div className='row'>
                    <div className='col-lg-6 col-md-6 col-sm-12'>
                        <h5 className="fw-bold">Jenkins</h5>
                        <Jenkins data={formData?.jenkins} />
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12'>
                        <h5 className="fw-bold">Plesk</h5>
                        <Plesk data={formData?.plesk} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AccessConfiguration
