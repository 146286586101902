import React from "react";
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Button, Card, ConfigProvider, Form, Input, Space } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFloppyDisk } from "@fortawesome/free-solid-svg-icons";

const ListCategories = () => {
  return (
    <ConfigProvider
      theme={{
        components: {
          Form: {
            marginLG: 0,
          },
        },
      }}
    >
      <Card>
      <Form.List name="categories">
        {(fields, { add, remove }) => (
          <div className="row gap-2">
            {fields.map(({ key, name, ...restField }) => (
              <div className="col-5 col-md-3 col-lg-3 ">
                <div
                  className="d-flex px-2 rounded-3 justify-content-evenly"
                  style={{
                    fontSize: "14px",
                  }}
                >
                  <Form.Item {...restField} name={name}>
                    <Input
                      placeholder="Compétence"
                      size="large"
                    />
                  </Form.Item>

                  <MinusCircleOutlined

                    onClick={() => remove(name)}
                    style={{ color: "red", paddingLeft: 5 }}
                  />
                </div>
              </div>
            ))}

            <Form.Item>
              <Space>
                <Button
                  className="fw-bold"
                  type="primary"
                  shape="round"
                  onClick={() => add()}
                  icon={<PlusCircleOutlined />}
                >
                  Nouvelle Catégorie
                </Button>
                <Button
                  className="fw-bold"
                  htmlType="submit"
                  type="primary"
                  shape="round"
                  icon={<FontAwesomeIcon icon={faFloppyDisk} />}
                >
                  Sauvgarder
                </Button>
              </Space>
            </Form.Item>
          </div>
        )}
      </Form.List>
      </Card>
    </ConfigProvider>
  );
};

export default ListCategories;
