import {
  Button,
  Space,
  Table,
  Tag,
  message,
  Input,
  Popconfirm,
  Typography,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useState, useEffect, useRef } from "react";
import Highlighter from "react-highlight-words";
import axios from "../../Utils/axios";
import dayjs from "dayjs";

const { Paragraph } = Typography;
const CertificateList = ({ isAdmin, size }) => {
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      const { data } = await axios.get("/api/certif/");
      setRequests(data.certificates);
      setLoading(false);
      return data;
    }
    fetchData();
  }, []);

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex, title) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Recherche ${title}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
          // style={{
          //   width: 90,
          // }}
          >
            Recherche
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Réinitialiser
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleApprove = async (record) => {
    try {
      const { data } = await axios.patch("/api/certif/" + record._id);
      if (data.status === "success") {
        message.success(data.message);
        window.open(data.certificate._url, "_blank");
        const new_requests = requests.map((request) => {
          if (request._id === record._id) {
            request = { ...request, ...data.certificate };
            return request;
          } else {
            return request;
          }
        });
        setRequests(new_requests);
      }
    } catch (error) {
      message.error(error.response.data.message);
    }
  };

  const handleConfirm = async (record) => {
    try {
      const { data } = await axios.patch(
        "/api/certif/handle/accept/" + record._id
      );
      if (data.status === "success") {
        message.success(data.message);
        const new_requests = requests.map((request) => {
          if (request._id === record._id) {
            request._status = "Accepted";
            return request;
          } else {
            return request;
          }
        });
        setRequests(new_requests);
      }
    } catch (error) {
      message.error(error.response.data.message);
    }
  };

  const handleReject = async (record) => {
    try {
      const { data } = await axios.patch(
        "/api/certif/handle/reject/" + record._id
      );
      if (data.status === "success") {
        message.success(data.message);
        const new_requests = requests.map((request) => {
          if (request._id === record._id) {
            request._status = "Rejected";
            return request;
          } else {
            return request;
          }
        });
        setRequests(new_requests);
      }
    } catch (error) {
      message.error(error.message);
    }
  };

  const columns = [
    {
      title: "Nom et prénom",
      dataIndex: "fullName",
      ...getColumnSearchProps("fullName", "Nom et prénom"),
    },
    {
      title: "Demande",
      width: 250,
      render: (_, record) => (
        <Typography.Paragraph
          ellipsis={{
            rows: 2,
            expandable: true,
            symbol: "Plus",
          }}
          style={{ whiteSpace: "pre-wrap" }}
        >
          {"Attestation de travail (" +
            dayjs(record._month_year_date, "MM/YYYY").format("MMMM YYYY") +
            ")"}
        </Typography.Paragraph>
      ),
    },
    {
      title: "Signature",
      render: (_, record) => (
        <Tag color={record._is_signed ? "blue" : "red"}>
          {record._is_signed ? "Oui" : "Non"}
        </Tag>
      ),
    },
    {
      title: "Motif",
      dataIndex: "_motif",
      width: 250,
      render: (_, requestedLeaves) => (
        <>
          <Paragraph
            style={{ margin: 0, whiteSpace: "pre-wrap" }}
            ellipsis={{
              rows: 2,
              expandable: true,
              symbol: "Plus",
            }}
          >
            {requestedLeaves._motif}
          </Paragraph>
        </>
      ),
    },
    {
      title: "Soumis le",
      dataIndex: "_date",
      render: (_, leave) => (
        <Typography.Text strong>
          {leave?._date
            ? dayjs(leave._date).format("DD/MM/YYYY HH:mm")
            : "N/A"}
        </Typography.Text>
      ),
      sorter: (a, b) => dayjs(a._date) - dayjs(b._date),
    },
    {
      title: "Statut",
      dataIndex: "_status",
      render: (_, record) => (
        <>
          {record._status === "Pending" && <Tag color="orange">En attente</Tag>}
          {record._status === "Accepted" && <Tag color="green">Approuvée</Tag>}

          {record._status === "Rejected" && <Tag color="red">Rejetée</Tag>}
          {record._status === "Canceled" && <Tag color="red">Annulée</Tag>}
        </>
      ),
      filters: [
        {
          text: <Tag color="orange">En attente</Tag>,
          value: "Pending",
        },
        {
          text: <Tag color="green">Acceptée</Tag>,
          value: "Accepted",
        },
        {
          text: <Tag color="red">Rejetée</Tag>,
          value: "Rejected",
        },
        {
          text: <Tag color="red">Annulée</Tag>,
          value: "Canceled",
        },
      ],
      onFilter: (value, record) => record.status === value,
    },
    {
      title: "Actions",
      render: (_, record) => (
        <>
          {record._status === "Pending" && (
            <>
              {isAdmin ? (
                <>
                  {!record._url ? (
                    <Popconfirm
                      placement="topLeft"
                      okText="Oui"
                      title="Vous êtes sûr d'approuver la demande?"
                      onConfirm={() => {
                        handleApprove(record);
                      }}
                    >
                      <Button size="small" type="primary">
                        Générer
                      </Button>
                    </Popconfirm>
                  ) : (
                    <Popconfirm
                      placement="topLeft"
                      okText="Oui"
                      title={
                        "Êtes-vous sûr de vouloir notifier " +
                        record.fullName +
                        "?"
                      }
                      onConfirm={() => {
                        handleConfirm(record);
                      }}
                    >
                      <Button size="small" type="primary">
                        Confirmer
                      </Button>
                    </Popconfirm>
                  )}
                  <Popconfirm
                    placement="topLeft"
                    okText="Oui"
                    title="Vous êtes sûr de rejeter la demande?"
                    onConfirm={() => {
                      handleReject(record);
                    }}
                  >
                    <Button
                      size="small"
                      type="primary"
                      danger
                      style={{ marginLeft: "5px" }}
                    >
                      Rejeter
                    </Button>
                  </Popconfirm>
                  {record._url && (
                    <a
                      className="mx-2 text-decoration-none "
                      href={record._url}
                      target="_blank"
                      without rel="noreferrer"
                      download={"Attestation du travail " + record._reference}
                    >
                      Télécharger
                    </a>
                  )}
                </>
              ) : (
                <>
                  <Popconfirm
                    placement="topLeft"
                    okText="Oui"
                    title="Vous êtes sûr d'annuler la demande?"
                    onConfirm={() => {
                      //   handleReject(record);
                    }}
                  >
                    <Button
                      size="small"
                      type="primary"
                      style={{ marginLeft: "5px" }}
                      danger
                    >
                      Annuler
                    </Button>
                  </Popconfirm>
                </>
              )}
            </>
          )}
          {record._status !== "Pending" && (
            <>
              <Button size="small" type="primary">
                Annuler
              </Button>
              {record._url && (
                <a
                  className="mx-2 text-decoration-none "
                  href={record._url}
                  target="_blank"
                  without rel="noreferrer"
                  download={"Attestation du travail " + record._reference}
                >
                  Télécharger
                </a>
              )}
            </>
          )}
        </>
      ),
    },
  ];
  return (
    <div>
      <Table
        columns={columns}
        loading={loading}
        dataSource={requests}
        size={size}
        scroll={{ x: "max-content" }}
      />
    </div>
  );
};

export default CertificateList;
