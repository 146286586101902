import axios from "../../../Utils/axios";
import { useEffect, useState } from "react";
import EditFaqDrawer from "../EditFaqDrawer";
import { DeleteOutlined } from "@ant-design/icons";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Popconfirm, Skeleton, Typography, Collapse } from "antd";

const { Meta } = Card;

const { Panel } = Collapse;
const panelStyle = {
  marginBottom: 24,
  background: "#f2f5fb",
  borderRadius: "8px",
  border: "none",
};

const FaqCard = ({ faq, deleteFaq, access, idx }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [open, setOpen] = useState(false);
  const showPopconfirm = () => {
    setOpen(!open);
  };

  useEffect(() => {
    async function fetchData() {
      const { data } = await axios.get(`/api/faq/${faq._id}`);
      setData(data.faq);
      setLoading(false);
      return data.faq;
    }

    fetchData();
  }, [faq]);

  return access ? (
    <Card
      className="border"
      style={{
        width: "100%",
        marginTop: 16,
      }}
      loading={loading}
      actions={
        access && [
          <EditFaqDrawer faq={data} setFaq={setData} />,
          <Popconfirm
            placement="topLeft"
            title="Vous êtes sûr de le supprimer?"
            open={open}
            onClick={showPopconfirm}
            okText="Oui"
            onConfirm={() => {
              deleteFaq(faq._id);
              showPopconfirm();
            }}
            onCancel={showPopconfirm}
          >
            <DeleteOutlined key="ellipsis" style={{ fontSize: "18px" }} />
          </Popconfirm>,
        ]
      }
    >
      <Skeleton loading={loading} active>
        <Meta
          description={
            <>
              <div className="mb-3" style={{ marginTop: "-5px" }}>
                <Typography.Text style={{ fontWeight: "300" }}>
                  {data._question}
                </Typography.Text>
              </div>
              <Typography.Paragraph
                ellipsis={{
                  rows: 2,
                  expandable: true,
                  symbol: "Plus",
                }}
                style={{ whiteSpace: "pre-wrap" }}
              >
                {data._description}
              </Typography.Paragraph>
            </>
          }
        />
      </Skeleton>
    </Card>
  ) : (
    <>
      {!loading ? (
        <Collapse
          style={{ width: "100%" }}
          expandIconPosition={"end"}
          bordered={true}
          expandIcon={({ isActive }) => (
            <FontAwesomeIcon
              style={{ fontSize: "24px", }}
              icon={faCirclePlus}
              rotate={isActive ? 90 : 0}
            />
          )}
        >
          <Panel
            header={
              <span
                style={{ fontWeight: "500", fontSize: "14px" }}
              >
                {data._question}
              </span>
            }
            key={"1"}

          >
            <p style={{ whiteSpace: "pre-wrap" }}>{data._description}</p>
          </Panel>
        </Collapse>
      ) : (
        <Skeleton.Input
          active={true}
          size={"default"}
          block="true"
          style={panelStyle}
        />
      )}
    </>
  );
};

export default FaqCard;
