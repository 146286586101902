import React, { useState } from "react";
import { Button, Form, Input, message, Modal } from "antd";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axiosInstance from "../../../Utils/axios";

const { TextArea } = Input;

const DeleteAccessModal = ({ record, handleRefresh }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = async () => {
    form
      .validateFields()
      .then(async (values) => {
        console.log(record?._id);
        console.log(values);
        
        try {
            const {data} = await axiosInstance.patch(`/api/administration/delete/access/${record?._id}`, values);
            
            if(data?.status === 'success') {
                message.info('Accès supprimé avec succès');
                handleRefresh();
                setIsModalOpen(false);
            }
        } catch {
            message.error('Erreur lors de la suppression');
        } 
        
      
      })
      .catch((error) => {
        return error;
      });
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <Button
        size="small"
        htmlType="button"
        type="primary"
        shape="circle"
        danger
        onClick={showModal}
      >
        <FontAwesomeIcon icon={faTrash} />
      </Button>
      <Modal
        title="Suppression d'accès"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name={"deleteTrace"}
            label="Motif de suppression"
            rules={[
              {
                required: true,
                whitespace: true,
                message: "Merci de remplir le champ",
              },
            ]}
          >
            <TextArea />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
export default DeleteAccessModal;
