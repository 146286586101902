import {
  DeleteOutlined,
  LinkOutlined,
  StopOutlined,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
import { faCalendar, faFileArrowDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Skeleton, Typography, Tooltip, Popconfirm, Tag } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import axios from "../../Utils/axios";
const { Meta } = Card;

const DocumentCard = ({
  id,
  adminAccess,
  handleDisable,
  handleDelete,
  isHomePage,
}) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [ellipsis, setEllipsis] = useState(true);
  const [open, setOpen] = useState(false);
  const showPopconfirm = () => {
    setOpen(!open);
  };

  useEffect(() => {
    async function fetchData() {
      const { data } = await axios.get("/api/docs/" + id);
      setData(data.doc);

      setLoading(false);
    }
    fetchData();
    /* eslint-disable-next-line */
  }, [id]);

  return (
    <div className="d-flex w-100">
      <div style={{ width: "30px" }}></div>
      <Card
        className="w-100"
        style={{
          marginTop: 16,
        }}
        actions={
          adminAccess && [
            <Popconfirm
              placement="topLeft"
              title="Vous êtes sûr de supprimer le document ?"
              open={open}
              onClick={showPopconfirm}
              okText="Oui"
              onConfirm={() => {
                handleDelete(id);
                showPopconfirm();
              }}
              onCancel={showPopconfirm}
            >
              <DeleteOutlined key="ellipsis" style={{ fontSize: "18px" }} />
            </Popconfirm>,
            <Tooltip
              title={data?._disabled ? "activer" : "Désactiver"}
              onClick={() => {
                handleDisable(id, data._disabled);
              }}
            >
              <StopOutlined key="delete" style={{ fontSize: "18px" }} />
            </Tooltip>,
          ]
        }
      >
        <Skeleton loading={loading} active>
          <Meta
            description={
              <Typography.Text>
                <div
                  style={{
                    color: "#2A96FF",
                    fontSize: "12px",
                    fontWeight: "400",
                  }}
                >
                  {isHomePage && <Tag color="blue">Document Partagé</Tag>}
                </div>
                <div className="d-flex justify-content-between flex-column flex-lg-row flex-md-row">
                  <Typography.Text
                    strong
                    style={{ fontWeight: "500", fontSize: "16px" }}
                  >
                    {data._title}
                  </Typography.Text>

                  <span className="datePoste">
                    <FontAwesomeIcon icon={faCalendar} />{" "}
                    {dayjs(data._posted_date).fromNow()}
                  </span>
                </div>

                <Typography.Text className="datePoste">
                  {data._categorie}
                </Typography.Text>
                <Typography.Paragraph
                  style={{
                    whiteSpace: "pre-wrap",
                    fontWeight: "400",
                    fontSize: "14px",
                  }}
                  ellipsis={
                    ellipsis
                      ? {
                          rows: 2,
                          expandable: ellipsis,
                          symbol: (
                            <Typography.Link
                              onClick={(event) => {
                                event.stopPropagation();
                                setEllipsis(false);
                              }}
                            >
                              Plus
                            </Typography.Link>
                          ),
                        }
                      : false
                  }
                >
                  {data._description}
                  {!ellipsis && (
                    <Typography.Link
                      onClick={() => {
                        setEllipsis(true);
                      }}
                    >
                      {" "}
                      Moins
                    </Typography.Link>
                  )}
                </Typography.Paragraph>

                {data?._type === "FILE"
                  ? data?._file?.map((file) => {
                      return (
                        <>
                          <Typography.Link
                            href={file.base64}
                            download={file.name}
                          >
                            <Tag
                              className="my-1"
                              color="blue"
                              style={{ fontSize: "12px", padding: "5px 10px" }}
                            >
                              {" "}
                              <FontAwesomeIcon
                                icon={faFileArrowDown}
                                style={{ marginRight: "5px" }}
                              />{" "}
                              {file.name}
                            </Tag>
                          </Typography.Link>
                          <br />
                        </>
                      );
                    })
                  : data?._link?.map((link) => {
                      return (
                        <>
                          <a href={link}>
                            {" "}
                            <LinkOutlined /> {link}
                          </a>
                          <br />
                        </>
                      );
                    })}

                {data?._disabled && (
                  <Typography.Text
                    strong
                    type="danger"
                    className="lead d-flex justify-content-end"
                  >
                    <EyeInvisibleOutlined /> Désactivé
                  </Typography.Text>
                )}
              </Typography.Text>
            }
          />
        </Skeleton>
      </Card>
    </div>
  );
};

export default DocumentCard;
