import { useState } from "react";
import { Menu, DatePicker, Form, Modal, notification, Input } from "antd";
import dayjs from "dayjs";
import axiosInstance from "../../../Utils/axios";

const { TextArea } = Input;
const PlanResignationModal = ({ record }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const disabledDate = (current) => {
    // Disable all dates that are less than or equal to today's date
    return current && current <= dayjs().endOf("day");
  };

  const handleResignation = (id) => {
    form
      .validateFields()
      .then(async (res) => {
        
        try {
          const { data } = await axiosInstance.post(
            "/api/plan/save/resignation/" + id,
            res
          );

          if (data?.status === "success") {
            notification.success({
              message: "Succès",
              description: data?.message,
            });
            handleOk();
          }
        } catch (error) {
          notification.error({
            message: "Erreur",
            description: (
              <p style={{ whiteSpace: "pre-wrap" }}>
                {error?.response?.data?.message}
              </p>
            ),
          });
          handleOk();
        }
      })
      .catch((error) => {
        return error;
      });
  };

  return (
    <>
      <Menu.Item onClick={showModal}>Planifier démission</Menu.Item>
      <Modal
        okText="Planifier"
        on
        title={
          "Planifier démission de " +
          record?.fullName +
          " #" +
          record?.matricule
        }
        open={isModalOpen}
        onOk={() => handleResignation(record?.matricule)}
        onCancel={handleCancel}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            name="date" label="Date de démission"
            rules={[
              { required: true, message: "Veuillez sélectionner une date." },
            ]}
          >
            <DatePicker
              className="w-100"
              disabledDate={disabledDate}
            />
          </Form.Item>
          <Form.Item
            label="Description"
            name="description"
            rules={[
              {
                whitespace: true,
                required: true,
                message: "Veuillez sélectionner une date.",
              },
            ]}
          >
            <TextArea />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
export default PlanResignationModal;
