import { Table, message, Tag, Typography } from "antd";
import React, { useEffect, useState } from "react";
import axiosInstance from "../../../Utils/axios";

const Acces = () => {
  const [loading, setLoading] = useState(true);
  const [access, setAccess] = useState([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const { data } = await axiosInstance.get("/api/access/me");

        setAccess(data?.access);
        setLoading(false);
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    }
    fetchData();
  }, []);

  const columns = [
    {
      title: "Service",
      dataIndex: "service",
    },
    {
      title: "Login",
      dataIndex: "userName",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: 'Serveur',
      dataIndex: 'server',
      render: (text) => <Tag color='blue'>{text}</Tag>
    },
    {
      title: "Lien de connexion",
      dataIndex: "url",
      render: (_, record) => (
        <a
          href={record?.url !== "N/A" ? record?.url : "#"}
          disabled={record?.url === "N/A"}
          target={record?.url !== "N/A" && "_blank"}
        >
          {record?.url}
        </a>
      ),
    },

  ];
  return (
    <div className="p-4">
      <div style={{ minHeight: "80vh" }}>
        <div style={{ maxHeight: "82vh", overflowY: "scroll" }}>
          <div>
            <Typography.Title level={4}>🔑 Mes accès :</Typography.Title>
            <Table columns={columns} loading={loading} dataSource={access}  />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Acces;
